import { useState } from "react";

import { UserIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import LoadingCard from "../../common/LoadingCard";
import UserForm from "../users/UserForm";
import ErrorAlert from "../../common/ErrorAlert";

import * as AuthService from "../../services/auth.service";
import useGetOne from "../../services/useGetOne";
import { UserDescriptor } from "../../services/webservice";

export default function ProfilePage() {
  const user = AuthService.getUser();
  const [refreshKey, setRefreshKey] = useState<number>(200);
  const { loading, data, error } = useGetOne<UserDescriptor>(
    `/Users/${user?.userId}`,
    refreshKey,
    user?.role === "Super" || user?.role === "Staff"
  );

  const handleRefresh = () => {
    setRefreshKey(refreshKey + 1);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="User Profile"
          icon={<UserIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="User Profile"
        icon={<UserIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <UserForm
            user={data}
            reload={handleRefresh}
            refreshKey={refreshKey}
          />
        )}
      </PageBody>
    </div>
  );
}
