import { PencilIcon } from "@heroicons/react/24/solid";

const SignatureRequired = () => {
  return (
    <div className="sm:mt-0 flex-shrink-0 flex">
      <p className="px-2 text-xs flex items-center leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
        <PencilIcon
          className="flex-shrink-0 mr-1.5 h-4 w-4"
          aria-hidden="true"
        />
        SIGNATURE REQUIRED
      </p>
    </div>
  );
};

export default SignatureRequired;
