import { useEffect, useState } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import FormError from "src/common/FormError";
import ShipMapDeleteButton from "../integrations/shipmaps/ShipMapDeleteButton";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import WmsConnectionMap from "./WmsConnectionMap";

import { apiInstance } from "src/services/api";
import * as AuthService from "src/services/auth.service";
import { useAccount } from "src/AccountContext";
import {
  parseAccountParams,
  parseAxiosErrors,
  parseClassNames,
} from "src/utils/Parse";
import { TEXT_INPUT } from "src/utils/styles";
import {
  GlobalShipMethodMapDescriptor,
  GlobalShipMethodMapItem,
  WmsConnection,
} from "src/services/webservice";
import useGetWms, { Formats } from "src/services/wms/useGetWms";
import LoadingInline from "src/common/LoadingInline";

interface IFormInputs {
  accountId: number;
  globalShipMethodMapId: number;
  cartShipMethod: string;
  items: GlobalShipMethodMapItem[];
}

interface IShipMapFormProps {
  shipMap?: GlobalShipMethodMapDescriptor;
}

export default function GlobalShipMapForm({ shipMap }: IShipMapFormProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const user = AuthService.getUser();
  const { data, error, loading } = useGetWms<WmsConnection>({
    format: Formats.Full,
  });
  const methods = useForm<IFormInputs>({
    defaultValues: {
      accountId: account.accountId > 0 ? account.accountId : user?.accountId,
      globalShipMethodMapId: shipMap?.globalShipMethodMapId || 0,
      cartShipMethod: shipMap?.cartShipMethod,
      items: shipMap?.items || [],
    },
  });
  const watchItems = methods.watch("items");
  const [items, setItems] = useState<GlobalShipMethodMapItem[]>([]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    let response;
    let loadingMessage = "Updating...";
    let successMessage = "Ship mapping updated successfully";
    if (shipMap) {
      response = apiInstance.put(
        `/GlobalShipMethodMaps/${shipMap.globalShipMethodMapId}`,
        data,
        { params }
      );
    } else {
      loadingMessage = "Creating...";
      successMessage = "Ship mapping created successfully";
      response = apiInstance.post("/GlobalShipMethodMaps", data, { params });
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        methods.reset();
        navigate("/shipmaps");
      });
  };

  useEffect(() => {
    if (shipMap && shipMap.items) setItems(shipMap.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    methods.setValue("items", items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const hasItems = () => {
    return watchItems.length > 0 ? true : false;
  };

  const addItem = (item: GlobalShipMethodMapItem) => {
    let newItems = items.filter(
      (newItem) => newItem.wmsConnectionId !== item.wmsConnectionId
    );
    setItems([...newItems, item]);
  };

  const removeItem = (item: GlobalShipMethodMapItem) => {
    let newItems = items.filter(
      (newItem) => newItem.wmsConnectionId !== item.wmsConnectionId
    );
    setItems(newItems);
  };

  const assignWmsToItem = (
    wmsConnectionId: number,
    items: GlobalShipMethodMapItem[]
  ) => {
    return items.find((item) => item.wmsConnectionId === wmsConnectionId);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <input type="hidden" {...methods.register("accountId")} />
        <input type="hidden" {...methods.register("globalShipMethodMapId")} />
        <input type="hidden" {...methods.register("items")} />
        <div>
          <div>
            <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
              Incoming Shipping Method Text
            </h3>
            <p className="mt-1 max-w-2xl text-xs md:text-sm text-gray-500">
              This text must exactly match the text we retrieve from your
              shopping cart.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label
                htmlFor="cartShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Cart Shipping Method Text
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...methods.register("cartShipMethod", { required: true })}
                    className={parseClassNames(
                      TEXT_INPUT.base,
                      methods.formState.errors.cartShipMethod
                        ? TEXT_INPUT.error
                        : TEXT_INPUT.valid
                    )}
                    placeholder="FREE SHIPPING"
                  />
                </div>
                {methods.formState.errors.cartShipMethod && (
                  <FormError message="Cart Method is Required" />
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-8 space-y-6 sm:space-y-5 sm:border-t sm:border-gray-200 sm:pt-5">
            <div>
              <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
                Available Wms Connection(s) & Ship Methods
              </h3>
              <p className="mt-1 text-xs md:text-sm text-gray-500">
                This list shows <span className="font-bold">ALL</span> available
                WMS Connections. Configured shipping methods and options will be
                displayed next to each connection.
              </p>
            </div>
            {loading && <LoadingInline message="Loading WMS Connections" />}
            {error && <FormError message={error} />}
            {data.map((wms) => (
              <div
                key={wms.wmsConnectionId}
                className="flex items-center flex-wrap"
              >
                <WmsConnectionMap
                  wmsConnectionId={wms.wmsConnectionId || 0}
                  wmsConnectionDescription={wms.description || ""}
                  item={assignWmsToItem(wms.wmsConnectionId || 0, items)}
                  addItem={addItem}
                  removeItem={removeItem}
                  isEditable
                />
              </div>
            ))}
          </div>

          <div className="pt-5">
            <ButtonGroup>
              <ShipMapDeleteButton id={shipMap?.globalShipMethodMapId} />
              <Button label="Cancel" path="/shipmaps" design="secondary" />
              <Button label="Save" type="submit" disabled={!hasItems()} />
            </ButtonGroup>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
