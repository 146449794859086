import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import DialogOverlay from "src/common/DialogOverlay";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import {
  parseAccountParams,
  parseAxiosErrors,
  parseClassNames,
} from "src/utils/Parse";
import { TEXT_INPUT } from "src/utils/styles";

interface IntegrationConfigDeleteButtonProps {
  id: number;
  integrationName: string;
  additionalWarnings?: string[];
}

export function IntegrationConfigDeleteButton({
  id,
  integrationName,
  additionalWarnings,
}: IntegrationConfigDeleteButtonProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [verifiedConfigName, setVerifiedConfigName] = useState<string>("");

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifiedConfigName(e.target.value);
  };

  const handleDeleteShipMap = () => {
    closeModal();
    toast
      .promise(apiInstance.delete(`/Integrations/${id}`, { params }), {
        loading: "Deleting...",
        success: "Integration config deleted",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => navigate("/integrations"));
  };

  if (id) {
    return (
      <>
        <Button label="Delete" func={openModal} design="delete" />
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            open={isOpen}
            onClose={closeModal}
          >
            <DialogOverlay />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className="rounded-md bg-red-50 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            Danger Zone! Deleting this integration will cause
                            the following:
                          </h3>
                          <div className="mt-2 text-sm text-red-700">
                            <ul className="list-disc space-y-1 pl-5">
                              <li>Orders will not sync.</li>
                              <li>Inventory will not sync.</li>
                              <li>Tracking will not sync.</li>
                              {additionalWarnings && (
                                <>
                                  {additionalWarnings.map((warning, index) => (
                                    <li key={index}>{warning}</li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-md font-medium leading-6 text-gray-900"
                    >
                      Are you sure?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        To confirm the delete process and unschedule all tasks
                        you must type the exact integration name:
                      </p>
                      <p className="text-gray-800 bg-gray-50 my-4 px-2 py-1 tracking-wider text-center rounded-md">
                        {integrationName}
                      </p>
                      <div>
                        <label htmlFor="email" className="sr-only">
                          Email
                        </label>
                        <input
                          type="text"
                          name="deleteConfirmation"
                          id="deleteConfirmation"
                          className={parseClassNames(
                            TEXT_INPUT.base,
                            TEXT_INPUT.valid
                          )}
                          placeholder="type integration name here"
                          value={verifiedConfigName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <ButtonGroup>
                      <Button
                        design="secondary"
                        label="Cancel"
                        func={closeModal}
                      />
                      <Button
                        disabled={verifiedConfigName !== integrationName}
                        design="delete"
                        label="Yes, Delete"
                        func={handleDeleteShipMap}
                      />
                    </ButtonGroup>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  } else {
    return <></>;
  }
}
