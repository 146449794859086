import { FolderPlusIcon } from "@heroicons/react/24/outline";

export default function FolderIcon() {
  return (
    <FolderPlusIcon
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    />
  );
}
