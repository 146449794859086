import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  PlusSmallIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import IntegrationConfig from "./IntegrationConfig";
import ErrorAlert from "../../common/ErrorAlert";
import Button from "../../common/buttons/Button";

import useGetOne from "../../services/useGetOne";
import { IntegrationConfigDescriptor } from "../../services/webservice";
import LoadingCard from "../../common/LoadingCard";
import EmptyList from "../../common/EmptyList";

export default function IntegrationPage() {
  const { integrationConfigId } = useParams();
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const { loading, data, error } = useGetOne<IntegrationConfigDescriptor>(
    `/Integrations/${integrationConfigId}`,
    refreshKey
  );
  const navigate = useNavigate();

  const refreshIntegrationsList = () => {
    setRefreshKey(refreshKey + 1);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Integrations"
          icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Integrations"
        icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        description="You can configure and view integration details below."
        actionComp={
          <Button
            label="Add"
            func={() => navigate("/integrations/add")}
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody>
        {!data && (
          <div className="text-center py-10">
            {loading ? (
              <LoadingCard />
            ) : (
              <EmptyList
                title="No Integrations"
                description="Get started by connecting a shopping cart or order management platform."
                buttonPath="add"
                buttonLabel="New Integration"
              />
            )}
          </div>
        )}
        {data && (
          <>
            {loading ? (
              <LoadingCard />
            ) : (
              <ul>
                <IntegrationConfig
                  integration={data}
                  key={data.integrationConfigId}
                  refresh={refreshIntegrationsList}
                />
              </ul>
            )}
          </>
        )}
      </PageBody>
    </div>
  );
}
