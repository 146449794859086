import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/solid";
import Button from "./Button";

export default function BackToIntegrationConfig() {
  const [configId, setConfigId] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  let { integrationConfigId } = useParams();
  let [searchParams] = useSearchParams();
  let integrationConfigIdParam = searchParams.get("integrationConfigId");

  useEffect(() => {
    setConfigId(integrationConfigId || integrationConfigIdParam);
  }, [integrationConfigId, integrationConfigIdParam]);

  if (!configId || location.pathname === `/integrations/${configId}`)
    return null;
  return (
    <div>
      <Button
        label="Back to Config"
        design="secondary"
        icon={<ArrowSmallLeftIcon className="mr-2 h-6 w-6" />}
        func={() => navigate(`/integrations/${configId}`)}
      />
    </div>
  );
}
