import { CalendarIcon } from "@heroicons/react/24/solid";

import { parseDate } from "src/utils/Parse";
import { OrderLog } from "src/services/webservice";

interface OrderStateListItemProps {
  log: OrderLog;
  logIdx: number;
  count: number | undefined;
}

export default function OrderStateListItem({
  log,
  logIdx,
  count,
}: OrderStateListItemProps) {
  return (
    <li>
      <div className="relative pb-8">
        {count && logIdx !== count - 1 ? (
          <span
            className="absolute top-3 left-3 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span className="h-6 w-6 rounded-full flex items-center justify-center ring-6 ring-white bg-gray-400">
              <CalendarIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1 pt-0.5 flex flex-wrap">
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              <time dateTime={parseDate(log.dateCreated)}>
                {parseDate(log.dateCreated)}
              </time>
            </div>
            <div>
              <p className="text-sm text-gray-900 sm:ml-2">{log.message}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
