import { RadioGroup } from "@headlessui/react";
import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/solid";

import { IntegrationConfigDescriptor } from "src/services/webservice";

interface SettingTransferRadioOptionsProps {
  id: number;
  integrations: IntegrationConfigDescriptor[];
  target: number;
  setTarget: (value: number) => void;
}

export function SettingTransferRadioOptions({
  id,
  integrations,
  target,
  setTarget,
}: SettingTransferRadioOptionsProps) {
  if (integrations.length === 1)
    return (
      <div className="mx-auto w-full max-w-md">No other integrations found</div>
    );

  return (
    <div className="mx-auto w-full max-w-md">
      <RadioGroup value={target} onChange={setTarget}>
        <RadioGroup.Label className="sr-only">
          Available Target Configurations
        </RadioGroup.Label>
        <div className="space-y-2">
          {integrations.map((integration) =>
            integration.integrationConfigId === id ? (
              false
            ) : (
              <RadioGroup.Option
                key={integration.integrationConfigId}
                value={integration.integrationConfigId}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-accent ring-opacity-60 ring-offset-2 ring-offset-100"
                      : ""
                  }
                  ${
                    checked
                      ? "bg-accent bg-opacity-75"
                      : "bg-gray-200 text-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-yellow-900" : "text-gray-900"
                            }`}
                          >
                            {integration.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-yellow-100" : "text-gray-700"
                            }`}
                          >
                            <span>{integration.cartIdentifier}</span>
                            <ArrowsRightLeftIcon className="h-4 w-4 inline mx-1" />
                            <span>
                              {integration.wmsConnection?.description}
                            </span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-yellow-900">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            )
          )}
        </div>
      </RadioGroup>
    </div>
  );
}
