import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import { SubmitHandler, useForm } from "react-hook-form";

import { Disclosure } from "@headlessui/react";
import { BriefcaseIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import { CartItem } from "src/services/webservice";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import AddCustomCartItemButton from "./AddCustomCartItemButton";
import Button from "src/common/buttons/Button";
import ItemsContent from "./ItemsContent";
import { TABLE_COLUMN_SORT, TEXT_INPUT } from "src/utils/styles";

import ErrorAlert from "src/common/ErrorAlert";
import Pagination from "src/common/Pagination";

import { apiInstance } from "src/services/api";
import useGetList, { SortType } from "src/services/useGetList";
import { useAccount } from "src/AccountContext";
import {
  parseAccountParams,
  parseAxiosErrors,
  parseClassNames,
} from "src/utils/Parse";

type SearchFieldType = "Name" | "Sku";

interface IItemSearchFormProps {
  search: string;
  field: SearchFieldType;
}

export default function ItemsPage() {
  const isInitialRender = useRef(true);
  const { integrationConfigId } = useParams();
  const [searchField, setSearchField] = useState<SearchFieldType>("Sku");
  const [search, setSearch] = useState<string>("");
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [customItemDialogOpen, setCustomItemDialogOpen] =
    useState<boolean>(false);
  const [customItem, setCustomItem] = useState<CartItem | undefined>();
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [sort, setSort] = useState<SortType[]>([
    { field: "sku", direction: "asc" },
  ]);
  const [filter, setFilter] = useState<string>("");
  const { data, loading, error, totalPages, totalRecords } =
    useGetList<CartItem>(
      `/cart/${integrationConfigId}/items`,
      page,
      pageSize,
      refreshKey,
      sort,
      filter
    );
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IItemSearchFormProps>();

  useEffect(() => {
    if (isInitialRender.current || search === "") {
      isInitialRender.current = false;
      return;
    }
    const updatedFilter = `&${searchField}=${search}`;
    setFilter(updatedFilter);
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchField]);

  const refreshCartItems = () => {
    toast
      .promise(
        apiInstance.get(`/cart/${integrationConfigId}/items/refresh`, {
          params,
        }),
        {
          loading: "Refreshing cart items...",
          success: "Cart items successfully refreshed",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => setRefreshKey(refreshKey + 1));
  };

  const handleSort = (field: string) => {
    let newSort: SortType = { field, direction: "asc" };
    if (sort[0].field === newSort.field) {
      newSort.direction = sort[0].direction === "asc" ? "desc" : "asc";
    }
    setSort([newSort]);
  };

  const reloadItemList = () => {
    setRefreshKey(refreshKey + 1);
  };

  const onSubmit: SubmitHandler<IItemSearchFormProps> = (data) => {
    setSearch(encodeURIComponent(data.search));
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedIds.length > 0) {
      setSelectedIds([]);
    } else {
      let newSelectedItems: number[] = data.map((item) => {
        return item.cachedCartItemId;
      });
      setSelectedIds(newSelectedItems);
    }
  };

  const handleStatusToggle = () => {
    toast
      .promise(
        apiInstance.put(
          `/Cart/${integrationConfigId}/items/activated`,
          selectedIds,
          { params }
        ),
        {
          loading: "Toggling item status...",
          success: "Item status successfully toggled",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => {
        setRefreshKey(refreshKey + 1);
        setSelectedIds([]);
      });
  };

  const handleEditCustomItem = (item: CartItem) => {
    setCustomItem(item);
    setCustomItemDialogOpen(true);
  };

  const resetCustomItem = () => {
    setCustomItem(undefined);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Cart Items"
          icon={<BriefcaseIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Cart Items"
        icon={<BriefcaseIcon className="w-8 md:w-10 ml-2" />}
        description="Cached list of items synced from your cart plus any custom items you create. You can refresh manually or schedule an automatic nightly refresh."
        actionComp={
          <Button
            label="Refresh"
            type="button"
            func={refreshCartItems}
            icon={<ArrowPathIcon className="mr-2 h-6 w-6" />}
          />
        }
        actionCompSecondary={
          <AddCustomCartItemButton
            integrationConfigId={integrationConfigId}
            refresh={reloadItemList}
            isOpen={customItemDialogOpen}
            setIsOpen={setCustomItemDialogOpen}
            item={customItem}
            resetCustomItem={resetCustomItem}
          />
        }
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-2">
        <div className="w-full rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                  <span>Search Items</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-primary-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 flex sm:space-x-10 space-y-4 sm:space-y-0 flex-wrap">
                  <div>
                    <p className="text-sm leading-5 text-gray-500">
                      Search Field
                    </p>
                    <fieldset>
                      <legend className="sr-only">Search Field</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                        <div className="flex items-center">
                          <input
                            id="Sku"
                            name="event-status"
                            type="radio"
                            defaultChecked={searchField === "Sku"}
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                            onClick={() => setSearchField("Sku")}
                          />
                          <label
                            htmlFor="Sku"
                            className="ml-2 block text-sm font-medium text-gray-700"
                          >
                            SKU
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="Name"
                            name="event-status"
                            type="radio"
                            defaultChecked={searchField === "Name"}
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                            onClick={() => setSearchField("Name")}
                          />
                          <label
                            htmlFor="Name"
                            className="ml-2 block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap space-x-4 space-y-2 sm:space-y-0">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="text"
                              {...register("search", { required: true })}
                              id="search"
                              className={parseClassNames(
                                "block w-full focus:outline-none rounded-none rounded-l-md  sm:text-sm",
                                errors.search
                                  ? TEXT_INPUT.error
                                  : TEXT_INPUT.valid
                              )}
                              placeholder={`Enter ${searchField}`}
                            />
                          </div>
                          <button
                            type="submit"
                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          >
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            <span>Search</span>
                          </button>
                        </div>
                        <Button
                          label="clear search"
                          design="text"
                          func={() => {
                            setFilter("");
                            setSearch("");
                            setRefreshKey(refreshKey + 1);
                            reset();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <PageBody table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow md:rounded-lg">
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  {selectedIds.length > 0 && (
                    <button
                      type="button"
                      onClick={handleStatusToggle}
                      className="inline-flex items-center rounded border border-primary-300 bg-primary px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                    >
                      Bulk Toggle Active Status
                    </button>
                  )}
                </div>
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      >
                        <input
                          type="checkbox"
                          onChange={selectAll}
                          checked={selectedIds.length > 0}
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-primary-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <button
                          onClick={() => handleSort("sku")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          SKU
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "sku"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "sku" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <button
                          onClick={() => handleSort("isActive")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Status
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "isActive"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "isActive" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <button
                          onClick={() => handleSort("isCustom")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Type
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "isCustom"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "isCustom" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <button
                          onClick={() => handleSort("isMapped")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Maps
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "isMapped"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "isMapped" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <ItemsContent
                      data={data}
                      loading={loading}
                      integrationConfigId={integrationConfigId || ""}
                      selectedIds={selectedIds}
                      refreshCartItems={refreshCartItems}
                      setSelectedIds={setSelectedIds}
                      refreshItemsList={reloadItemList}
                      editCustomItem={handleEditCustomItem}
                    />
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
