import LoadingCard from "../../common/LoadingCard";
import FolderIcon from "../../common/FolderIcon";
import AuditLogDetailDialog from "./AuditLogDetailDialog";

import { AuditLog } from "../../services/webservice";
import { parseDate, parseClassNames } from "../../utils/Parse";
import { TABLE_COLUMN_TEXT } from "../../utils/styles";

interface AuditLogsContentProps {
  data: AuditLog[];
  loading: boolean;
}

export default function ItemsContent({ data, loading }: AuditLogsContentProps) {
  if (loading)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <LoadingCard />
          </div>
        </td>
      </tr>
    );

  if (data.length === 0)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <FolderIcon />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Audit Logs
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by changing some stuff.
            </p>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {data.map((audit) => (
        <tr key={audit.auditLogId}>
          <td className={TABLE_COLUMN_TEXT.base}>
            {audit.integrationConfigId === 0 ? (
              <span className="text-gray-400">N/A</span>
            ) : (
              `${audit.integrationConfigName} (${audit.integrationConfigId})`
            )}
          </td>
          <td className={TABLE_COLUMN_TEXT.base}>
            {parseDate(audit.dateCreated)}
          </td>
          <td className={TABLE_COLUMN_TEXT.base}>{audit.type}</td>
          <td className={TABLE_COLUMN_TEXT.base}>{audit.resource}</td>
          <td className={TABLE_COLUMN_TEXT.base}>{audit.userName}</td>
          <td className={parseClassNames(TABLE_COLUMN_TEXT.base, "text-right")}>
            <AuditLogDetailDialog audit={audit} />
          </td>
        </tr>
      ))}
    </>
  );
}
