import { parseClassNames } from "../../../utils/Parse";

import { ClockIcon } from "@heroicons/react/24/outline";

interface EventLogStatusBadgeProps {
  text?: string;
  inProgress?: boolean;
}

export default function EventLogStatusBadge({
  text,
  inProgress,
}: EventLogStatusBadgeProps) {
  let style;
  switch (text) {
    case "Info":
      style = "bg-primary-100 text-primary-800";
      break;
    case "Success":
      style = "bg-green-100 text-green-800";
      break;
    case "Warning":
      style = "bg-yellow-100 text-yellow-800";
      break;
    case "Error":
      style = "bg-red-100 text-red-800";
      break;
    default:
      style = "bg-gray-100 text-gray-800";
  }

  if (!text) return null;
  return (
    <div className="flex space-x-1">
      <span
        className={parseClassNames(
          "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
          style
        )}
      >
        {text}
      </span>
      {inProgress && (
        <ClockIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
      )}
    </div>
  );
}
