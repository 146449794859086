import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-hot-toast";

import {
  ChevronDoubleRightIcon,
  MinusSmallIcon,
} from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import UomMapDeleteButton from "./UomMapDeleteButton";
import AddWmsItemButton from "./AddWmsItemButton";
import ErrorAlert from "src/common/ErrorAlert";
import DisplayUomMaps from "../items/DisplayUomMaps";
import DisplayMappedCartItem from "../skumaps/DisplayMappedCartItem";
import DisplayMappedUomItem from "../skumaps/DisplayMappedUomItem";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import ButtonRound from "src/common/buttons/ButtonRound";
import LoadingCard from "src/common/LoadingCard";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import useGetOne from "src/services/useGetOne";
import { UomMap } from "src/services/webservice";
import { UomItemType } from "../items/types";

export default function EditUomMapPage() {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const { integrationConfigId, wmsConnectionId, uomMapId } = useParams();
  const { data, loading, error } = useGetOne<UomMap>(
    `/integrations/${integrationConfigId}/UomMaps/${uomMapId}`
  );
  const [uomItems, setUomItems] = useState<UomItemType[]>([]);

  const reMapItems = () => {
    if (data?.items && data.items.length > 0) {
      const newData: any = data.items.map((item) => {
        return {
          uomSku: item.uomSku,
          quantity: item.quantity,
        };
      });
      setUomItems(newData);
    }
  };

  useEffect(() => {
    if (data?.items && data.items.length > 0) {
      const newData: any = data.items.map((item) => {
        return {
          uomSku: item.uomSku,
          quantity: item.quantity,
        };
      });
      setUomItems(newData);
    }
  }, [data]);

  const addItem = (newItem: UomItemType) => {
    let isNew = true;
    let newItems = uomItems.map((item) => {
      if (item.uomSku === newItem.uomSku) {
        isNew = false;
        return {
          uomSku: item.uomSku,
          quantity: item.quantity + newItem.quantity,
        };
      } else {
        return item;
      }
    });
    if (isNew) newItems.push(newItem);
    setUomItems(newItems);
  };

  const removeItem = (sku: string) => {
    const newItems = uomItems.filter((item) => item.uomSku !== sku);
    setUomItems(newItems);
  };

  const updateMap = () => {
    toast
      .promise(
        apiInstance.put(
          `/integrations/${integrationConfigId}/UomMaps/${uomMapId}`,
          {
            items: uomItems,
          },
          { params }
        ),
        {
          loading: "Updating...",
          success: "SKU map has been successfully updated.",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() =>
        navigate(
          `/integrations/${integrationConfigId}/${wmsConnectionId}/items`
        )
      );
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader title="Edit Unit of Measure Map" />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader title="Edit Unit of Measure Map" />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            <div className="flex space-y-4 sm:space-y-0 sm:space-x-10 flex-wrap">
              <div id="existing-map">
                <div className="text-lg mb-2">Existing Map:</div>
                <DisplayMappedCartItem sku={data?.cartSku} />
                <DisplayUomMaps map={data} />
              </div>
              <div className="place-self-center invisible sm:visible">
                <ChevronDoubleRightIcon className="h-10 w-10 text-gray-400" />
              </div>
              <div className="place-self-start" id="revised-map">
                <div className="text-lg mb-2">Revised Map:</div>
                <DisplayMappedCartItem sku={data?.cartSku} />
                {uomItems.length === 0 ? (
                  <div className="text-sm mt-2">
                    All items removed.{" "}
                    <button
                      onClick={reMapItems}
                      className="underline text-blue-700 hover:text-blue-600"
                    >
                      Click here to clear your edits.
                    </button>
                  </div>
                ) : (
                  uomItems.map((item) => (
                    <div key={item.uomSku} className="flex items-center">
                      <DisplayMappedUomItem item={item} />
                      <ButtonRound
                        func={() => removeItem(item.uomSku)}
                        icon={
                          <MinusSmallIcon
                            className="h-3 w-3"
                            aria-hidden="true"
                          />
                        }
                        label={`remove SKU ${item.uomSku}`}
                        design="tertiary"
                        custom="ml-2 mt-2"
                      />
                    </div>
                  ))
                )}
                <div className="mt-4">
                  <AddWmsItemButton
                    wmsConnectionId={Number(wmsConnectionId)}
                    addItem={addItem}
                    params={params}
                  />
                </div>
              </div>
            </div>
            <ButtonGroup>
              <Button
                label="Cancel"
                func={() => navigate(-1)}
                design="secondary"
              />
              <UomMapDeleteButton
                id={Number(uomMapId)}
                integrationConfigId={Number(integrationConfigId)}
                wmsConnectionId={wmsConnectionId}
              />
              <Button
                label="Save Changes"
                disabled={uomItems.length === 0}
                func={updateMap}
              />
            </ButtonGroup>
          </>
        )}
      </PageBody>
    </div>
  );
}
