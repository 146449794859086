import { useState, useEffect } from "react";

import { apiInstance } from "./api";
import { useAccount } from "../AccountContext";
import { parseAccountParams } from "../utils/Parse";

export type SortType = {
  field: string;
  direction: "asc" | "desc";
};

export default function useGetList<T>(
  url: string,
  page: number,
  pageSize: number,
  reload: number = 0,
  sort?: SortType[] | null,
  filter?: string
) {
  const { account } = useAccount();
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    if (!url) return;
    let sortString = "";
    if (sort && sort?.length > 0) {
      sortString = sort
        .map((e) => {
          return `sortFields=${e.field}&sortDirections=${e.direction}`;
        })
        .join("&");
    }

    const params = parseAccountParams(account);

    async function init() {
      try {
        setLoading(true);
        const response = await apiInstance.get(
          `${url}?Page=${page}&PageSize=${pageSize}&${sortString}${
            filter || ""
          }`,
          { params }
        );
        if (response.status === 200) {
          setData(response.data);
          if (response.headers["x-pagination-totalrecords"]) {
            setTotalRecords(
              Number(response.headers["x-pagination-totalrecords"])
            );
            setTotalPages(Number(response.headers["x-pagination-totalpages"]));
          } else {
            setTotalRecords(response.data.length);
          }
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || "Unknown error");
      } finally {
        setLoading(false);
      }
    }

    init();
    return () => setData([]);
  }, [url, page, pageSize, reload, sort, filter, account]);

  return { data, error, loading, totalRecords, totalPages };
}
