import { Toaster } from "react-hot-toast";

import SeverityIcon from "./SeverityIcon";

import { parseClassNames } from "src/utils/Parse";

const baseToast =
  "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden";

const ToastContainer = () => (
  <div
    aria-live="assertive"
    className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Toaster
        position="top-right"
        containerStyle={{ marginTop: "4rem" }}
        toastOptions={{
          duration: 5000,
          success: {
            className: parseClassNames("status-success", baseToast),
            icon: <SeverityIcon type="success" />,
          },
          error: {
            className: parseClassNames("status-error", baseToast),
            icon: <SeverityIcon type="error" />,
          },
          loading: {
            className: parseClassNames("status-loading", baseToast),
          },
        }}
      />
    </div>
  </div>
);

export default ToastContainer;
