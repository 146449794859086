import { useFormContext } from 'react-hook-form';

import SettingsMetaEnum from "../meta/SettingMetaEnum";
import FormError from 'src/common/FormError';

import { SettingEnumMeta } from "../../services/webservice";
import { parseValueIndex } from "src/utils/Parse";

interface CartMetaScheduleFieldProps {
  name: string;
  label: string;
  options: SettingEnumMeta[];
  value?: string;
}

export default function CartMetaScheduleField({ name, label, options, value }: CartMetaScheduleFieldProps) {
  const selectedSchedule = parseValueIndex(options, value);

  const {
    register
  } = useFormContext();

  if (selectedSchedule < 0) return (
    <>
      <label htmlFor="inventorySyncSchedule" className="block text-sm font-medium text-gray-700">
        {`${label} (CUSTOM)`}
      </label>
      <div className="mt-1">
        <input
          type="text"
          {...register(`${name}` as const)}
          id="inventorySyncSchedule"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:text-gray-300 disabled:border-gray-200"
          disabled
        />
      </div>
      <FormError message='This setting has been customized. Contact support to change it (you can edit other settings).' type='warning' />
    </>
  );

  return <SettingsMetaEnum label={label} name={name} options={options} defaultOptionKey={selectedSchedule} />;
}