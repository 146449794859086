import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

export type SeverityTypes = "info" | "error" | "warn" | "success";

interface SeverityIconProps {
  type?: SeverityTypes;
}

export default function SeverityIcon({ type }: SeverityIconProps) {
  switch (type) {
    case "success":
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    case "error":
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      );
    case "warn":
      return (
        <ExclamationTriangleIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      );
    default:
      return (
        <InformationCircleIcon
          className="h-6 w-6 text-blue-400"
          aria-hidden="true"
        />
      );
  }
}
