import EventLogStatus from "./EventLogStatus";

import { EventLogItem } from "src/services/webservice";

interface EventLogListItemProps {
  log: EventLogItem;
  logIdx: number;
  count: number | undefined;
}

export default function EventLogListItem({
  log,
  logIdx,
  count,
}: EventLogListItemProps) {
  return (
    <li key={log.eventLogItemId}>
      <div className="relative pb-8">
        {count && logIdx !== count - 1 ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <EventLogStatus status={log.logLevel} />
          <div className="min-w-0 flex-1 sm:pt-1.5 flex justify-between sm:space-x-4 flex-wrap-reverse">
            <div>
              <p className="text-sm text-gray-500">{log.message}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
