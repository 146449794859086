interface ButtonGroupProps {
  children: React.ReactNode
}

export default function ButtonGroup({children}:ButtonGroupProps) {
  return (
    <div className="flex flex-wrap space-x-2 justify-end mt-4">
      {children}
    </div>
  );
}