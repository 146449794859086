import SettingsMetaEnum from "../meta/SettingMetaEnum";
import NoWmsTargetDialog from "./NoWmsTargetDialog";
import LoadingInline from "../../common/LoadingInline";
import FormError from "../../common/FormError";

import useGetWms, { Formats } from "../../services/wms/useGetWms";
import { SettingEnumMeta } from "../../services/webservice";
import { parseValueIndex } from "../../utils/Parse";

interface AvailableWmsProps {
  selectedWms?: number | undefined;
}

export default function AvailableWms({ selectedWms }: AvailableWmsProps) {
  const { data, error, loading } = useGetWms<SettingEnumMeta>({
    format: Formats.Enum,
  });
  if (error)
    return (
      <div className="mb-4">
        <p className="block text-sm font-medium text-gray-700">WMS Target</p>
        <FormError message={error} />
      </div>
    );

  if (loading)
    return (
      <div className="mb-4">
        <p className="block text-sm font-medium text-gray-700">WMS Target</p>
        <div className="border border-gray-300 rounded-md p-2">
          <LoadingInline message="Loading WMS Targets..." />
        </div>
      </div>
    );
  if (data.length === 0) return <NoWmsTargetDialog open={true} />;

  if (selectedWms) {
    const defaultKey = parseValueIndex(data, selectedWms);
    return (
      <div className="mb-4">
        <SettingsMetaEnum
          name="wmsConnectionId"
          label="WMS Target"
          options={data}
          defaultOptionKey={defaultKey}
        />
      </div>
    );
  }

  return (
    <div className="mb-4">
      <SettingsMetaEnum
        name="wmsConnectionId"
        label="WMS Target"
        options={data}
      />
    </div>
  );
}
