import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { DateTime } from "luxon";

import Navigation from "src/common/Navigation";
import ErrorFallback from "src/common/ErrorBoundary";
import AccountHeader from "src/common/AccountHeader";
import AccountCheck from "src/common/AccountCheck";
import { Banner } from "../announcement/Banner";

interface MainLayoutProps {
  role: string;
}

export default function MainLayout({ role }: MainLayoutProps) {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(true);
  const resetErrorBoundary = () => {
    setRefresh(false);
    navigate("/integrations");
  };
  const year = DateTime.local().year;
  return (
    <>
      <Banner />
      <div className="min-h-screen bg-gray-100">
        <Navigation />
        {(role === "Super" || role === "Staff") && (
          <>
            <AccountHeader />
            <AccountCheck />
          </>
        )}
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={resetErrorBoundary}
          resetKeys={[refresh]}
        >
          <Outlet />
        </ErrorBoundary>
        <p className="mt-8 pb-4 text-center text-base text-gray-400">
          &copy; {year} eFulfillment Service, Inc. All rights reserved.{" "}
          {process.env.REACT_APP_BUILD_NUMBER}
        </p>
      </div>
    </>
  );
}
