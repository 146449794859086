import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import { FingerPrintIcon } from "@heroicons/react/24/outline";

import Button from "src/common/buttons/Button";

import { apiInstance } from "src/services/api";
import { parseAxiosErrors } from "src/utils/Parse";

interface IntegrationConfigOauthButtonProps {
  integrationConfigId: number;
}

export function IntegrationConfigOauthButton({
  integrationConfigId,
}: IntegrationConfigOauthButtonProps) {
  const [searchParams] = useSearchParams();

  const handleAuthorization = async () => {
    const params = searchParams.toString();
    toast
      .promise(
        apiInstance.get(`/OAuth/authorize/${integrationConfigId}?${params}`),
        {
          loading: "Requesting Authorization...",
          success: "Granted!",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then((response) => {
        window.location.href = response.data.oauthRedirectUrl;
      });
  };

  return (
    <div>
      <Button
        icon={<FingerPrintIcon className="mr-2 h-6 w-6" />}
        label="Request OAuth Handshake"
        func={handleAuthorization}
      />
    </div>
  );
}
