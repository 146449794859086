import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";

import {
  ArrowLeftOnRectangleIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";

import logo from "../../logo.svg";
import login from "../../login.jpg";
import FormError from "../../common/FormError";

import * as AuthService from "../../services/auth.service";
import Button from "../../common/buttons/Button";

interface IFormInputs {
  email: string;
}

export default function ForgotPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [buttonDisabled, setbuttonDisabled] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInputs>();
  const watchEmail = watch("email", "");

  useEffect(() => {
    setbuttonDisabled(watchEmail.length === 0);
  }, [watchEmail]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setLoading(true);
    toast
      .promise(AuthService.passwordResetRequest(data.email), {
        loading: "Requesting password reset...",
        success: "Check your email for confirmation",
        error: (err) => err.response.data.toString(),
      })
      .then(() => setRequestSent(true))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="w-auto" src={logo} alt="EFS logo" />
              {requestSent ? (
                <>
                  <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">
                    Request Sent
                  </h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    Check your email for further instructions.{" "}
                    <Button
                      label="Click here to go back to the sign in page."
                      path="/signin"
                      design="text"
                    />
                  </p>
                </>
              ) : (
                <>
                  <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">
                    Request Password Reset
                  </h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    Enter your email address and we will send you a link to
                    reset your password.
                  </p>
                </>
              )}
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                  {!requestSent && (
                    <>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            {...register("email", {
                              required: true,
                              pattern:
                                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
                            })}
                            placeholder="frank@smiles.com"
                            type="text"
                            autoComplete="email"
                            autoFocus
                            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                              errors.email
                                ? "border-red-300 placeholder-red-300 focus-within:border-red-300"
                                : "placeholder-gray-400 border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            }`}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <FormError message="Email address is required" />
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <FormError message="Invalid email format" />
                          )}
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={loading || buttonDisabled}
                          className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                    ${
                      loading || buttonDisabled
                        ? "opacity-20 text-gray-400"
                        : "hover:bg-gray-700"
                    }`}
                        >
                          {loading ? (
                            <>
                              <CloudIcon className="animate-bounce h-6 w-6 mr-2" />
                              Loading...
                            </>
                          ) : (
                            <>
                              <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-2" />
                              Send Request
                            </>
                          )}
                        </button>
                      </div>

                      <div className="text-center">
                        <div className="text-sm">
                          <Button label="Cancel" path="/signin" design="text" />
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={login}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
