import { useNavigate } from "react-router";

import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

import { parseClassNames } from "../../utils/Parse";
import { BUTTONS } from "../../utils/styles";

interface ButtonProps {
  type?: "submit" | "button";
  design?: "primary" | "secondary" | "delete" | "text";
  custom?: string;
  path?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  label: string;
  srOnly?: string;
  func?: () => void;
}

export default function Button({
  type = "button",
  design = "primary",
  custom = "",
  path,
  disabled = false,
  loading = false,
  icon,
  label,
  srOnly,
  func,
}: ButtonProps) {
  const navigate = useNavigate();
  let styles;
  let combineBase = true;
  switch (design) {
    case "primary":
      styles = BUTTONS.primary;
      break;
    case "secondary":
      styles = BUTTONS.secondary;
      break;
    case "delete":
      styles = BUTTONS.delete;
      break;
    case "text":
      styles = BUTTONS.text;
      combineBase = false;
      break;
  }
  return (
    <button
      type={type}
      className={combineBase ? parseClassNames(BUTTONS.base, styles, custom) : parseClassNames(styles, custom)}
      disabled={disabled || loading}
      onClick={func ? func : () => navigate(path || "")}
    >
      {loading ? <ButtonLoadingSpinner /> : <span className="hidden sm:inline">{icon}</span>}
      {label}
      {!label && !icon && 'Submit'}
      {srOnly && <span className="sr-only">{srOnly}</span>}
    </button>
  );
}
