import { ClockIcon } from "@heroicons/react/24/solid";

interface HoldOrdersProps {
  holdOrderHours?: number;
}

const HoldOrders = ({ holdOrderHours }: HoldOrdersProps) => {
  if (!holdOrderHours) return null;
  return (
    <div className="sm:mt-0 flex-shrink-0 flex">
      <p className="px-2 text-xs flex items-center leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
        <ClockIcon
          className="flex-shrink-0 mr-1.5 h-4 w-4"
          aria-hidden="true"
        />
        HOLD {holdOrderHours} HRS
      </p>
    </div>
  );
};

export default HoldOrders;
