import LoadingCard from "../../../common/LoadingCard";
import EmptyList from "../../../common/EmptyList";

import PendingOrderDetailDialog from "./PendingOrderDetailDialog";

import { Order } from "../../../services/webservice";
import { parseClassNames } from "../../../utils/Parse";
import { TABLE_COLUMN_TEXT } from "../../../utils/styles";

interface PendingOrdersContentProps {
  data: Order[];
  loading: boolean;
  refresh: () => void;
}

export default function PendingOrdersContent({
  data,
  loading,
  refresh,
}: PendingOrdersContentProps) {
  if (loading)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <LoadingCard />
          </div>
        </td>
      </tr>
    );

  if (data.length === 0)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <EmptyList
              title="No Pending Orders"
              description="No orders match the settings from your integration configuration"
              buttonPath=""
              buttonLabel=""
              displayButton={false}
            />
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {data.length > 0 &&
        data.map((order, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-500">{order.orderNumber}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {order.referenceNumber}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {order.shipAddress?.lastName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {order.shipAddress?.country}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{order.shipMethod}</td>
            <td
              className={parseClassNames(TABLE_COLUMN_TEXT.base, "text-right")}
            >
              <PendingOrderDetailDialog order={order} refresh={refresh} />
            </td>
          </tr>
        ))}
    </>
  );
}
