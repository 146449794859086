import { createContext, useContext } from "react";

import { AccountDescriptor } from "./services/webservice";

export type AccountType = AccountDescriptor;

export type AccountContextType = {
  account: AccountDescriptor;
  setAccount: (account: AccountDescriptor) => void;
};

const defaultAccount: AccountDescriptor = {
  accountId: 0,
  accountName: "",
  accountReference: "",
};

export const AccountContext = createContext<AccountContextType>({
  account: defaultAccount,
  setAccount: (account) => console.warn("no account provider"),
});
export const useAccount = () => useContext(AccountContext);
