import toast from "react-hot-toast";
import {
  ArrowSmallLeftIcon,
  XMarkIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";

import EventLogStatusBadge from "./EventLogStatusBadge";
import Button from "../../../common/buttons/Button";
import ButtonRound from "src/common/buttons/ButtonRound";
import EventLogListItem from "./EventLogListItem";

import { EventLog } from "../../../services/webservice";
import { parseDate } from "../../../utils/Parse";

interface EventDetailsProps {
  event: EventLog;
  buttonFunc: () => void;
  buttonLabel?: string;
}

export default function EventDetails({
  event,
  buttonFunc,
  buttonLabel = "Back to Event Logs",
}: EventDetailsProps) {
  // function to copy log url to clipboard
  const handleClipboard = () => {
    navigator.clipboard.writeText(event?.logUrl || "");
    toast.success("Log URL copied to clipboard");
  };

  return (
    <>
      <div className="bg-white border-b border-gray-200 pb-5 mb-5">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-500 flex">
              Event {event.eventLogId}:
              <span className="text-gray-900 ml-2">{event?.eventLogType}</span>
            </h3>
            {event && (
              <>
                <div className="flex items-center space-x-4 mt-2">
                  <p className="text-sm text-gray-500">
                    <time dateTime={parseDate(event.startTime)}>
                      {parseDate(event.startTime)}
                    </time>
                  </p>
                  <div>
                    <EventLogStatusBadge text={event?.eventLogStatus || ""} />
                  </div>
                </div>
                {event?.correlationId && (
                  <div className="mt-2">
                    {event?.logUrl ? (
                      <div className="flex space-x-2">
                        <div>
                          <Button
                            label={event?.correlationId}
                            design="text"
                            func={() =>
                              window.open(event?.logUrl || "", "_blank")
                            }
                            custom="text-sm"
                          />
                        </div>
                        <div>
                          <ButtonRound
                            design="primary"
                            icon={
                              <ClipboardDocumentIcon
                                className="h-3 w-3"
                                aria-hidden="true"
                              />
                            }
                            func={handleClipboard}
                            label="Copy Log URL to Clipboard"
                            custom="ml-2"
                          />
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">
                        {`correlation id: ${event?.correlationId}`}
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <Button
              label={buttonLabel}
              icon={
                buttonLabel === "Close" ? (
                  <XMarkIcon className="mr-2 h-6 w-6" />
                ) : (
                  <ArrowSmallLeftIcon className="mr-2 h-6 w-6" />
                )
              }
              design="secondary"
              func={buttonFunc}
            />
          </div>
        </div>
      </div>

      <div className="flow-root">
        <ul className="mb-4">
          {event?.items &&
            event.items.length > 0 &&
            event.items.map((log, logIdx) => (
              <EventLogListItem
                log={log}
                logIdx={logIdx}
                count={event.items?.length}
              />
            ))}
        </ul>
      </div>
    </>
  );
}
