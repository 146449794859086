import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import DialogOverlay from "src/common/DialogOverlay";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";

interface UserDeleteButtonProps {
  id?: number;
}

export default function UserDeleteButton({ id }: UserDeleteButtonProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleDeleteUser = () => {
    closeModal();
    toast
      .promise(apiInstance.delete(`/Users/${id}`, { params }), {
        loading: "Deleting user...",
        success: "User deleted",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        navigate("/users");
      });
  };

  if (!id) return null;
  return (
    <>
      <Button design="delete" func={openModal} label="Delete" />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={isOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Permanently delete this user?
                    </p>
                  </div>

                  <ButtonGroup>
                    <Button
                      label="Cancel"
                      design="secondary"
                      func={closeModal}
                    />
                    <Button
                      label="Yes, Delete"
                      func={handleDeleteUser}
                      design="delete"
                    />
                  </ButtonGroup>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
