import ButtonRound from "../../common/buttons/ButtonRound";

interface IntegrationConfigShortcutButtonProps {
  label: string;
  configName: string;
  icon?: React.ReactNode;
  clicker: () => void;
}

export default function IntegrationConfigShortcutButton({
  label,
  configName,
  icon,
  clicker,
}: IntegrationConfigShortcutButtonProps) {
  return (
    <div className="text-center text-sm text-gray-400">
      <ButtonRound
        icon={icon}
        func={clicker}
        label={`${label} for ${configName}`}
        design="primary"
      />
      <div className="mt-1 text-base sm:text-sm">{label}</div>
    </div>
  );
}
