import { useState } from "react";

import LoadingInline from "src/common/LoadingInline";
import { InfoAlert } from "src/common/InfoAlert";
import { WmsTrackingToggle } from "./WmsTrackingSentToggle";

import useGetList from "src/services/useGetList";
import { getErrorMessage } from "src/utils/Parse";

interface WmsTrackingsListProps {
  id: number | undefined;
}

export function WmsTrackingsList({ id }: WmsTrackingsListProps) {
  const [refresh, setRefresh] = useState<number>(1);
  const { data, error, loading } = useGetList(
    `/Orders/${id}/Tracking`,
    1,
    100,
    refresh
  );

  if (loading) return <LoadingInline message="Loading tracking triage..." />;

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  };

  return (
    <div className="sm:col-span-2" id="order-tracking">
      <dt className="text-sm font-medium text-gray-500">Tracking Triage</dt>
      {error ? (
        <div className="mt-1 max-w-sm">
          <InfoAlert message={getErrorMessage(error)} type="error" />
        </div>
      ) : (
        <dd className="mt-1 text-sm text-gray-900">
          {data && data.length === 0 ? (
            <span>No trackings found</span>
          ) : (
            <ul>
              {data.map((tracking: any) => (
                <li key={tracking.wmsTrackingId}>
                  <div className="relative pb-4">
                    <div className="relative flex space-x-3">
                      <WmsTrackingToggle
                        tracking={tracking}
                        refresh={handleRefresh}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </dd>
      )}
    </div>
  );
}
