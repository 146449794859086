import CartMethod from "./CartMethod";
import WmsMethod from "./WmsMethod";

interface ShipMappingProps {
  cartMethod?: string;
  wmsMethod?: string;
}

export default function ShipMapping({
  cartMethod,
  wmsMethod,
}: ShipMappingProps) {
  if (!cartMethod && !wmsMethod) return null;
  return (
    <div>
      <CartMethod method={cartMethod} />
      <WmsMethod method={wmsMethod} />
    </div>
  );
}
