import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

import { EyeIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

import OrderDetails from "./OrderDetails";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import Button from "../../common/buttons/Button";
import DialogOverlay from "src/common/DialogOverlay";

import { apiInstance } from "../../services/api";
import { useAccount } from "../../AccountContext";
import { parseAccountParams, parseAxiosErrors } from "../../utils/Parse";
import { Order } from "../../services/webservice";

interface OrderDetailDialogProps {
  id?: number;
  orderNumber?: string;
  refresh: () => void;
}

export default function OrderDetailDialog({
  id,
  orderNumber,
  refresh,
}: OrderDetailDialogProps) {
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reSubmitloading, setReSubmitLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  function closeModal() {
    setDialogOpen(false);
    shouldRefresh && refresh();
  }

  const handleOrderDetails = (orderId: number | undefined) => {
    toast
      .promise(apiInstance.get(`/Orders/${id}`, { params }), {
        loading: "Loading order...",
        success: "Order loaded",
        error: (err) => parseAxiosErrors(err),
      })
      .then((res) => {
        setOrder(res.data);
        setDialogOpen(true);
      });
  };

  const handleReSubmitOrder = (orderId: number | undefined) => {
    setReSubmitLoading(true);
    toast
      .promise(
        apiInstance.post(`/Orders/${orderId}/resubmit`, null, { params }),
        {
          loading: "Resubmitting order...",
          success: "Order has been successfully submitted",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => {
        setShouldRefresh(true);
        handleOrderDetails(orderId);
      })
      .finally(() => {
        setReSubmitLoading(false);
      });
  };

  return (
    <>
      <Button
        label="Details"
        srOnly={`, order ${orderNumber}`}
        func={() => handleOrderDetails(id)}
        design="secondary"
        icon={<EyeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      />
      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={dialogOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Order Details
                  </Dialog.Title>
                  {order && (
                    <>
                      <OrderDetails
                        order={order}
                        refreshParent={refresh}
                        setParentDialogOpen={setDialogOpen}
                      />
                      <ButtonGroup>
                        <Button
                          label="Close"
                          func={closeModal}
                          design="secondary"
                        />
                        {!order?.wmsId && (
                          <Button
                            label="Re-submit"
                            func={() => handleReSubmitOrder(order?.orderId)}
                            icon={
                              <ArrowPathIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                            }
                            loading={reSubmitloading}
                          />
                        )}
                      </ButtonGroup>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
