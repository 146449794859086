import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import OrderDetailDialog from "./OrderDetailDialog";
import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import Pagination from "../../common/Pagination";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import OrderStatusBadge from "./OrderStatusBadge";
import Button from "src/common/buttons/Button";

import useGetList, { SortType } from "../../services/useGetList";
import { Order } from "../../services/webservice";
import {
  TABLE_COLUMN_HEADER_TEXT,
  TABLE_COLUMN_SORT,
  TABLE_COLUMN_TEXT,
  TEXT_INPUT,
} from "../../utils/styles";
import { parseClassNames, parseDate } from "../../utils/Parse";

interface IOrderSearchFormProps {
  orderNumber: string;
}

export type OrderStatusType =
  | "Open"
  | "Rejected"
  | "Completed"
  | "Tracking Error"
  | "";
export type RadioStatusOptionType = {
  id: string;
  title: string;
  filter: OrderStatusType;
};

const orderStatuses: RadioStatusOptionType[] = [
  { id: "status-all", title: "All", filter: "" },
  { id: "status-rejected", title: "Rejected", filter: "Rejected" },
  { id: "status-open", title: "Open", filter: "Open" },
  { id: "status-completed", title: "Completed", filter: "Completed" },
  {
    id: "status-trackingerror",
    title: "Tracking Error",
    filter: "Tracking Error",
  },
];

export default function OrdersPage() {
  const isInitialRender = useRef(true);
  let [searchParams] = useSearchParams();
  let integrationConfigId = searchParams.get("integrationConfigId");
  let createdAfter = searchParams.get("createdAfter");
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<SortType[]>([
    { field: "dateCreated", direction: "desc" },
  ]);
  const [statusParam, setStatusParam] = useState<OrderStatusType>("");
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [filter, setFilter] = useState<string>(
    integrationConfigId ? `&integrationConfigId=${integrationConfigId}` : ""
  );
  const [refreshKey, setRefreshKey] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const { loading, data, error, totalPages, totalRecords } = useGetList<Order>(
    "/orders",
    page,
    pageSize,
    refreshKey,
    sort,
    filter
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IOrderSearchFormProps>();

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const updatedFilter = handleFilter();
    setFilter(updatedFilter);
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusParam, orderNumber, createdAfter]);

  const refreshData = () => setRefreshKey(refreshKey + 1);

  const onSubmit: SubmitHandler<IOrderSearchFormProps> = (data) => {
    setOrderNumber(encodeURIComponent(data.orderNumber));
  };

  const handleFilter = () => {
    let newFilter = integrationConfigId
      ? `&integrationConfigId=${integrationConfigId}`
      : "";
    newFilter += createdAfter ? `&CreatedAfter=${createdAfter}` : "";
    newFilter += statusParam ? `&status=${statusParam}` : "";
    newFilter += orderNumber ? `&orderNumber=${orderNumber}` : "";
    console.log(newFilter);
    return newFilter;
  };

  const handleSort = (field: string) => {
    let newSort: SortType = { field, direction: "asc" };
    if (sort[0].field === newSort.field) {
      newSort.direction = sort[0].direction === "asc" ? "desc" : "asc";
    }
    setSort([newSort]);
  };

  const handleStatusFilter = (status: OrderStatusType) => {
    setStatusParam(status);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Orders"
          icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Orders"
        icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
        refresh={refreshData}
        description={`List of all orders from all integrations${
          integrationConfigId
            ? " (currently filtered to a single integration)"
            : ""
        }.`}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-2">
        <div className="w-full rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                  <span>Filter Orders</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-primary-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 flex sm:space-x-10 space-y-4 sm:space-y-0 flex-wrap">
                  <div>
                    <p className="text-sm leading-5 text-gray-500">By Status</p>
                    <fieldset>
                      <legend className="sr-only">Event Status</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                        {orderStatuses.map((status) => (
                          <div key={status.id} className="flex items-center">
                            <input
                              id={status.id}
                              name="event-status"
                              type="radio"
                              defaultChecked={status.filter === statusParam}
                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                              onClick={() => handleStatusFilter(status.filter)}
                            />
                            <label
                              htmlFor={status.id}
                              className="ml-2 block text-sm font-medium text-gray-700"
                            >
                              {status.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap space-x-4 space-y-2 sm:space-y-0">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="text"
                              {...register("orderNumber", { required: true })}
                              id="orderNumber"
                              className={parseClassNames(
                                "block w-full focus:outline-none rounded-none rounded-l-md  sm:text-sm",
                                errors.orderNumber
                                  ? TEXT_INPUT.error
                                  : TEXT_INPUT.valid
                              )}
                              placeholder="By Order Number"
                            />
                          </div>
                          <button
                            type="submit"
                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          >
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            <span>Search</span>
                          </button>
                        </div>
                        <Button
                          label="clear search"
                          design="text"
                          func={() => {
                            setOrderNumber("");
                            reset();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <PageBody table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className={parseClassNames(
                          TABLE_COLUMN_HEADER_TEXT.base,
                          "min-w-[7rem]"
                        )}
                      >
                        <button
                          onClick={() => handleSort("orderNumber")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Order #
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "orderNumber"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "orderNumber" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Reference #
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <button
                          onClick={() => handleSort("dateCreated")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Date
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "dateCreated"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "dateCreated" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <button
                          onClick={() => handleSort("status")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Status
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "status"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "status" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Integration
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        WMS ID
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <LoadingCard />
                        </td>
                      </tr>
                    ) : (
                      data.map((order, index) => (
                        <tr key={index}>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            {order.orderNumber}
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            {order.referenceNumber}
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            {parseDate(order.dateCreated)}
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            <OrderStatusBadge
                              text={order.status || undefined}
                            />
                          </td>
                          <td
                            className={parseClassNames(
                              TABLE_COLUMN_TEXT.base,
                              TABLE_COLUMN_TEXT.secondary
                            )}
                          >
                            {order.integrationConfigName}
                          </td>
                          <td
                            className={parseClassNames(
                              TABLE_COLUMN_TEXT.base,
                              TABLE_COLUMN_TEXT.secondary
                            )}
                          >
                            {order.wmsId === 0 ? "N/A" : order.wmsId}
                          </td>
                          <td
                            className={parseClassNames(
                              TABLE_COLUMN_TEXT.base,
                              "text-right"
                            )}
                          >
                            <OrderDetailDialog
                              id={order.orderId}
                              orderNumber={order.orderNumber || ""}
                              refresh={refreshData}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
