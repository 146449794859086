import { Link } from "react-router-dom";
import {
  ChevronRightIcon,
  GlobeAltIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import ErrorAlert from "../../common/ErrorAlert";

import { WmsDescriptor } from "../../services/webservice";
import useGetList from "../../services/useGetList";
import LoadingCard from "../../common/LoadingCard";

export default function AddIntegrationsPage() {
  const { loading, data, error } = useGetList<WmsDescriptor>(
    "/Meta/wms",
    1,
    100
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Ship Map"
          icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Add Wms"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        description="Please choose from the list of WMS targets below:"
      />
      <PageBody>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200">
            {loading && (
              <div className="text-center py-10">
                <LoadingCard />
              </div>
            )}
            {data.map((wms) => (
              <li key={wms.identifier}>
                <Link
                  to={wms.identifier || ""}
                  className="block hover:bg-gray-50"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-gray-600 truncate">
                            {wms.name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <span>{wms.description}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </PageBody>
    </div>
  );
}
