import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";

import { PlusSmallIcon } from "@heroicons/react/24/solid";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import TextInput from "src/common/forms/TextInput";
import DialogOverlay from "src/common/DialogOverlay";
import FormError from "src/common/FormError";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { CartItem } from "src/services/webservice";
import { getUser } from "src/services/auth.service";

interface AddCustomCartItemButtonProps {
  integrationConfigId: string | undefined;
  item?: CartItem;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  refresh: () => void;
  resetCustomItem: () => void;
}

interface IFormInputs {
  name: string;
  sku: string;
  declaredValue: number;
}

export default function AddCustomCartItemButton({
  integrationConfigId,
  item,
  isOpen,
  setIsOpen,
  refresh,
  resetCustomItem,
}: AddCustomCartItemButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const user = getUser();
  useEffect(() => {
    methods.setValue("name", item?.name || "");
    methods.setValue("sku", item?.sku || "");
    methods.setValue("declaredValue", item?.declaredValue || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const methods = useForm<IFormInputs>({
    defaultValues: {
      declaredValue: 0,
    },
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setLoading(true);
    let response;
    let loadingMessage = "Updating...";
    let successMessage = "Custom item updated";
    if (item) {
      response = apiInstance.put(
        `/cart/${integrationConfigId}/items/${item.cachedCartItemId}`,
        {
          cachedCartItemId: item.cachedCartItemId,
          Name: data.name,
          Sku: data.sku,
          declaredValue: data.declaredValue,
        },
        { params }
      );
    } else {
      response = apiInstance.post(
        `/cart/${integrationConfigId}/items`,
        [{ Name: data.name, Sku: data.sku, DeclaredValue: data.declaredValue }],
        { params }
      );
      loadingMessage = "Creating custom item...";
      successMessage = "Custom item created";
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        resetCustomItem();
        setIsOpen(false);
        methods.reset();
        refresh();
      })
      .finally(() => setLoading(false));
  };

  const handleBlankSku = () => {
    setLoading(true);

    const response = apiInstance.post(
      `/cart/${integrationConfigId}/items`,
      [{ Name: "Blank SKU", Sku: "", DeclaredValue: 0 }],
      { params }
    );

    toast
      .promise(response, {
        loading: "Creating blank SKU...",
        success: "Blank SKU created",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        setIsOpen(false);
        refresh();
      })
      .finally(() => setLoading(false));
  };

  const closeModal = () => {
    setIsOpen(false);
    resetCustomItem();
  };
  const openModal = () => {
    resetCustomItem();
    setIsOpen(true);
  };

  if (integrationConfigId) {
    return (
      <>
        <Button
          label="Add Custom Item"
          design="secondary"
          icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          func={openModal}
        />
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            open={isOpen}
            onClose={closeModal}
          >
            <DialogOverlay />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {item ? "Edit" : "Add"} Custom Cart Item
                      <p className="text-sm font-light text-gray-500 mt-2">
                        If you need to accept orders for an item that does not
                        exist in your cart, or is not visible, use this form to
                        create it.
                      </p>
                    </Dialog.Title>
                    <div className="mt-2">
                      <FormProvider {...methods}>
                        <form
                          onSubmit={methods.handleSubmit(onSubmit)}
                          className="space-y-4"
                        >
                          <TextInput
                            label="Item Name"
                            name="name"
                            type="text"
                            placeholder="Blue Widget"
                            required
                          />
                          <TextInput
                            label="Item SKU"
                            name="sku"
                            type="text"
                            placeholder="WIDGET-BLUE"
                            required
                            disabled={item ? true : false}
                          />
                          {item && (
                            <FormError
                              type="warning"
                              message="You must delete and recreate if the SKU needs to be changed"
                            />
                          )}
                          <TextInput
                            label="Declared Value"
                            name="declaredValue"
                            type="number"
                            placeholder="13.00"
                          />
                          <ButtonGroup>
                            <Button
                              label="Cancel"
                              design="secondary"
                              func={closeModal}
                            />
                            <Button
                              label={item ? "Update" : "Add"}
                              icon={
                                !item && (
                                  <PlusSmallIcon className="h-5 w-5 mr-2" />
                                )
                              }
                              type="submit"
                              loading={loading}
                            />
                          </ButtonGroup>
                          {(user?.role === "Super" || user?.role === "Staff") &&
                            !item && (
                              <Button
                                label="Create Blank SKU"
                                design="secondary"
                                func={handleBlankSku}
                              />
                            )}
                        </form>
                      </FormProvider>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  } else {
    return <></>;
  }
}
