import { parseClassNames } from "src/utils/Parse";

interface CarrierChipProps {
  carrier: string | null;
}

export const CarrierChip = ({ carrier }: CarrierChipProps) => {
  if (!carrier) return null;

  let carrierColor = "bg-gray-50 text-gray-700 ring-gray-700/10";
  switch (carrier.toLowerCase()) {
    case "fedex":
      carrierColor = "bg-purple-50 text-purple-700 ring-purple-700/10";
      break;
    case "ups":
      carrierColor = "bg-yellow-50 text-yellow-700 ring-yellow-700/10";
      break;
    case "usps":
      carrierColor = "bg-primary-50 text-primary-700 ring-primary-700/10";
      break;
    case "default":
      carrierColor = "bg-green-50 text-green-700 ring-green-700/10";
      break;
  }

  return (
    <span
      className={parseClassNames(
        carrierColor,
        "inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset "
      )}
    >
      {carrier}
    </span>
  );
};
