import { parseClassNames } from "../../utils/Parse";

interface WmsTrackingSentToggleStatusProps {
  loading: boolean;
  enabled: boolean;
  status: string;
}

export function WmsTrackingSentToggleStatus({
  loading,
  enabled,
  status,
}: WmsTrackingSentToggleStatusProps) {
  let style = "";
  switch (status) {
    case "Success":
      style = "bg-green-500";
      break;
    case "Received":
      style = "bg-primary-500";
      break;
    case "Fail":
      style = "bg-red-500";
      break;
    default:
      style = "bg-gray-500";
  }

  if (loading)
    return (
      <span className="mr-2 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
        <span className="absolute flex-shrink-0 flex items-center justify-center">
          <span
            className="h-1.5 w-1.5 rounded-full bg-yellow-500"
            aria-hidden="true"
          ></span>
        </span>
        <span className="ml-3.5 font-medium text-gray-900">Loading</span>
      </span>
    );

  return (
    <span className="mr-2 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
      <span className="absolute flex-shrink-0 flex items-center justify-center">
        <span
          className={parseClassNames(style, "h-1.5 w-1.5 rounded-full")}
          aria-hidden="true"
        ></span>
      </span>
      <span className="ml-3.5 font-medium text-gray-900">{status}</span>
    </span>
  );
}
