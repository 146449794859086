import { useState, useEffect } from "react";

import { apiInstance } from "./api";
import { useAccount } from "../AccountContext";
import { parseAccountParams } from "../utils/Parse";

export default function useGetOne<T>(
  url: string,
  reload: number = 0,
  passAccount = false
) {
  const { account } = useAccount();
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const params = passAccount ? {} : parseAccountParams(account);
    async function init() {
      try {
        const response = await apiInstance.get(url, { params });
        if (response.status === 200) {
          setData(response.data);
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || "Unknown error");
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [url, account, reload, passAccount]);

  return { data, error, loading };
}
