import { GlobeAltIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import { ShippingProfileForm } from "./ShippingProfileForm";

export function AddShippingProfilePage() {
  const error = null;
  const loading = null;

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Add Shipping Profile"
          icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Add Shipping Profile"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>{loading ? <LoadingCard /> : <ShippingProfileForm />}</PageBody>
    </div>
  );
}
