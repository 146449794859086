import { GlobeAltIcon } from "@heroicons/react/24/solid";

interface WmsDescriptionProps {
  wmsDescription?: string;
}

const WmsDescription = ({ wmsDescription }: WmsDescriptionProps) => {
  if (!wmsDescription) return null;
  return (
    <div className="py-2 sm:mt-0 flex-shrink-0 flex">
      <p className="px-2 text-xs flex items-center leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
        <GlobeAltIcon
          className="flex-shrink-0 mr-1.5 h-4 w-4"
          aria-hidden="true"
        />
        {wmsDescription}
      </p>
    </div>
  );
};

export default WmsDescription;
