import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import ErrorAlert from "../../common/ErrorAlert";
import CartMetaForm from "./CartMetaForm";
import LoadingCard from "../../common/LoadingCard";
import { IntegrationConfigReAuthButton } from "./IntegrationConfigReAuthButton";

import useEditIntegration from "../../services/integrations/useEditIntegration";
import { IntegrationConfigOauthButton } from "./IntegrationConfigOauthButton";

export default function EditWmsMetaPage() {
  const { integrationConfigId } = useParams();
  const { loading, data, meta, error } = useEditIntegration({
    integrationConfigId: Number(integrationConfigId),
  });

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Integration"
          icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Edit Integration"
        icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody overflow>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="pb-6">{data?.name} Settings</div>
              <div className="flex space-x-4">
                {data?.cartIdentifier === "ShopifyV2" && (
                  <IntegrationConfigReAuthButton config={data} />
                )}
                {meta?.requiresOAuthTokens &&
                  data?.cartIdentifier !== "ShopifyV2" && (
                    <IntegrationConfigOauthButton
                      integrationConfigId={Number(integrationConfigId)}
                    />
                  )}
              </div>
            </div>
            <CartMetaForm meta={meta} existing={data} />
          </>
        )}
      </PageBody>
    </div>
  );
}
