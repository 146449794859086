import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Cog8ToothIcon } from "@heroicons/react/24/outline";

import ErrorAlert from "src/common/ErrorAlert";
import PageHeader from "src/common/PageHeader";
import LoadingCard from "src/common/LoadingCard";
import PageBody from "src/common/PageBody";

import { apiInstance } from "src/services/api";
import { getErrorMessage, parseAccountParams } from "src/utils/Parse";
import { useAccount } from "src/AccountContext";

export function ShopifyV2Prefs() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>("");
  const { account } = useAccount();
  const params = parseAccountParams(account);

  useEffect(() => {
    // grab store params
    const shop = searchParams.get("shop");

    (async () => {
      try {
        // call verify hmac endpoint
        const storeVerification = await apiInstance.get(
          `/Oauth/getshopifystore/${shop}`,
          { params }
        );
        if (storeVerification.status === 200) {
          console.log(storeVerification.data);
          if (storeVerification.data.integrationConfigId === 0) {
            throw new Error(`No matching integration config found for ${shop}`);
          } else {
            navigate(
              `/integrations/${storeVerification.data.integrationConfigId}/edit`
            );
          }
        } else {
          throw new Error("Error verifying store");
        }
      } catch (error) {
        setError(getErrorMessage(error));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="ShopifyV2 Preferences"
          icon={<Cog8ToothIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );
  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="ShopifyV2 Preferences"
        icon={<Cog8ToothIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        <LoadingCard message="Checking installation..." />
      </PageBody>
    </div>
  );
}
