import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { ValidateResult } from "react-hook-form";

import { FORM_ERROR } from "../utils/styles";
import { parseClassNames } from "../utils/Parse";

interface FormErrorProps {
  message: string | undefined | ValidateResult;
  type?: "error" | "warning" | "info";
}

export default function FormError({ message, type = "error" }: FormErrorProps) {
  if (!message) return null;

  if (type === "warning")
    return (
      <div className={parseClassNames(FORM_ERROR.base, FORM_ERROR.warn)}>
        <ExclamationTriangleIcon className={FORM_ERROR.icons} />
        <span>{message}</span>
      </div>
    );

  if (type === "info")
    return (
      <div className={parseClassNames(FORM_ERROR.base, FORM_ERROR.info)}>
        <InformationCircleIcon className={FORM_ERROR.icons} />
        <span>{message}</span>
      </div>
    );

  return (
    <div className={parseClassNames(FORM_ERROR.base, FORM_ERROR.error)}>
      <ExclamationCircleIcon className={FORM_ERROR.icons} />
      <span>{message}</span>
    </div>
  );
}
