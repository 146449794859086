import SettingsMetaEnum from "./SettingMetaEnum";
import TextInput from "../../common/forms/TextInput";

import { parseValueIndex } from "../../utils/Parse";
import { SettingMeta } from "../../services/webservice";

const boolOptions = [
  { id: "false", name: "No" },
  { id: "true", name: "Yes" },
];

interface CartMetaFormProps {
  setting: SettingMeta;
  type: "wms" | "cart";
  defaultValue?: string | undefined;
  existingConfig?: any;
  isSuper?: boolean;
}

export default function CartMetaSettingField({
  setting,
  type,
  defaultValue,
  existingConfig,
  isSuper = false,
}: CartMetaFormProps) {
  const fieldPrefix = type === "wms" ? "wmsUserConfig." : "cartUserConfig.";
  const fieldId = fieldPrefix + setting.id;
  defaultValue =
    existingConfig?.cartUserConfig[setting.id]?.toString() || defaultValue;
  if (!setting.id) return <div>no setting provided</div>;

  if (
    setting.valueType === "String" ||
    setting.valueType === "Date" ||
    setting.valueType === "Long" ||
    setting.valueType === "Time" ||
    setting.valueType === "DateTime"
  )
    return (
      <TextInput
        label={setting.label}
        name={`${fieldId}` as const}
        type={setting.isSecret && !isSuper ? "password" : "text"}
        placeholder="enter your text"
        description={setting.description}
        required={setting.isRequired}
        disabled={setting.isReadonly}
      />
    );

  if (setting.valueType === "Integer" || setting.valueType === "Decimal")
    return (
      <TextInput
        label={setting.label}
        name={`${fieldId}` as const}
        type="number"
        placeholder="123"
        description={setting.description}
        required={setting.isRequired}
        defaultValue={
          setting.id === "automaticFulfillmentOrderAgeMinutes" ? 180 : undefined
        }
      />
    );

  if (setting.valueType === "Enum")
    return (
      <SettingsMetaEnum
        name={fieldId}
        label={setting.label}
        options={setting.enumValues}
        description={setting.description}
        defaultOptionKey={parseValueIndex(setting.enumValues, defaultValue)}
      />
    );

  if (setting.valueType === "Bool")
    return (
      <SettingsMetaEnum
        name={fieldId}
        label={setting.label}
        options={boolOptions}
        description={setting.description}
        defaultOptionKey={parseValueIndex(boolOptions, defaultValue)}
      />
    );

  return (
    <div>
      {setting.id} - {setting.valueType}
    </div>
  );
}
