import UserPermsCheckbox from "./UserPermsCheckbox";

export type PermissionType = {
  name: string;
  description: string;
};

interface UserPermsCheckboxFormProps {
  perms: string[];
  setOptions: (perms: string) => void;
  options: PermissionType[];
}

export default function UserPermsCheckboxForm({
  perms,
  setOptions,
  options,
}: UserPermsCheckboxFormProps) {
  console.log(perms);
  return (
    <div className="grow">
      <fieldset>
        <legend className="text-lg font-medium text-gray-900">
          Permissions
        </legend>
        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
          {options.map((map) => (
            <UserPermsCheckbox
              key={map.name}
              name={map.name}
              description={map.description}
              isChecked={Boolean(perms.find((x) => x === map.name))}
              value={map.name}
              setValue={setOptions}
            />
          ))}
        </div>
      </fieldset>
    </div>
  );
}
