import { useNavigate, useParams } from "react-router";

import { TruckIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import Button from "../../../common/buttons/Button";
import ShipMapsContent from "./ShipMapsContent";
import ErrorAlert from "../../../common/ErrorAlert";

import { ShipMethodMapDescriptor } from "../../../services/webservice";
import useGetList from "../../../services/useGetList";

export default function ShipMapsPage() {
  const navigate = useNavigate();
  const { integrationConfigId } = useParams();
  const { data, loading, error } = useGetList<ShipMethodMapDescriptor>(
    `/integrations/${integrationConfigId}/shipmethodmaps`,
    1,
    100
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Ship Maps"
          icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Ship Maps"
        icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        description="These mappings will override any global ship maps with the same incoming text."
        actionComp={
          <Button
            label="Add"
            func={() => navigate("add")}
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody>
        <ul className="divide-y divide-gray-200">
          <ShipMapsContent data={data} loading={loading} />
        </ul>
      </PageBody>
    </div>
  );
}
