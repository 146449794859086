import { Outlet, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";

import * as AuthService from "src/services/auth.service";

export default function RequireAuth() {
  let location = useLocation();
  const user = AuthService.getUser();
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (user.sessionStart && AuthService.hasExpired(user.sessionStart)) {
    toast.error("Local session expired");
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
