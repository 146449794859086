import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import LoadingCard from "../../common/LoadingCard";
import WmsMetaForm from "./WmsMetaForm";
import ErrorAlert from "src/common/ErrorAlert";

import useEditWms from "../../services/wms/useEditWms";

export default function EditWmsMetaPage() {
  const { wmsConnectionId } = useParams();
  const { loading, data, meta, error } = useEditWms({
    wmsConnectionId: Number(wmsConnectionId),
  });

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit WMS Connection"
          icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Edit WMS Connection"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            {data && (
              <>
                <div className="pb-6">Edit {data.description} Settings</div>
                <WmsMetaForm meta={meta} existing={data} />
              </>
            )}
          </>
        )}
      </PageBody>
    </div>
  );
}
