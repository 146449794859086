import { useParams } from "react-router";
import { TruckIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../../common/PageHeader";
import ShipMapForm from "./ShipMapForm";
import GlobalShipMapForm from "../../globalshipmaps/GlobalShipMapForm";

export default function AddShipMapPage() {
  const { integrationConfigId } = useParams();
  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Add Ship Map"
        icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
      />
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="py-2 px-4 sm:py-8 sm:px-0">
            <div className="bg-white shadow overflow-hidden sm:rounded-md p-8">
              {integrationConfigId ? (
                <ShipMapForm
                  integrationConfigId={Number(integrationConfigId)}
                />
              ) : (
                <GlobalShipMapForm />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
