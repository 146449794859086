import { useNavigate } from "react-router";

import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

import { parseClassNames } from "../../utils/Parse";
import { BUTTONS_ROUND } from "../../utils/styles";

interface ButtonProps {
  type?: "submit" | "button";
  design?: "primary" | "secondary" | "tertiary" | "delete" | "disabled";
  custom?: string;
  path?: string;
  disabled?: boolean;
  loading?: boolean;
  icon: React.ReactNode;
  label: string;
  func?: () => void;
}

export default function Button({
  type = "button",
  design = "primary",
  custom = "",
  path,
  disabled = false,
  loading = false,
  icon,
  label,
  func,
}: ButtonProps) {
  const navigate = useNavigate();
  let styles;
  let combineBase = true;
  return (
    <button
      type={type}
      className={
        combineBase
          ? parseClassNames(BUTTONS_ROUND.base, BUTTONS_ROUND[design], custom)
          : parseClassNames(styles, custom)
      }
      disabled={disabled || loading}
      onClick={func ? func : () => navigate(path || "")}
    >
      {loading ? <ButtonLoadingSpinner /> : icon}
      <span className="sr-only">{label}</span>
    </button>
  );
}
