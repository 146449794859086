import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

import { ArrowUpOnSquareStackIcon } from "@heroicons/react/24/outline";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import ButtonRound from "src/common/buttons/ButtonRound";
import DialogOverlay from "src/common/DialogOverlay";
import LoadingInline from "src/common/LoadingInline";
import { SettingTransferRadioOptions } from "./SettingTransferRadioOptions";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { BUTTONS_ROUND } from "src/utils/styles";
import { IntegrationConfigDescriptor } from "src/services/webservice";

interface IntegrationConfigSettingTransferButtonProps {
  id: number;
  refresh: () => void;
}

export default function IntegrationConfigSettingTransferButton({
  id,
  refresh,
}: IntegrationConfigSettingTransferButtonProps) {
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [target, setTarget] = useState<number>(0);
  const [integrations, setIntegrations] = useState<
    IntegrationConfigDescriptor[]
  >([]);

  const closeModal = () => {
    setIsOpen(false);
    setIntegrations([]);
  };

  const openModal = () => {
    setIsOpen(true);
    setLoading(true);
    toast
      .promise(apiInstance.get("/Integrations/", { params }), {
        loading: "Loading integrations list...",
        success: "Integrations loaded",
        error: (err) => parseAxiosErrors(err),
      })
      .then((data) => setIntegrations(data.data))
      .finally(() => setLoading(false));
  };

  const handleInitiateTransfer = () => {
    toast
      .promise(
        apiInstance.post(
          "/Migration/Transfer",
          {
            SourceIntegrationConfigId: id,
            TargetIntegrationConfigId: target,
          },
          { params }
        ),
        {
          loading: "Requesting transfer...",
          success: "Transfer complete",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => {
        refresh();
        closeModal();
      });
  };

  if (id) {
    return (
      <>
        <ButtonRound
          icon={
            <ArrowUpOnSquareStackIcon
              className={BUTTONS_ROUND.icon}
              aria-hidden="true"
            />
          }
          label={`transfer settings from ${id}`}
          design="secondary"
          func={openModal}
        />
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            open={isOpen}
            onClose={closeModal}
          >
            <DialogOverlay />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Transfer Settings from Config to Config
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Here is what it transfers and the rules:
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        All custom cart items from source that don't have a
                        matching sku in target (any item in target, not just
                        custom items)
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        The activation status of all items from source that
                        having a matching sku in target
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        All sku maps in source that don't have a matching cart
                        sku in target
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        All ship method maps in source that don't have a
                        matching cart ship method in target
                      </p>
                    </div>
                    <div className="text-lg font-medium leading-6 text-gray-900 pt-2">
                      Choose Target Config:
                    </div>
                    <div className="w-full px-4 py-4">
                      {loading ? (
                        <LoadingInline message="Loading..." />
                      ) : (
                        <SettingTransferRadioOptions
                          id={id}
                          integrations={integrations}
                          target={target}
                          setTarget={setTarget}
                        />
                      )}
                    </div>
                    <ButtonGroup>
                      <Button
                        design="secondary"
                        label="Cancel"
                        func={closeModal}
                      />
                      <Button
                        design="primary"
                        label="Initiate Transfer"
                        disabled={loading || target === 0}
                        func={handleInitiateTransfer}
                      />
                    </ButtonGroup>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  } else {
    return <></>;
  }
}
