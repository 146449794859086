import { useParams } from "react-router";
import { TruckIcon } from "@heroicons/react/24/outline";

import { ShipMethodMapDescriptor } from "../../../services/webservice/models/ShipMethodMapDescriptor";
import useGetOne from "../../../services/useGetOne";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import ShipMapForm from "./ShipMapForm";
import ErrorAlert from "../../../common/ErrorAlert";
import LoadingCard from "../../../common/LoadingCard";

export default function EditShipMapPage() {
  const { shipMethodMapId, integrationConfigId } = useParams();
  const { loading, error, data } = useGetOne<ShipMethodMapDescriptor>(
    `/integrations/${integrationConfigId}/shipmethodmaps/${shipMethodMapId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Ship Map"
          icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Edit Ship Map"
        icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <ShipMapForm
            shipMap={data}
            integrationConfigId={Number(integrationConfigId)}
          />
        )}
      </PageBody>
    </div>
  );
}
