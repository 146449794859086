import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import { ShippingProfileForm } from "./ShippingProfileForm";

import useGetOne from "src/services/useGetOne";
import { ShippingProfileDescriptor } from "./types";

export function EditShippingProfilePage() {
  const { shippingProfileId } = useParams();
  const { data, loading, error } = useGetOne<ShippingProfileDescriptor>(
    `/ShippingProfiles/${shippingProfileId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Shipping Profile"
          icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Edit Shipping Profile"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <ShippingProfileForm existingProfile={data} />
        )}
      </PageBody>
    </div>
  );
}
