import { LockClosedIcon } from "@heroicons/react/24/outline";

import ButtonRound from "src/common/buttons/ButtonRound";

import { BUTTONS_ROUND } from "src/utils/styles";

interface IntegrationConfigReAuthButtonProps {
  config: any;
}

export function IntegrationConfigReAuthButton({
  config,
}: IntegrationConfigReAuthButtonProps) {
  const shop = config.cartUserConfig.storeUrl;
  const authUrl = `https://${shop}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT_ID}&scope=${process.env.REACT_APP_SHOPIFY_SCOPES},write_shipping&redirect_uri=${process.env.REACT_APP_SHOPIFY_REAUTH_REDIRECT_URL}`;
  console.log(authUrl);
  if (!shop) return null;

  return (
    <div>
      <ButtonRound
        icon={
          <LockClosedIcon className={BUTTONS_ROUND.icon} aria-hidden="true" />
        }
        label={`re-auth ${shop}`}
        design="secondary"
        func={() => window.open(authUrl, "_blank")}
      />
    </div>
  );
}
