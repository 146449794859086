import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { CartDescriptor } from "src/services/webservice";
import { getUser } from "src/services/auth.service";

interface IAddIntegrationLineItemProps {
  integration: CartDescriptor;
}

export default function AddIntegrationLineItem({
  integration,
}: IAddIntegrationLineItemProps) {
  const user = getUser();
  switch (integration.identifier) {
    case "CustomApi":
    case "TestCart":
    case "ShopifyV2":
      if (user?.role === "Super") {
        return (
          <li key={integration.identifier}>
            <Link
              to={integration.identifier || ""}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-600 truncate">
                        {integration.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span>{integration.description}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        );
      } else {
        return null;
      }

    default:
      return (
        <li key={integration.identifier}>
          <Link
            to={integration.identifier || ""}
            className="block hover:bg-gray-50"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-600 truncate">
                      {integration.name}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <span>{integration.description}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
        </li>
      );
  }
}
