import {Link} from "react-router-dom";

interface TextLinkProps {
  link: string;
  text: string;
}

export default function TextLink({link, text}: TextLinkProps) {
  return (
    <Link to={link} className="font-medium text-gray-600 hover:text-gray-500 hover:underline">
      {text}
    </Link>
  );
}