import { useState, useEffect } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";

import FormError from "src/common/FormError";
import ShipMapDeleteButton from "./ShipMapDeleteButton";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import {
  AvailableWmsShipMethods,
  WmsShipMethodType,
} from "../AvailableWmsShipMethods";
import { AvailableWmsShippingProfiles } from "./AvailableWmsShippingProfiles";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import {
  parseAccountParams,
  holdOrderValues,
  parseAxiosErrors,
} from "src/utils/Parse";
import * as AuthService from "src/services/auth.service";
import { ShipMethodMapDescriptor } from "src/services/webservice";

interface IFormInputs {
  accountId: number;
  shipMethodMapId: number;
  integrationConfigId: number;
  cartShipMethod: string;
  warehouseShipMethod: string;
  signatureRequired: boolean;
  holdOrder: boolean;
  holdOrderHours: number;
  shippingProfileId: number;
}

interface IShipMapFormProps {
  shipMap?: ShipMethodMapDescriptor;
  integrationConfigId: number;
}

export default function ShipMapForm({
  shipMap,
  integrationConfigId,
}: IShipMapFormProps) {
  const navigate = useNavigate();
  const { wmsConnectionId } = useParams();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const user = AuthService.getUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [isTpbMethod, setIsTpbMethod] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<string>();
  const methods = useForm<IFormInputs>({
    defaultValues: {
      accountId: account.accountId > 0 ? account.accountId : user?.accountId,
      shipMethodMapId: shipMap?.shipMethodMapId || 0,
      integrationConfigId: Number(integrationConfigId) || 0,
      cartShipMethod: shipMap?.cartShipMethod,
      warehouseShipMethod: shipMap?.warehouseShipMethod || "choose",
      signatureRequired: shipMap?.signatureRequired,
      holdOrder: shipMap?.holdOrder,
      holdOrderHours: shipMap?.holdOrderHours || 0,
      shippingProfileId: shipMap?.shippingProfileId || 0,
    },
  });
  const holdOrderChecked = methods.watch("holdOrder");
  const holdOrderHoursValue = methods.watch("holdOrderHours");
  const warehouseShipMethodValue = methods.watch("warehouseShipMethod");
  const shippingProfileIdValue = methods.watch("shippingProfileId");
  const [selected, setSelected] = useState(
    holdOrderValues[
      holdOrderValues.findIndex((x) => x.hours === holdOrderHoursValue)
    ]
  );

  // show shipping profiles if method starts with TPB;
  useEffect(() => {
    console.log("warehouseShipMethodValue", warehouseShipMethodValue);
    if (warehouseShipMethodValue.startsWith("TPB;")) {
      setIsTpbMethod(true);
    } else {
      setIsTpbMethod(false);
    }
  }, [warehouseShipMethodValue]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setLoading(true);
    let res;
    let loadingMessage = "Updating...";
    let successMessage = "Ship mapping updated";
    if (shipMap) {
      res = apiInstance.put(
        `/integrations/${integrationConfigId}/shipmethodmaps/${shipMap.shipMethodMapId}`,
        data,
        { params }
      );
    } else {
      res = apiInstance.post(
        `/integrations/${integrationConfigId}/shipmethodmaps`,
        data,
        { params }
      );
      loadingMessage = "Creating...";
      successMessage = "Ship mapping created";
    }
    toast
      .promise(res, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        methods.reset();
        navigate(
          `/integrations/${integrationConfigId}/${wmsConnectionId}/shipmaps`
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSetWmsMethod = (method: WmsShipMethodType) => {
    console.log("handleWms", method);
    setCarrier(method.carrier);
    methods.setValue("warehouseShipMethod", method.code);
  };

  const handleSetShippingProfile = (profileId: number) => {
    console.log("handleProfile", profileId);
    console.log(profileId);
    methods.setValue("shippingProfileId", profileId);
  };

  const isHoldValid = () => {
    return (holdOrderChecked && holdOrderHoursValue >= 6) || !holdOrderChecked;
  };
  useEffect(() => {
    console.log("errors", methods.formState.errors);
  }, [methods.formState.errors]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <input type="hidden" {...methods.register("accountId")} />
        <input type="hidden" {...methods.register("shipMethodMapId")} />
        <input type="hidden" {...methods.register("holdOrderHours")} />
        <input type="hidden" {...methods.register("warehouseShipMethod")} />
        <input type="hidden" {...methods.register("shippingProfileId")} />
        <div>
          <div>
            <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
              Incoming Text and Warehouse Code
            </h3>
            <p className="mt-1 max-w-2xl text-md md:text-sm text-gray-500">
              You must exactly match the incoming text from your cart and assign
              a warehouse shipping code.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cartShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Cart Method
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...methods.register("cartShipMethod", { required: true })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      methods.formState.errors.cartShipMethod
                        ? "focus:ring-red-300 focus:border-red-300 border-red-300 placeholder-red-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="FREE SHIPPING"
                  />
                </div>
                {methods.formState.errors.cartShipMethod && (
                  <FormError message="Cart Method is Required" />
                )}
              </div>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="warehouseShipMethod"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  WMS Shipping Code
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <AvailableWmsShipMethods
                    wmsConnectionId={wmsConnectionId || ""}
                    setMethod={handleSetWmsMethod}
                    selectedShipMethod={shipMap?.warehouseShipMethod}
                  />
                  {methods.formState.errors.warehouseShipMethod && (
                    <FormError message="WMS Carrier Code is Required" />
                  )}
                  {(isTpbMethod || shipMap?.shippingProfileId) && (
                    <div className="mt-3 text-sm">
                      <AvailableWmsShippingProfiles
                        wmsConnectionId={wmsConnectionId}
                        carrier={carrier}
                        setProfile={handleSetShippingProfile}
                        selectedProfile={shipMap?.shippingProfileId}
                      />
                    </div>
                  )}
                  {isTpbMethod && !shippingProfileIdValue && (
                    <FormError message="You must choose a profile with TPB methods" />
                  )}
                </div>
              </div>
            </div>

            <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
                  Optional Settings
                </h3>
              </div>
              <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-email">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div></div>
                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg space-y-4">
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="signatureRequired"
                                {...methods.register("signatureRequired")}
                                type="checkbox"
                                className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="signatureRequired"
                                className="font-medium text-gray-700"
                              >
                                Signature Required
                              </label>
                              <p className="text-gray-500">
                                Request signature required service, if
                                available.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="holdOrder"
                                  {...methods.register("holdOrder")}
                                  type="checkbox"
                                  className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="holdOrder"
                                  className="font-medium text-gray-700"
                                >
                                  Hold Orders
                                </label>
                                <p className="text-gray-500">
                                  Hold incoming orders for fraud screening.
                                </p>
                              </div>
                            </div>
                            {holdOrderChecked && (
                              <div className="sm:col-span-2 mt-4">
                                <div className="max-w-lg">
                                  <RadioGroup
                                    value={selected}
                                    onChange={(selection) => {
                                      setSelected(selection);
                                      methods.setValue(
                                        "holdOrderHours",
                                        selection.hours
                                      );
                                    }}
                                  >
                                    <RadioGroup.Label className="text-sm text-gray-500">
                                      Please select a hold time. Orders will not
                                      be released until the next processing
                                      batch after the hold time has expired.
                                    </RadioGroup.Label>
                                    <div className="space-y-4 mt-2">
                                      {holdOrderValues.map((value) => (
                                        <RadioGroup.Option
                                          key={value.name}
                                          value={value}
                                          className={({ active, checked }) =>
                                            `${
                                              active
                                                ? "ring-2 ring-gray-500 ring-opacity-60"
                                                : ""
                                            }
                  ${
                    checked
                      ? "bg-gray-900 bg-opacity-75 text-gray-400"
                      : "bg-white"
                  }
                    relative rounded-lg p-2 cursor-pointer flex focus:outline-none bg-gray-200`
                                          }
                                        >
                                          {({ active, checked }) => (
                                            <>
                                              <div className="flex items-center justify-between w-full">
                                                <div className="flex items-center">
                                                  <div className="text-sm">
                                                    <RadioGroup.Label
                                                      as="p"
                                                      className={`font-medium  ${
                                                        checked
                                                          ? "text-white"
                                                          : "text-gray-900"
                                                      }`}
                                                    >
                                                      {value.name}
                                                    </RadioGroup.Label>
                                                  </div>
                                                </div>
                                                {checked && (
                                                  <div className="flex-shrink-0 text-white">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="h-6 w-6"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                      />
                                                    </svg>
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </RadioGroup.Option>
                                      ))}
                                    </div>
                                  </RadioGroup>
                                </div>
                                {!isHoldValid() && (
                                  <FormError message="You must choose a hold time" />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <ButtonGroup>
              <ShipMapDeleteButton
                id={shipMap?.shipMethodMapId}
                integrationConfigId={integrationConfigId}
                wmsConnectionId={wmsConnectionId}
              />
              <Button
                label="Cancel"
                func={() => navigate(-1)}
                design="secondary"
              />
              <Button
                label="Save"
                disabled={
                  !isHoldValid() ||
                  warehouseShipMethodValue === "choose" ||
                  (isTpbMethod && !shippingProfileIdValue)
                }
                type="submit"
                loading={loading}
              />
            </ButtonGroup>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
