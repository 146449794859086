import { useNavigate } from "react-router";

import { FolderPlusIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

import Button from "../common/buttons/Button";

interface EmptyListProps {
  title: string;
  description: string;
  buttonPath: string;
  buttonLabel: string;
  displayButton?: boolean;
}

export default function EmptyList({
  title,
  description,
  buttonPath,
  buttonLabel,
  displayButton = true,
}: EmptyListProps) {
  const navigate = useNavigate();
  return (
    <>
      <FolderPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      {displayButton && (
        <div className="mt-6">
          <Button
            label={buttonLabel}
            func={() => navigate(buttonPath)}
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        </div>
      )}
    </>
  );
}
