import { ArrowRightIcon } from "@heroicons/react/24/outline";

export type MappedOrderItemType = {
  orderId?: number;
  orderItemId?: number;
  sku?: string | null;
  quantity?: number;
  wmsId?: number;
  skuMapApplied?: boolean;
};

interface MappedItemProps {
  item: MappedOrderItemType;
}

export default function MappedItem({ item }: MappedItemProps) {
  if (item.skuMapApplied) {
    return (
      <dd className="ml-4 mt-1 text-sm text-gray-900 flex">
        <ArrowRightIcon className="w-4 mr-2 text-gray-500" />
        <span className="text-gray-500">{item.quantity} &times;</span>
        &nbsp;
        {item.sku}
      </dd>
    );
  } else {
    return null;
  }
}
