import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";

import { EyeIcon } from "@heroicons/react/24/outline";

import PendingOrderDetails from "./PendingOrderDetails";
import ButtonGroup from "../../../common/buttons/ButtonGroup";
import Button from "../../../common/buttons/Button";
import PendingOrderDeleteButton from "./PendingOrderDeleteButton";

import { Order } from "../../../services/webservice";
import DialogOverlay from "src/common/DialogOverlay";

interface PendingOrderDetailDialogProps {
  order: Order;
  refresh: () => void;
}

export default function PendingOrderDetailDialog({
  order,
  refresh,
}: PendingOrderDetailDialogProps) {
  const { integrationConfigId } = useParams();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function closeModal() {
    setDialogOpen(false);
  }

  const parseOrderId = () => {
    let apiCartOrderId =
      (order.metaData &&
        order.metaData.find((x) => x.key === "ApiCartOrderId")?.value) ||
      0;
    return Number(apiCartOrderId);
  };

  const closeAndRefresh = () => {
    closeModal();
    refresh();
  };

  return (
    <>
      <Button
        label="Details"
        srOnly={`, order ${order.orderNumber}`}
        func={() => setDialogOpen(true)}
        design="secondary"
        icon={<EyeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      />
      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={dialogOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Order Details
                  </Dialog.Title>
                  <PendingOrderDetails order={order} />
                  <ButtonGroup>
                    {parseOrderId() > 0 && (
                      <PendingOrderDeleteButton
                        id={parseOrderId()}
                        integrationConfigId={Number(integrationConfigId)}
                        closeAndRefresh={closeAndRefresh}
                      />
                    )}
                    <Button
                      label="Close"
                      func={closeModal}
                      design="secondary"
                    />
                  </ButtonGroup>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
