import { parseClassNames } from "../utils/Parse";

interface PageBodyProps {
  children: React.ReactNode;
  table?: boolean;
  overflow?: boolean;
}

export default function PageBody({ children, table, overflow }: PageBodyProps) {
  return (
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 z-10">
        <div className="px-0 py-2 sm:py-8">
          <div
            className={parseClassNames(
              table ? "" : "bg-white p-4 sm:rounded-md shadow",
              overflow ? "" : "overflow-hidden"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </main>
  );
}
