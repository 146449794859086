import LoadingCard from "../../../common/LoadingCard";
import FolderIcon from "../../../common/FolderIcon";

import { InventoryTransfer } from "../../../services/webservice";
import { parseClassNames } from "../../../utils/Parse";
import { TABLE_COLUMN_TEXT } from "../../../utils/styles";

interface WmsItemsContentProps {
  data: InventoryTransfer[];
  loading: boolean;
}

export function InventoryTransferContent({
  data,
  loading,
}: WmsItemsContentProps) {
  if (loading)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <LoadingCard />
          </div>
        </td>
      </tr>
    );

  if (data.length === 0)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <FolderIcon />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Items Found for Transfer
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please review your location settings in Shopify?
            </p>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {data.map((item) => (
        <tr key={item.cartId}>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              "max-w-xs break-normal"
            )}
          >
            <div>
              <div className="text-sm font-medium">
                <div className="flex space-x-2">
                  {item.sku === "" ? '""' : item.sku}
                </div>
              </div>
            </div>
          </td>
          <td className={TABLE_COLUMN_TEXT.base}>{item.cartId}</td>
          <td className={TABLE_COLUMN_TEXT.base}>{item.quantity}</td>{" "}
          <td className={TABLE_COLUMN_TEXT.base}>{item.sourceId}</td>
        </tr>
      ))}
    </>
  );
}
