import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

import { InboxArrowDownIcon, TrashIcon } from "@heroicons/react/24/outline";

import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import DialogOverlay from "src/common/DialogOverlay";
import ButtonRound from "src/common/buttons/ButtonRound";
import AccountSelector from "../accounts/AccountSelector";

import { apiInstance } from "src/services/api";
import { AccountDescriptor } from "src/services/webservice";
import { parseAxiosErrors } from "src/utils/Parse";

type SubscriptionType = {
  notificationSubscriptionId: number;
  accountId: number;
  accountName: string;
};

type SelectionType = {
  accountId: number;
  accountName: string;
};

interface AccountMonitoringDialogProps {
  userId: number;
}

export default function AccountMonitoringDialog({
  userId,
}: AccountMonitoringDialogProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reSubmitloading, setReSubmitLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SelectionType[]>([]);

  function closeModal() {
    setDialogOpen(false);
  }

  const handleGetSubscriptions = () => {
    toast
      .promise(
        apiInstance.get<SubscriptionType[]>(
          `/Users/${userId}/notificationSubscriptions`
        ),
        {
          loading: "Loading subscriptions...",
          success: "Subscriptions loaded",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then((res) => {
        let newData = res.data.map((sub: SelectionType) => {
          return { accountId: sub.accountId, accountName: sub.accountName };
        });
        setSubscriptions(newData);
        setDialogOpen(true);
      });
  };

  const handleUpdateSubscriptions = () => {
    setReSubmitLoading(true);
    let newSubscriptions: number[] = subscriptions.map((sub) => sub.accountId);
    toast
      .promise(
        apiInstance.put(
          `/Users/${userId}/notificationSubscriptions`,
          newSubscriptions
        ),
        {
          loading: "Updating subscriptions...",
          success: "Subscriptions updated",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then((res) => {
        setDialogOpen(false);
      })
      .finally(() => setReSubmitLoading(false));
  };

  const removeAccount = (accountId: number) => {
    const newSubscriptions = subscriptions.filter(
      (account) => account.accountId !== accountId
    );
    setSubscriptions(newSubscriptions);
  };

  const clearAllAccounts = () => {
    setSubscriptions([]);
  };

  const addAccount = (account: AccountDescriptor) => {
    let isNew = true;
    let newSubs = subscriptions.map((sub) => {
      if (sub.accountId === account.accountId) {
        isNew = false;
        toast.error("already in the list");
      }
      return sub;
    });
    if (isNew)
      newSubs.push({
        accountId: account.accountId,
        accountName: account.accountName,
      });
    setSubscriptions(newSubs);
  };

  return (
    <>
      <Button
        label="Error Monitoring"
        func={handleGetSubscriptions}
        design="secondary"
        custom="mt-4"
        icon={
          <InboxArrowDownIcon
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
        }
      />
      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={dialogOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Manage Subscribed Accounts
                  </Dialog.Title>
                  <Dialog.Description className="mb-4">
                    Add or remove accounts. You will receive error notification
                    emails for all integrations within subscribed accounts.
                  </Dialog.Description>
                  <AccountSelector label="Add Account" func={addAccount} />
                  <div className="mt-8">
                    <div className="flex space-x-2">
                      <span className="text-lg font-medium text-gray-900">
                        Currently Subscribed
                      </span>
                      <Button
                        label="clear all"
                        design="text"
                        func={clearAllAccounts}
                      />
                      <Button
                        label="reload"
                        design="text"
                        func={handleGetSubscriptions}
                      />
                    </div>
                    {subscriptions?.length === 0 ? (
                      <div>none</div>
                    ) : (
                      <ul>
                        {subscriptions?.map((sub) => (
                          <li key={sub.accountId}>
                            {`${sub.accountName} (id: ${sub.accountId})`}
                            <ButtonRound
                              func={() => removeAccount(sub.accountId)}
                              icon={
                                <TrashIcon
                                  className="h-3 w-3"
                                  aria-hidden="true"
                                />
                              }
                              label={`remove account ${sub.accountName}`}
                              design="tertiary"
                              custom="ml-2 mt-2"
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <ButtonGroup>
                    <Button
                      label="Save"
                      design="primary"
                      func={handleUpdateSubscriptions}
                      disabled={reSubmitloading}
                    />
                    <Button
                      label="Close"
                      func={closeModal}
                      design="secondary"
                    />
                  </ButtonGroup>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
