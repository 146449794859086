import { getTracking } from "ts-tracking-number";

import { BUTTONS } from "src/utils/styles";

interface FulfillmentTrackingProps {
  tracking: string;
}

export default function FulfillmentTracking({
  tracking,
}: FulfillmentTrackingProps) {
  const url = getTracking(tracking);
  if (!url) return <span>{tracking}</span>;

  return (
    <a
      href={url.trackingUrl?.replace("%s", url.trackingNumber) || "#"}
      target="_blank"
      rel="noreferrer"
      className={BUTTONS.text}
    >
      {tracking}
    </a>
  );
}
