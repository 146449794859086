import { useState } from "react";
import ReactDiffViewer from "react-diff-viewer";

import { AuditLog } from "src/services/webservice";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import PortalDialog from "src/common/PortalDialog";

interface AuditLogDetailDialogProps {
  audit: AuditLog;
}

export default function AuditLogDetailDialog({
  audit,
}: AuditLogDetailDialogProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function closeModal() {
    setDialogOpen(false);
  }

  const handleUserDetails = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Button
        label="View Details"
        func={handleUserDetails}
        design="secondary"
      />
      <PortalDialog
        title="Audit Details"
        isOpen={dialogOpen}
        closeModal={closeModal}
      >
        <div className="mt-2">
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Action</dt>
            <dd className="mt-1 text-sm text-gray-900 flex items-center space-x-2">
              <span>{audit.resource}</span>
              <ArrowRightCircleIcon className="w-4 h-4" />
              <span>{audit.type}</span>
            </dd>
          </div>
          <div className="mt-4">
            <ReactDiffViewer
              oldValue={audit.dataBefore || ""}
              newValue={audit.dataAfter || ""}
              splitView={true}
            />
          </div>
        </div>
        <ButtonGroup>
          <Button label="Done" func={closeModal} />
        </ButtonGroup>
      </PortalDialog>
    </>
  );
}
