import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import Pagination from "../../../common/Pagination";
import EventLogStatusBadge from "./EventLogStatusBadge";
import LoadingCard from "../../../common/LoadingCard";
import ErrorAlert from "../../../common/ErrorAlert";
import EventDetailDialog from "./EventDetailDialog";

import useGetList, { SortType } from "../../../services/useGetList";
import { EventLog } from "../../../services/webservice";
import {
  TABLE_COLUMN_HEADER_TEXT,
  TABLE_COLUMN_SORT,
  TABLE_COLUMN_TEXT,
} from "../../../utils/styles";
import { parseDate, parseClassNames } from "../../../utils/Parse";
import {
  EventStatusType,
  RadioStatusOptionType,
  RadioTypeOptionType,
  EventTypeType,
} from "./types";

const eventStatuses: RadioStatusOptionType[] = [
  { id: "status-all", title: "All", filter: "" },
  { id: "status-error", title: "Error", filter: "Error" },
  { id: "status-info", title: "Info", filter: "Info" },
  { id: "status-warning", title: "Warning", filter: "Warning" },
];

const eventTypes: RadioTypeOptionType[] = [
  { id: "type-all", title: "All", filter: "" },
  { id: "type-processorders", title: "ProcessOrders", filter: "ProcessOrders" },
  { id: "type-inventorysync", title: "InventorySync", filter: "InventorySync" },
  {
    id: "type-updatetracking",
    title: "UpdateTracking",
    filter: "UpdateTracking",
  },
  {
    id: "type-refreshcartitems",
    title: "RefreshCartItems",
    filter: "RefreshCartItems",
  },
];

export default function EventLogsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  let pageParam = searchParams.get("page");
  const { integrationConfigId } = useParams();
  const [statusParam, setStatusParam] = useState<EventStatusType>("");
  const [typeParam, setTypeParam] = useState<EventTypeType>("");
  const [filter, setFilter] = useState<string>(
    `&IntegrationConfigId=${integrationConfigId}`
  );
  const [sort, setSort] = useState<SortType[]>([
    { field: "startTime", direction: "desc" },
  ]);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [refreshKey, setRefreshKey] = useState<number>(1);
  const { data, error, loading, totalRecords, totalPages } =
    useGetList<EventLog>(
      "/EventLogs",
      page,
      pageSize,
      refreshKey,
      sort,
      filter
    );

  useEffect(() => {
    let newFilter = `&IntegrationConfigId=${integrationConfigId}`;
    newFilter += statusParam ? `&EventLogMaxLevel=${statusParam}` : "";
    newFilter += typeParam ? `&EventLogType=${typeParam}` : "";
    setFilter(newFilter);
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusParam, typeParam]);

  useEffect(() => {
    if (pageParam && !isNaN(Number(pageParam))) {
      handlePageChange(Number(pageParam));
    } else {
      setSearchParams({ page: page.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParam]);

  const refreshData = () => setRefreshKey(refreshKey + 1);

  const handlePageChange = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSort = (field: string) => {
    let newSort: SortType = { field, direction: "asc" };
    if (sort[0].field === newSort.field) {
      newSort.direction = sort[0].direction === "asc" ? "desc" : "asc";
    }
    setSort([newSort]);
  };

  const handleStatusFilter = (status: EventStatusType) => {
    setStatusParam(status);
  };

  const handleTypeFilter = (type: EventTypeType) => {
    setTypeParam(type);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Event Log"
          icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Event Logs"
        icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
        refresh={refreshData}
        description="Status of scheduled events and user interactions."
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-2">
        <div className="w-full rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                  <span>Filter Events</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-primary-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 flex space-x-10">
                  <div>
                    <p className="text-sm leading-5 text-gray-500">By Level</p>
                    <fieldset>
                      <legend className="sr-only">Event Status</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                        {eventStatuses.map((status) => (
                          <div key={status.id} className="flex items-center">
                            <input
                              id={status.id}
                              name="event-status"
                              type="radio"
                              defaultChecked={status.filter === statusParam}
                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                              onClick={() => handleStatusFilter(status.filter)}
                            />
                            <label
                              htmlFor={status.id}
                              className="ml-2 block text-sm font-medium text-gray-700"
                            >
                              {status.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <p className="text-sm leading-5 text-gray-500">By Type</p>
                    <fieldset>
                      <legend className="sr-only">Event Type</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        {eventTypes.map((type) => (
                          <div key={type.id} className="flex items-center">
                            <input
                              id={type.id}
                              name="event-type"
                              type="radio"
                              defaultChecked={type.filter === typeParam}
                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                              onClick={() => handleTypeFilter(type.filter)}
                            />
                            <label
                              htmlFor={type.id}
                              className="ml-2 block text-sm font-medium text-gray-700"
                            >
                              {type.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <PageBody table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className={parseClassNames(
                          TABLE_COLUMN_HEADER_TEXT.base,
                          "min-w-[7rem]"
                        )}
                      >
                        Event Id
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Level
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <button
                          onClick={() => handleSort("startTime")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Date
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "startTime"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "startTime" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <button
                          onClick={() => handleSort("eventLogType")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Type
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "eventLogType"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "eventLogType" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <LoadingCard />
                        </td>
                      </tr>
                    ) : (
                      data.map((event, index) => (
                        <tr key={index}>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            {event.eventLogId}
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            <EventLogStatusBadge
                              text={event.eventLogMaxLevel || ""}
                              inProgress={Boolean(
                                event.eventLogStatus === "InProgress"
                              )}
                            />
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            <time dateTime={parseDate(event.startTime)}>
                              {parseDate(event.startTime)}
                            </time>
                          </td>
                          <td className={TABLE_COLUMN_TEXT.base}>
                            <span className="font-medium text-grey-900">
                              {event.eventLogType}
                            </span>
                          </td>
                          <td
                            className={parseClassNames(
                              TABLE_COLUMN_TEXT.base,
                              "text-right"
                            )}
                          >
                            <EventDetailDialog
                              id={event.eventLogId}
                              integrationConfigId={integrationConfigId}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  setPage={handlePageChange}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
