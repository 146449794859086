import UserCheckbox from "./UserCheckbox";

type optionsType = {
  name: string;
  value: number;
}

interface UserCheckboxFormProps {
  perms: number;
  title: string;
  setOptions: (perms: number) => void;
  options: optionsType[]
}

export default function UserCheckboxForm({perms, title, setOptions, options}: UserCheckboxFormProps) {
  return (
    <div className="grow">
      <fieldset>
        <legend className="text-lg font-medium text-gray-900">{title}</legend>
        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
          {options.map(map => (
            <UserCheckbox key={map.value} name={map.name} isChecked={Boolean(perms & map.value)} value={map.value}
                          setValue={setOptions}/>
          ))}
        </div>
      </fieldset>
    </div>

  );
}