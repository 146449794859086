import { useNavigate, useParams } from "react-router";

import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import ErrorAlert from "../../../common/ErrorAlert";
import LoadingCard from "../../../common/LoadingCard";

import EventDetails from "./EventDetails";

import { EventLog } from "../../../services/webservice";
import useGetOne from "../../../services/useGetOne";

export default function EventLogsPage() {
  const { integrationConfigId, eventLogId } = useParams();
  const navigate = useNavigate();
  const { data, error, loading } = useGetOne<EventLog>(
    `/EventLogs/${eventLogId}?integrationConfigId=${integrationConfigId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Log Entry"
          icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
          description="Activities for scheduled events and user interactions."
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Log Entry"
        icon={<ClipboardDocumentListIcon className="w-8 md:w-10 ml-2" />}
        description="Activities for scheduled events and user interactions."
      />
      <PageBody>
        {!data && (
          <div>
            {loading ? (
              <LoadingCard />
            ) : (
              <li>
                <div className="relative pb-8">No event found.</div>
              </li>
            )}
          </div>
        )}
        {data && (
          <EventDetails
            event={data}
            buttonFunc={() =>
              navigate(`/integrations/${integrationConfigId}/eventlogs`)
            }
          />
        )}
      </PageBody>
    </div>
  );
}
