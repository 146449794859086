import { useParams } from "react-router";
import { TruckIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import GlobalShipMapForm from "./GlobalShipMapForm";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";

import { GlobalShipMethodMapDescriptor } from "../../services/webservice";
import useGetOne from "../../services/useGetOne";

export default function EditShipMapPage() {
  const { globalShipMapMethodId } = useParams();
  const { loading, error, data } = useGetOne<GlobalShipMethodMapDescriptor>(
    `/GlobalShipMethodMaps/${globalShipMapMethodId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Ship Map"
          icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Edit Ship Map"
        icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? <LoadingCard /> : <GlobalShipMapForm shipMap={data} />}
      </PageBody>
    </div>
  );
}
