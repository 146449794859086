import { Fragment, useEffect, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";

import ButtonGroup from "../../common/buttons/ButtonGroup";
import Button from "../../common/buttons/Button";
import DialogOverlay from "src/common/DialogOverlay";

import {
  AvailableWmsShipMethods,
  WmsShipMethodType,
} from "../integrations/AvailableWmsShipMethods";
import FormError from "../../common/FormError";
import { holdOrderValues } from "../../utils/Parse";
import { GlobalShipMethodMapItem } from "../../services/webservice";

interface IFormInputs {
  wmsConnectionId: number;
  wmsShipMethod: string;
  wmsConnectionDescription: string;
  signatureRequired: boolean;
  holdOrder: boolean;
  holdOrderHours: number;
}

interface AddWmsShipMethodDialogProps {
  wmsConnectionId: number;
  item?: GlobalShipMethodMapItem;
  addItem: (item: GlobalShipMethodMapItem) => void;
}

export default function AddWmsShipMethodDialog({
  wmsConnectionId,
  item,
  addItem,
}: AddWmsShipMethodDialogProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dialogMethods = useForm<IFormInputs>({
    defaultValues: {
      wmsConnectionId: wmsConnectionId || item?.wmsConnectionId || 0,
      wmsShipMethod: item?.wmsShipMethod || "choose",
      wmsConnectionDescription: item?.wmsConnectionDescription || "",
      signatureRequired: item?.signatureRequired,
      holdOrder: item?.holdOrder,
      holdOrderHours: item?.holdOrderHours || 0,
    },
  });
  const holdOrderChecked = dialogMethods.watch("holdOrder");
  const holdOrderHoursValue = dialogMethods.watch("holdOrderHours");
  const wmsShipMethodValue = dialogMethods.watch("wmsShipMethod");
  const wmsConnectionIdValue = dialogMethods.watch("wmsConnectionId");
  const [refreshKey, setRefreshKey] = useState<number>(1);
  const [selected, setSelected] = useState(
    holdOrderValues[
      holdOrderValues.findIndex((x) => x.hours === holdOrderHoursValue)
    ]
  );

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
    return () => {
      setRefreshKey(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsConnectionIdValue]);

  function closeModal() {
    dialogMethods.reset();
    setDialogOpen(false);
  }

  function openModal() {
    setDialogOpen(true);
  }

  const onSubmitDialog: SubmitHandler<IFormInputs> = (data) => {
    addItem(data);
    closeModal();
  };

  const handleSetWmsMethod = (method: WmsShipMethodType) => {
    dialogMethods.setValue("wmsShipMethod", method.code);
  };

  const isHoldValid = () => {
    return (holdOrderChecked && holdOrderHoursValue >= 6) || !holdOrderChecked;
  };

  return (
    <>
      <Button
        label={item ? "Edit" : "Configure"}
        design="secondary"
        func={openModal}
      />
      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={dialogOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Shipping Configuration
                  </Dialog.Title>
                  <Dialog.Description as="p" className="text-gray-500">
                    Set shipping method and options.
                  </Dialog.Description>
                  <FormProvider {...dialogMethods}>
                    <form>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="warehouseShipMethod"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            WMS Shipping Code
                          </label>
                          <div
                            className="mt-1 sm:mt-0 sm:col-span-2"
                            key={refreshKey}
                          >
                            {wmsConnectionIdValue > 0 && (
                              <AvailableWmsShipMethods
                                wmsConnectionId={
                                  `${wmsConnectionIdValue}` || ""
                                }
                                setMethod={handleSetWmsMethod}
                                selectedShipMethod={item?.wmsShipMethod}
                              />
                            )}
                            {dialogMethods.formState.errors.wmsShipMethod && (
                              <FormError message="WMS Carrier Code is Required" />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                          <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
                            Optional Settings
                          </h3>
                        </div>
                        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                          <div className="pt-6 sm:pt-5">
                            <div role="group" aria-labelledby="label-email">
                              <div>
                                <div className="mt-4 sm:mt-0 sm:col-span-2">
                                  <div className="max-w-lg space-y-4">
                                    <div className="relative flex items-start">
                                      <div className="flex items-center h-5">
                                        <input
                                          id="signatureRequired"
                                          {...dialogMethods.register(
                                            "signatureRequired"
                                          )}
                                          type="checkbox"
                                          className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="signatureRequired"
                                          className="font-medium text-gray-700"
                                        >
                                          Signature Required
                                        </label>
                                        <p className="text-gray-500">
                                          Request signature required service, if
                                          available.
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="relative flex items-start">
                                        <div className="flex items-center h-5">
                                          <input
                                            id="holdOrder"
                                            {...dialogMethods.register(
                                              "holdOrder"
                                            )}
                                            type="checkbox"
                                            className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="holdOrder"
                                            className="font-medium text-gray-700"
                                          >
                                            Hold Orders
                                          </label>
                                          <p className="text-gray-500">
                                            Hold incoming orders for fraud
                                            screening.
                                          </p>
                                        </div>
                                      </div>
                                      {holdOrderChecked && (
                                        <div className="sm:col-span-2 mt-4">
                                          <div className="max-w-lg">
                                            <RadioGroup
                                              value={selected}
                                              onChange={(selection) => {
                                                setSelected(selection);
                                                dialogMethods.setValue(
                                                  "holdOrderHours",
                                                  selection.hours
                                                );
                                              }}
                                            >
                                              <RadioGroup.Label className="text-sm text-gray-500">
                                                Please select a hold time.
                                                Orders will not be released
                                                until the next processing batch
                                                after the hold time has expired.
                                              </RadioGroup.Label>
                                              <div className="space-y-4 mt-2">
                                                {holdOrderValues.map(
                                                  (value) => (
                                                    <RadioGroup.Option
                                                      key={value.name}
                                                      value={value}
                                                      className={({
                                                        active,
                                                        checked,
                                                      }) =>
                                                        `${
                                                          active
                                                            ? "ring-2 ring-gray-500 ring-opacity-60"
                                                            : ""
                                                        }
                  ${
                    checked
                      ? "bg-gray-900 bg-opacity-75 text-gray-400"
                      : "bg-white"
                  }
                    relative rounded-lg p-2 cursor-pointer flex focus:outline-none bg-gray-200`
                                                      }
                                                    >
                                                      {({
                                                        active,
                                                        checked,
                                                      }) => (
                                                        <>
                                                          <div className="flex items-center justify-between w-full">
                                                            <div className="flex items-center">
                                                              <div className="text-sm">
                                                                <RadioGroup.Label
                                                                  as="p"
                                                                  className={`font-medium  ${
                                                                    checked
                                                                      ? "text-white"
                                                                      : "text-gray-900"
                                                                  }`}
                                                                >
                                                                  {value.name}
                                                                </RadioGroup.Label>
                                                              </div>
                                                            </div>
                                                            {checked && (
                                                              <div className="flex-shrink-0 text-white">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  className="h-6 w-6"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke="currentColor"
                                                                >
                                                                  <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={
                                                                      2
                                                                    }
                                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </>
                                                      )}
                                                    </RadioGroup.Option>
                                                  )
                                                )}
                                              </div>
                                            </RadioGroup>
                                          </div>
                                          {!isHoldValid() && (
                                            <FormError message="You must choose a hold time" />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ButtonGroup>
                        <Button
                          label="Cancel"
                          func={closeModal}
                          design="secondary"
                        />
                        <Button
                          label="Save"
                          func={dialogMethods.handleSubmit(onSubmitDialog)}
                          disabled={
                            !isHoldValid() || wmsShipMethodValue === "choose"
                          }
                        />
                      </ButtonGroup>
                    </form>
                  </FormProvider>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
