import { Fragment, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import FormError from "src/common/FormError";
import TextInput from "src/common/forms/TextInput";

import { SettingEnumMeta } from "src/services/webservice";
import { parseClassNames } from "src/utils/Parse";
import { getUser } from "src/services/auth.service";

interface SettingsMetaEnumProps {
  label: string;
  name: string;
  options: SettingEnumMeta[];
  defaultOptionKey?: number;
  description?: string;
}

export default function SettingsMetaEnum({
  label,
  name,
  options,
  defaultOptionKey = 0,
  description,
}: SettingsMetaEnumProps) {
  const user = getUser();
  const [selected, setSelected] = useState(options[defaultOptionKey]);
  const { control } = useFormContext();

  if (!selected) {
    console.log("options", options);
    if (user?.role === "Super" || user?.role === "Staff") {
      return (
        <>
          <TextInput
            label={label}
            name={`${name}` as const}
            type="text"
            placeholder="enter your text"
            description={description}
            required={true}
          />
          <div className="rounded-md bg-yellow-50 p-4 mt-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Setting has been customized from the following list of
                  standard options:
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <ul className="list-disc space-y-1 pl-5">
                    {options.map((item) => (
                      <li key={item.id}>
                        ID: <span className="font-bold">{item.id}</span>, Name:{" "}
                        <span className="font-bold">{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <FormError message={`Could not parse default selection for ${label}`} />
      );
    }
  }

  if (user?.role === "Super" && name === "cartUserConfig.history") {
    return (
      <>
        <TextInput
          label={label}
          name={`${name}` as const}
          type="text"
          placeholder="enter your text"
          description={description}
          required={true}
        />
        <div className="rounded-md bg-yellow-50 p-4 mt-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                With great power comes great responsibility. You may customize
                outside these standard options:
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <ul className="list-disc space-y-1 pl-5">
                  {options.map((item) => (
                    <li key={item.id}>
                      ID: <span className="font-bold">{item.id}</span>, Name:{" "}
                      <span className="font-bold">{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <Controller
        control={control}
        defaultValue={selected.id}
        name={`${name}` as const}
        render={({ field: { onChange } }) => (
          <Listbox
            value={selected}
            onChange={(selection) => {
              onChange(selection.id);
              setSelected(selection);
            }}
          >
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                <span className="block truncate">{selected.name}</span>
                <span className="absolute top-0 right-0 flex items-center pr-2 pt-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option, optionIdx) => (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active }) =>
                        parseClassNames(
                          active ? "text-white bg-gray-400" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={parseClassNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={parseClassNames(
                                active ? "text-white" : "text-gray-600",
                                "absolute top-0 right-0 flex items-center pr-4 pt-2"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
              {description && (
                <p
                  className="mt-2 text-sm text-gray-500"
                  id="field-description"
                >
                  {description}
                </p>
              )}
            </div>
          </Listbox>
        )}
      />
    );
  }
}
