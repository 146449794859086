import axios from "axios";
import { DateTime } from "luxon";
import jwtDecode from "jwt-decode";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export type AuthType = {
  userId: number;
  accountId: number;
  role: string;
  token: string;
  sessionStart?: number;
};

export const setLocalUser = ({
  userId,
  accountId,
  role,
  token,
  sessionStart = DateTime.local().toSeconds(),
}: AuthType) => {
  localStorage.setItem(
    "efsIntUser",
    JSON.stringify({
      sessionStart,
      userId,
      accountId,
      role,
      token,
    })
  );
};

export const hasExpired = (expires: number) => {
  const now = DateTime.local();
  // session expiration setting
  const whenExpired = DateTime.fromSeconds(expires).plus({ hours: 5 });
  return whenExpired < now ? true : false;
};

export const login = (email: string, password: string) => {
  return axios
    .post(
      baseUrl + "/users/login",
      {
        Username: email,
        Password: password,
      },
      { withCredentials: true }
    )
    .then((response) => {
      let token = response.data;
      if (token) {
        let decodedUser: any = jwtDecode(token);
        const { UserId, AccountId, role } = decodedUser;
        setLocalUser({ userId: UserId, accountId: AccountId, role, token });
      }

      return response.data;
    });
};

export const passwordResetRequest = (email: string) => {
  return axios
    .post(baseUrl + "/users/password/reset/request", {
      Username: email,
    })
    .then((response) => {
      return response.data;
    });
};

export const passwordReset = (requestId: string, password: string) => {
  return axios
    .post(
      baseUrl + "/users/password/reset",
      {
        passwordResetRequestId: requestId,
        newPassword: password,
      },
      { withCredentials: true }
    )
    .then((response) => {
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("efsIntUser");
  localStorage.removeItem("efsIntAccount");
};

export const getUser = () => {
  const userStr = localStorage.getItem("efsIntUser");
  if (userStr) {
    const user = JSON.parse(userStr) as AuthType;
    return user.sessionStart && !hasExpired(user.sessionStart) ? user : null;
  }

  return null;
};
