import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";

import { apiInstance } from "../../services/api";
import * as AuthService from "../../services/auth.service";
import { useAccount } from "../../AccountContext";
import { parseAccountParams, parseAxiosErrors } from "../../utils/Parse";

import FormError from "../../common/FormError";
import UserDeleteButton from "./UserDeleteButton";
import Button from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";
import UserCheckboxForm from "./UserCheckboxForm";
import UserPermsCheckboxForm from "./UserPermsCheckboxForm";
import CheckAllButton from "./CheckAllButton";
import AccountMonitoringDialog from "./AccountMonitoringDialog";
import LoadingInline from "src/common/LoadingInline";

import { PermissionType } from "./UserPermsCheckboxForm";

const notificationsOptionsArray = [
  { name: "Success", value: 1 },
  { name: "Error", value: 2 },
  { name: "Detail", value: 4 },
  { name: "Warning", value: 8 },
];

export type UserType = {
  userId?: number;
  accountId?: number;
  lastLogin?: string;
  password?: string;
  name: string;
  email: string;
  role: string;
  notificationSettings: number;
  permissions: string[];
};

interface IUserFormProps {
  user?: UserType;
  reload?: () => void;
  refreshKey?: number;
}

export default function UserForm({ user, reload, refreshKey }: IUserFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const identity = AuthService.getUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPerms, setLoadingPerms] = useState<boolean>(false);
  const [permsKey] = useState<number>(1);
  const [notificationsKey, setNotificationsKey] = useState<number>(50);
  const [permissionsOptionsArray, setPermissionsOptionsArray] = useState<
    PermissionType[]
  >([]);
  const [localPermissionOptions, setLocalPermissionOptions] = useState<
    string[]
  >(user?.permissions || []);
  const [localNotificationOptions, setLocalNotificationOptions] =
    useState<number>(user?.notificationSettings || 0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<UserType>({
    defaultValues: {
      accountId: account.accountId || identity?.accountId,
      userId: user?.userId || 0,
      name: user?.name || "",
      email: user?.email || "",
      role: user?.role || "User",
      permissions: user?.permissions || [],
      notificationSettings: user?.notificationSettings || 0,
    },
  });

  const watchRole = watch("role");

  useEffect(() => {
    setLoadingPerms(true);
    toast
      .promise(apiInstance.get("/enums/permissions"), {
        loading: "Loading permissions",
        success: "Permissions loaded",
        error: (err) => parseAxiosErrors(err),
      })
      .then((res) => setPermissionsOptionsArray(res.data))
      .finally(() => setLoadingPerms(false));
  }, []);

  const onSubmit: SubmitHandler<UserType> = (data) => {
    setLoading(true);
    let response;
    let loadingMessage = "Updating...";
    let successMessage = "User updated";
    if (user) {
      response = apiInstance.put(`/Users/${user.userId}`, data, { params });
    } else {
      loadingMessage = "Creating...";
      successMessage = "User created";
      response = apiInstance.post("/Users", data, { params });
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        if (identity?.role === "User") {
          reload && reload();
        } else {
          navigate("/users");
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setValue("permissions", localPermissionOptions);
    console.log(localPermissionOptions);
  }, [localPermissionOptions, setValue]);

  useEffect(() => {
    setValue("notificationSettings", localNotificationOptions);
  }, [localNotificationOptions, setValue]);

  const setPermissions = (perm: string) => {
    let newPerms = [];
    if (localPermissionOptions.find((x) => x === perm)) {
      newPerms = localPermissionOptions.filter((newItem) => newItem !== perm);
      setLocalPermissionOptions(newPerms);
    } else {
      setLocalPermissionOptions([...localPermissionOptions, perm]);
    }
  };
  const setNotifications = (perm: number) => {
    if (localNotificationOptions & perm) {
      setLocalNotificationOptions(localNotificationOptions - perm);
    } else {
      setLocalNotificationOptions(localNotificationOptions + perm);
    }
  };

  const massCheckNotifications = (perm: number) => {
    setLocalNotificationOptions(perm);
    setNotificationsKey(notificationsKey + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register("notificationSettings")} />
      <input type="hidden" {...register("permissions")} />
      <div>
        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("name", { required: true })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      errors.name
                        ? "focus:ring-gray-300 focus:border-gray-300 border-gray-300 placeholder-gray-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="Frankie Sinatra"
                  />
                </div>
                {errors.name && <FormError message="Name is Required" />}
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("email", {
                      required: true,
                      pattern:
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
                    })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      errors.name
                        ? "focus:ring-gray-300 focus:border-gray-300 border-gray-300 placeholder-gray-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="bob@dog.com"
                  />
                </div>
                {errors.email && errors.email.type === "required" && (
                  <FormError message="Email address is required" />
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <FormError message="Invalid email format" />
                )}
              </div>
            </div>
          </div>

          {!user && (
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Password
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="password"
                      {...register("password", {
                        required: "You must enter a password",
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{10,30}$/g,
                          message:
                            "Password must be at least 10 characters, contain 1 capital letter, 1 number, and 1 of these symbols: #$^+=!*()@%&",
                        },
                      })}
                      className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                        errors.password
                          ? "focus:ring-gray-300 focus:border-gray-300 border-gray-300 placeholder-gray-400"
                          : "focus:ring-gray-500 focus:border-gray-500"
                      }`}
                      autoComplete="new-password"
                    />
                  </div>
                  {errors.password && (
                    <FormError message={errors.password.message} />
                  )}
                </div>
              </div>
            </div>
          )}

          {!user && (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Role
              </label>
              <fieldset className="mt-4">
                <legend className="sr-only">User Role</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  <div className="flex items-center">
                    <input
                      id="userRole"
                      {...register("role")}
                      type="radio"
                      value="User"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="userRole"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      User
                    </label>
                  </div>

                  {identity?.role !== "User" && identity?.role !== "Admin" && (
                    <div className="flex items-center">
                      <input
                        id="adminRole"
                        {...register("role")}
                        type="radio"
                        value="Admin"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor="adminRole"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Admin
                      </label>
                    </div>
                  )}

                  {identity?.role === "Super" && (
                    <>
                      <div className="flex items-center">
                        <input
                          id="staffRole"
                          {...register("role")}
                          type="radio"
                          value="Staff"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor="staffRole"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Staff
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="superAdminRole"
                          {...register("role")}
                          type="radio"
                          value="Super"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor="superAdminRole"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Super
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </fieldset>
            </div>
          )}

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-2 sm:gap-10 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              {identity?.role !== "User" && watchRole === "User" && (
                <div>
                  {loadingPerms ? (
                    <LoadingInline message="Loading available permissions" />
                  ) : (
                    <UserPermsCheckboxForm
                      key={permsKey}
                      perms={localPermissionOptions}
                      setOptions={setPermissions}
                      options={permissionsOptionsArray}
                    />
                  )}
                </div>
              )}

              <div className="mt-6 sm:mt-0">
                <UserCheckboxForm
                  key={notificationsKey}
                  title="Notifications"
                  perms={localNotificationOptions}
                  setOptions={setNotifications}
                  options={notificationsOptionsArray}
                />
                <div className="flex space-x-2">
                  <CheckAllButton
                    perms={localNotificationOptions}
                    label="Notifications"
                    maxValue={7}
                    massCheck={massCheckNotifications}
                  />
                  {identity?.role === "Super" &&
                    location.pathname.substring(0, 8) === "/profile" && (
                      <AccountMonitoringDialog userId={identity.userId} />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <ButtonGroup>
            {identity?.role !== "User" && (
              <>
                <UserDeleteButton id={user?.userId} />
                <Button label="Cancel" path="/users" design="secondary" />
              </>
            )}
            <Button label="Save" type="submit" loading={loading} />
          </ButtonGroup>
        </div>
      </div>
    </form>
  );
}
