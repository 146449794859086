import { ShoppingCartIcon } from "@heroicons/react/24/outline";

interface DisplayMappedCartItemProps {
  sku: string | null | undefined;
}

export default function DisplayMappedCartItem({
  sku,
}: DisplayMappedCartItemProps) {
  if (sku !== null) {
    return (
      <p className="flex items-center text-sm text-gray-500">
        <ShoppingCartIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
          aria-hidden="true"
        />
        <span className="text-sm font-medium text-gray-600 truncate">
          {sku === "" ? '"" (blank SKU)' : sku}
        </span>
      </p>
    );
  } else {
    return null;
  }
}
