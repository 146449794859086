import { useState } from "react";
import { useNavigate } from "react-router";
import {
  PencilIcon,
  GlobeAltIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

import WmsDeleteButton from "./WmsDeleteButton";
import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import Button from "../../common/buttons/Button";
import ButtonRound from "src/common/buttons/ButtonRound";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import EmptyList from "../../common/EmptyList";

import useGetWms, { Formats } from "../../services/wms/useGetWms";
import { WmsConnectionDescriptor } from "../../services/webservice";
import { apiInstance } from "../../services/api";
import { parseAxiosErrors, parseDate } from "../../utils/Parse";

export default function WmsConnectionsPage() {
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const { loading, data, error } = useGetWms<WmsConnectionDescriptor>({
    format: Formats.Full,
    reload: refreshKey,
  });
  const navigate = useNavigate();

  const editWms = (id: number) => {
    navigate(`/wms/edit/${id}`);
  };

  const refreshWmsList = () => {
    setRefreshKey(refreshKey + 1);
  };

  const handleWmsRefreshToggle = (
    wmsConnectionId: number,
    accountId: number
  ) => {
    toast
      .promise(
        apiInstance.post(`/wms/${wmsConnectionId}/refreshWmsItemsEnabled`, "", {
          params: { AccountId: accountId },
        }),
        {
          loading: "Toggling...",
          success: "Toggled",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => refreshWmsList());
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Ship Map"
          icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="WMS Connections"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        description="Each integration is paired with a WMS connection for orders, inventory, and tracking."
        actionComp={
          <Button
            label="Add"
            path="add"
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody>
        {data.length === 0 && (
          <div className="text-center py-10">
            {loading ? (
              <LoadingCard />
            ) : (
              <EmptyList
                title="No WMS Connections"
                description="Get started by connecting a WMS."
                buttonPath="add"
                buttonLabel="New WMS Connection"
              />
            )}
          </div>
        )}
        {data.length > 0 &&
          data.map((wms) => (
            <div
              key={wms.wmsConnectionId}
              className="mb-2 flex items-center py-2 space-x-2"
            >
              <div className="flex flex-col">
                <div>{wms.description}</div>
                <div className="text-xs text-gray-400 flex mr-6">
                  <span className="mr-2">Automatic WMS Item Refresh:</span>
                  {wms.isRefreshWmsItemsEnabled ? (
                    <Button
                      label="YES"
                      design="text"
                      func={() =>
                        handleWmsRefreshToggle(
                          wms.wmsConnectionId,
                          wms.accountId
                        )
                      }
                    />
                  ) : (
                    <Button
                      label="NO"
                      design="text"
                      func={() =>
                        handleWmsRefreshToggle(
                          wms.wmsConnectionId,
                          wms.accountId
                        )
                      }
                    />
                  )}
                </div>
                <div className="text-xs text-gray-400">
                  Last Refresh: {parseDate(wms.lastWmsRefresh)}
                </div>
              </div>
              <ButtonRound
                icon={<PencilIcon className="h-6 w-6" aria-hidden="true" />}
                func={() => editWms(wms.wmsConnectionId)}
                label={`Edit ${wms.description} WMS`}
                design="secondary"
              />
              <WmsDeleteButton
                id={wms.wmsConnectionId}
                name={wms.description}
                reload={refreshWmsList}
              />
            </div>
          ))}
      </PageBody>
    </div>
  );
}
