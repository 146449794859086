import { useState } from "react";
import { useNavigate } from "react-router";
import {
  PencilIcon,
  UserCircleIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";

import ShippingProfileDeleteButton from "./ShippingProfileDeleteButton";
import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import Button from "../../common/buttons/Button";
import ButtonRound from "src/common/buttons/ButtonRound";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import EmptyList from "../../common/EmptyList";
import { CarrierChip } from "./CarrierChip";

import useGetList from "src/services/useGetList";
import { ShippingProfileDescriptor } from "./types";

export function ShippingProfilesPage() {
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const { data, error, loading } = useGetList<ShippingProfileDescriptor>(
    "/shippingProfiles",
    1,
    100,
    refreshKey
  );

  const editWms = (id: number) => {
    navigate(`/shippingprofiles/edit/${id}`);
  };

  const refreshProfileList = () => {
    setRefreshKey(refreshKey + 1);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="TPB Shipping Profiles"
          icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="TPB Shipping Profiles"
        icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
        description="You can create a third party account profile to be assigned to shipping methods"
        actionComp={
          <Button
            label="Add"
            path="add"
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody>
        {data.length === 0 && (
          <div className="text-center py-10">
            {loading ? (
              <LoadingCard />
            ) : (
              <EmptyList
                title="No Shipping Profiles"
                description="Get started by creating a profile."
                buttonPath="add"
                buttonLabel="New Shipping Profile"
              />
            )}
          </div>
        )}
        {data.length > 0 &&
          data.map((profile) => (
            <div
              key={profile.shippingProfileId}
              className="mb-2 flex items-center py-2 space-x-2"
            >
              <div className="flex flex-col">
                <div className="flex space-x-2 align-middle">
                  <span>{profile.name}</span>
                  <CarrierChip carrier={profile.carrier} />
                </div>
                <div className="text-xs text-gray-400">
                  {profile.description}
                </div>
              </div>
              <ButtonRound
                icon={<PencilIcon className="h-6 w-6" aria-hidden="true" />}
                func={() => editWms(profile.shippingProfileId || 0)}
                label={`Edit ${profile.name} Profile`}
                design="secondary"
              />
              <ShippingProfileDeleteButton
                id={profile.shippingProfileId || 0}
                name={profile.name}
                reload={refreshProfileList}
              />
            </div>
          ))}
      </PageBody>
    </div>
  );
}
