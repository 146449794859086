import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import { apiInstance } from "../../services/api";
import { useAccount } from "../../AccountContext";
import { AccountDescriptor } from "../../services/webservice";

import FormError from "../../common/FormError";
import AccountDeleteButton from "./AccountDeleteButton";
import { AccountMigrateShopifyButton } from "./AccountMigrateShopifyButton";
import Button from "../../common/buttons/Button";
import ButtonGroup from "../../common/buttons/ButtonGroup";

interface IAccountFormProps {
  account?: AccountDescriptor;
}

export default function AccountForm({ account }: IAccountFormProps) {
  const navigate = useNavigate();
  const { setAccount } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AccountDescriptor>({
    defaultValues: {
      accountReference: account?.accountReference || "",
      accountName: account?.accountName || "",
    },
  });

  const onSubmit: SubmitHandler<AccountDescriptor> = (data) => {
    setLoading(true);
    let response;
    let loadingMessage = "Updating...";
    let successMessage = "Account updated successfully";
    if (account) {
      response = apiInstance.put(`/Accounts/${account.accountId}`, data);
    } else {
      loadingMessage = "Creating...";
      successMessage = "Account created successfully";
      response = apiInstance.post("/Accounts", data);
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => err.toString(),
      })
      .then(() => {
        reset();
        navigate("/accounts");
        setLoading(false);
        setAccount({ accountId: 0, accountName: "", accountReference: "" });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <label
                htmlFor="warehouseShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Account Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("accountName", { required: true })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      errors.accountName
                        ? "focus:ring-gray-300 focus:border-gray-300 border-gray-300 placeholder-gray-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="Bobby's Bits"
                  />
                </div>
                {errors.accountName && (
                  <FormError message="Account Name is Required" />
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="warehouseShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Account Reference
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("accountReference")}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      errors.accountName
                        ? "focus:ring-gray-300 focus:border-gray-300 border-gray-300 placeholder-gray-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="this is my reference data 1234"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex flex-wrap justify-between items-end">
            <div>
              <AccountMigrateShopifyButton id={account?.accountId} />
            </div>
            <ButtonGroup>
              <AccountDeleteButton id={account?.accountId} />
              <Button label="Cancel" path="/accounts" design="secondary" />
              <Button label="Save" type="submit" loading={loading} />
            </ButtonGroup>
          </div>
        </div>
      </div>
    </form>
  );
}
