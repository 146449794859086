import { useEffect, useState } from "react";
import { GlobeAltIcon as GlobeIcon } from "@heroicons/react/24/outline";

import WmsMethod from "../../common/shipmap/WmsMethod";
import AddWmsShipMethodDialog from "./AddWmsShipMethodDialog";
import SignatureRequired from "../../common/shipmap/SignatureRequired";
import HoldOrders from "../../common/shipmap/HoldOrders";
import WmsDescription from "../../common/shipmap/WmsDescription";

import Button from "../../common/buttons/Button";
import { GlobalShipMethodMapItem } from "../../services/webservice";

interface WmsConnectionMapProps {
  wmsConnectionId: number;
  wmsConnectionDescription?: string;
  item?: GlobalShipMethodMapItem | null | undefined;
  isEditable?: boolean;
  addItem: (item: GlobalShipMethodMapItem) => void;
  removeItem: (item: GlobalShipMethodMapItem) => void;
}

export default function WmsConnectionMap({
  wmsConnectionId,
  wmsConnectionDescription,
  item,
  isEditable = false,
  addItem,
  removeItem,
}: WmsConnectionMapProps) {
  const [refreshKey, setRefreshKey] = useState<number>(1);
  useEffect(() => {
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  if (!item)
    return (
      <div className="ml-0 sm:ml-2 flex items-center space-x-2 text-gray-400 flex-wrap">
        <div className="flex items-center">
          <GlobeIcon
            className="h-5 w-5 mr-1 hidden sm:inline-flex"
            aria-hidden="true"
          />
          <span>{wmsConnectionDescription}</span>
        </div>
        <WmsMethod />
        <AddWmsShipMethodDialog
          wmsConnectionId={wmsConnectionId}
          addItem={addItem}
        />
      </div>
    );
  return (
    <div className="ml-0 sm:ml-2 flex space-x-3 flex-wrap items-center">
      {wmsConnectionDescription && (
        <div className="flex items-center">
          <GlobeIcon className="h-5 w-5 mr-1" aria-hidden="true" />
          <span className="text-gray-600">{wmsConnectionDescription}</span>
        </div>
      )}
      <WmsMethod method={item.wmsShipMethod || ""} />
      {item.wmsConnectionDescription && !isEditable && (
        <WmsDescription wmsDescription={item.wmsConnectionDescription} />
      )}
      {item.signatureRequired && <SignatureRequired />}
      {item.holdOrder && <HoldOrders holdOrderHours={item.holdOrderHours} />}
      {isEditable && (
        <div key={refreshKey}>
          <AddWmsShipMethodDialog
            wmsConnectionId={wmsConnectionId}
            item={item}
            addItem={addItem}
          />
          <Button
            label="Remove"
            design="secondary"
            custom="ml-2"
            func={() => removeItem(item)}
          />
        </div>
      )}
    </div>
  );
}
