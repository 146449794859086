import { useEffect } from "react";
import { useNavigate } from "react-router";
import { XCircleIcon } from "@heroicons/react/24/solid";

import PageBody from "./PageBody";
import Button from "./buttons/Button";

import { parseHtmlErrors } from "../utils/Parse";

interface ErrorAlertProps {
  error: string;
  details?: string[];
  func?: () => void;
}

export default function ErrorAlert({ error, details, func }: ErrorAlertProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (error === "Your session has expired") navigate("/signin");
  }, [error, navigate]);

  return (
    <PageBody table={true}>
      <div className="rounded-md bg-red-50 p-4 mx-4 sm:mx-0">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              {parseHtmlErrors(error)}
            </h3>
            {details && (
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                  {details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {func && (
        <div className="px-4 sm:px-0">
          <p className="my-4 text-sm text-gray-700">
            Please make a note of the error information and provide it to your
            support team. You can attempt to recover from the error and reload
            the main integration page by clicking the button below:
          </p>
          <Button design="primary" label="Attempt Recovery" func={func} />
        </div>
      )}
    </PageBody>
  );
}
