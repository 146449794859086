import { useState, useEffect } from "react";

import { apiInstance } from "../api";
import { WmsConnectionDescriptor } from "../webservice";
import { WmsMeta } from "../../services/webservice/models/WmsMeta";
import { useAccount } from "../../AccountContext";
import { parseAccountParams } from "../../utils/Parse";

interface UseGetWmsProps {
  wmsConnectionId: number;
}

export default function useGetWms({ wmsConnectionId }: UseGetWmsProps) {
  const [data, setData] = useState<WmsConnectionDescriptor>();
  const [meta, setMeta] = useState<WmsMeta>();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        const response = await apiInstance.get(`/Wms/${wmsConnectionId}`, {
          params,
        });
        if (response.status === 200) {
          setData(response.data);
        } else {
          throw response;
        }
        const metaResponse = await apiInstance.get(
          `/Meta/wms/${response.data.wmsIdentifier}`,
          { params }
        );
        if (metaResponse.status === 200) {
          setMeta(metaResponse.data);
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || "Unknown error");
      } finally {
        setLoading(false);
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsConnectionId]);

  return { data, meta, error, loading };
}
