import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";

import {
  ArrowLeftOnRectangleIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";

import logo from "src/logo.svg";
import login from "src/login.jpg";
import FormError from "src/common/FormError";
import TextLink from "src/common/TextLink";

import { passwordReset } from "src/services/auth.service";

interface IFormInputs {
  password: string;
  passwordConfirmation: string;
}

export default function ResetPage() {
  const { resetKey } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setbuttonDisabled] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInputs>();
  const watchPassword = watch("password", "");
  const watchPasswordConfirmation = watch("passwordConfirmation", "");

  useEffect(() => {
    setbuttonDisabled(
      watchPassword.length === 0 || watchPasswordConfirmation.length === 0
    );
  }, [watchPassword, watchPasswordConfirmation]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setLoading(true);
    toast
      .promise(passwordReset(resetKey || "", data.password), {
        loading: "Reseting password...",
        success: "Password successfully reset",
        error: (err) => err.response.data.toString(),
      })
      .then(() => navigate("/signin"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="w-auto" src={logo} alt="EFS logo" />
              <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">
                Reset Password
              </h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Please use the form below to create a new password. Password
                must be at least 10 characters, contain 1 capital letter, 1
                number, and 1 of these symbols: #$^+=!*()@%&.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("password", {
                          required: "You must enter a password",
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{10,30}$/g,
                            message: "Password does not meet the requirements",
                          },
                        })}
                        type="password"
                        autoComplete="new-password"
                        placeholder="password"
                        className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                          errors.password
                            ? "border-red-300 placeholder-red-300 focus-within:border-red-300"
                            : "placeholder-gray-400 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                      {errors.password && (
                        <FormError message={errors.password.message} />
                      )}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="passwordConfirmation"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("passwordConfirmation", {
                          required: true,
                          validate: (value) =>
                            value === watchPassword ||
                            "The passwords do not match",
                        })}
                        type="password"
                        autoComplete="new-password"
                        placeholder="confirm password"
                        className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                          errors.password
                            ? "border-red-300 placeholder-red-300 focus-within:border-red-300"
                            : "placeholder-gray-400 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        }`}
                      />
                      {errors.passwordConfirmation && (
                        <FormError
                          message={errors.passwordConfirmation.message}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={loading || buttonDisabled}
                      className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    ${
                      loading || buttonDisabled
                        ? "opacity-20 text-gray-400"
                        : "hover:bg-gray-700"
                    }`}
                    >
                      {loading ? (
                        <>
                          <CloudIcon className="animate-bounce h-6 w-6 mr-2" />
                          Loading...
                        </>
                      ) : (
                        <>
                          <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-2" />
                          Save Password
                        </>
                      )}
                    </button>
                  </div>
                  <div className="text-center">
                    <div className="text-sm">
                      <TextLink link="/signin" text="Cancel" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={login}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
