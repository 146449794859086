import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface BannerProps {
  active?: boolean;
}

export function Banner({ active = false }: BannerProps) {
  const [dismissed, setDismissed] = useState(false);

  if (dismissed || !active) return null;

  return (
    <div className="flex items-center gap-x-6 bg-primary-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <a
          href="https://support.efulfillmentservice.com/hc/en-us/articles/17066502445467--STEP-1-FOR-SHOPIFY-MIGRATION-UPDATE-July-12th-2023-"
          target="_blank"
          rel="noopener noreferrer"
        >
          Have you completed your migration steps for the new V2 Shopify
          integration?
        </a>
        <a
          href="https://support.efulfillmentservice.com/hc/en-us/articles/17066502445467--STEP-1-FOR-SHOPIFY-MIGRATION-UPDATE-July-12th-2023-"
          className="flex-none rounded-full bg-primary-900 px-3.5 py-1 text-sm text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-900 ml-2 whitespace-nowrap"
          target="_blank"
          rel="noopener noreferrer"
        >
          VIEW STEPS <span aria-hidden="true">&rarr;</span>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={() => setDismissed(true)}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
