import { CubeIcon } from "@heroicons/react/24/solid";

import FulfillmentTracking from "./FulfillmentTracking";

import { Fulfillment } from "src/services/webservice";

interface OrderTrackingListItemProps {
  fulfillment: Fulfillment;
}

export default function OrderTrackingListItem({
  fulfillment,
}: OrderTrackingListItemProps) {
  return (
    <li>
      <div className="relative pb-4">
        <div className="relative flex space-x-3">
          <div>
            <span className="h-6 w-6 rounded-full flex items-center justify-center ring-6 ring-white bg-gray-400">
              <CubeIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1 pt-0.5 flex flex-wrap">
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              {fulfillment.carrier}
            </div>
            <div>
              <p className="text-sm text-gray-900 sm:ml-2">
                <FulfillmentTracking
                  tracking={fulfillment.trackingNumber || ""}
                />
              </p>
            </div>
          </div>
        </div>
        {fulfillment.serialNumbers && fulfillment.serialNumbers?.length > 0 && (
          <div className="ml-4 flex flex-wrap space-x-2 text-xs">
            <span className="text-gray-500">
              {fulfillment.serialNumbers?.length} &times; Serials Scanned:
            </span>
            {fulfillment.serialNumbers?.map((serial) => (
              <span key={serial}>
                {serial.length > 0 ? serial : "empty string"}
              </span>
            ))}
          </div>
        )}
      </div>
    </li>
  );
}
