import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { AccountDescriptor } from "../../services/webservice";
import useGetList from "../../services/useGetList";

import ErrorAlert from "../../common/ErrorAlert";
import Button from "../../common/buttons/Button";

interface AccountSelectorProps {
  label: string;
  func: (selected: AccountDescriptor) => void;
}

export default function AccountSelector({ label, func }: AccountSelectorProps) {
  const { data, loading, error } = useGetList<AccountDescriptor>(
    "/Accounts",
    1,
    800,
    0,
    null,
    "&SortFields=AccountName"
  );

  const [selected, setSelected] = useState<AccountDescriptor>(data[0]);
  const [query, setQuery] = useState("");

  const filteredAccounts =
    query === ""
      ? data
      : data.filter((account) =>
          `${account?.accountName} (id: ${account?.accountId}, ref: ${account?.accountReference})`
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  if (error) return <ErrorAlert error={error} />;

  return (
    <div className="flex space-x-2 items-center flex-wrap">
      <div className="sm:w-1/3">
        <Combobox value={selected} onChange={setSelected} name="account">
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(account: AccountDescriptor) =>
                  account?.accountName || ""
                }
                autoFocus
                autoComplete="off"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredAccounts.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredAccounts.map((account) => (
                    <Combobox.Option
                      key={account.accountId}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={account}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {`${account?.accountName} (id: ${account?.accountId}, ref: ${account?.accountReference})`}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-primary-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
      <Button
        label={label}
        icon={
          <ArrowRightIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        }
        func={() => func(selected)}
        loading={loading}
        disabled={!selected}
      />
    </div>
  );
}
