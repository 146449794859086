import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import LogRocket from "logrocket";

import App from "./App";
import "./index.css";

if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  console.log = function () {};
  LogRocket.init("fegrqq/efs-integration-portal", {
    network: {
      requestSanitizer: (request) => {
        if (request.headers["Authorization"]) {
          request.headers["Authorization"] = "";
        }

        // if the url contains 'login'
        if (request.url.toLowerCase().indexOf("login") !== -1) {
          // ignore the request response pair
          return null;
        }

        return request;
      },
    },
  });
}

ReactDOM.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
  document.getElementById("root")
);
