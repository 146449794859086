import { Fragment } from "react";
import { Transition } from "@headlessui/react";

const DialogOverlay = () => (
  <Transition.Child
    as={Fragment}
    enter="transition-opacity ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity ease-in duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 opacity-30" />
  </Transition.Child>
);

export default DialogOverlay;
