import { useParams } from "react-router";
import { UserGroupIcon } from "@heroicons/react/24/outline";

import { UserDescriptor } from "../../services/webservice";
import useGetOne from "../../services/useGetOne";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import UserForm from "./UserForm";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";

export default function EditShipMapPage() {
  const { userId } = useParams();
  const { loading, error, data } = useGetOne<UserDescriptor>(
    `/Users/${userId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Admin: Edit User"
          icon={<UserGroupIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Admin: Edit User"
        icon={<UserGroupIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? <LoadingCard /> : <UserForm user={data} />}
      </PageBody>
    </div>
  );
}
