import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FingerPrintIcon } from "@heroicons/react/24/outline";

import ErrorAlert from "src/common/ErrorAlert";
import PageHeader from "src/common/PageHeader";

import { apiInstance } from "src/services/api";
import { getErrorMessage } from "src/utils/Parse";

export function ShopifyV2() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // grab hmac and store params
    const params = searchParams.toString();

    (async () => {
      try {
        if (!params) {
          throw new Error("No URL parameters supplied from Shopify");
        }
        // call verify hmac endpoint
        const hmacVerification = await apiInstance.get(
          `/Oauth/verifyshopifyhmac?${params}`
        );
        if (hmacVerification.status === 200) {
          const authUrl = await apiInstance.get(
            `/OAuth/generateshopifyredirecturl?${params}`
          );
          if (authUrl.status === 200) {
            document.cookie = `ShopifyInstallNonce=s:${authUrl.data.nonce}; path=/; SameSite=None; Secure; max-age=900;`;
            window.location.href = authUrl.data.url;
          } else {
            throw new Error("Error getting auth url");
          }
        } else {
          throw new Error("Error verifying HMAC");
        }
      } catch (error) {
        setError(getErrorMessage(error));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Connect ShopifyV2"
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );
  return null;
}
