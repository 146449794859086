import DisplayMappedUomItem from "../skumaps/DisplayMappedUomItem";

import { UomMap } from "../../../services/webservice";

interface DisplayUomMapsProps {
  map?: UomMap;
}

export default function DisplayUomMaps({ map }: DisplayUomMapsProps) {
  if (!map || map.uomMapId === 0) return null;
  return (
    <div>
      {map.items &&
        map.items.map((item) => (
          <DisplayMappedUomItem key={item.uomMapItemId} item={item} />
        ))}
    </div>
  );
}
