import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import LoadingInline from "src/common/LoadingInline";

import ErrorAlert from "../../common/ErrorAlert";
import FormError from "src/common/FormError";
import { CarrierChip } from "./CarrierChip";

import { SettingEnumMeta } from "../../services/webservice";
import useGetList from "../../services/useGetList";

export type TpbAccountType = {
  accountId: number;
  accountName: string;
  carrier: string;
  precedence: number;
};

interface AvailableWmsTpbAccountsProps {
  wms: SettingEnumMeta;
  setAccount: (account: TpbAccountType) => void;
}

export function AvailableWmsTpbAccounts({
  wms,
  setAccount,
}: AvailableWmsTpbAccountsProps) {
  const { data, loading, error } = useGetList<TpbAccountType>(
    `/wms/${wms?.id}/tpbAccounts`,
    1,
    100
  );

  const [selected, setSelected] = useState<TpbAccountType>(data[0]);
  const [query, setQuery] = useState("");

  const filteredAccounts =
    query === ""
      ? data
      : data.filter((account) =>
          `${account?.accountName} (id: ${account?.accountId}, carrier: ${account?.carrier})`
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  if (error) return <ErrorAlert error={error} />;
  if (loading) return <LoadingInline message="Loading accounts..." />;
  if (data.length === 0)
    return (
      <FormError type="warning" message="WMS has no accounts configured" />
    );

  return (
    <div className="flex space-x-2 items-center flex-wrap">
      <div className="w-full z-10">
        <Combobox
          value={selected}
          onChange={(val) => {
            setSelected(val);
            setAccount(val);
          }}
          name="account"
        >
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(account: TpbAccountType) => account?.accountName}
                autoFocus
                autoComplete="off"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredAccounts.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredAccounts.map((account) => (
                    <Combobox.Option
                      key={account.accountId}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={account}
                    >
                      {({ selected, active }) => (
                        <div className="flex space-x-1">
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {`${account?.accountName} (id: ${account.accountId})`}
                          </span>
                          <CarrierChip carrier={account.carrier} />
                          {account.precedence === 0 && (
                            <CarrierChip carrier="DEFAULT" />
                          )}
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-primary-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
