import { useState, useEffect } from 'react';

import { apiInstance } from '../api';
import { useAccount } from '../../AccountContext';
import { parseAccountParams } from '../../utils/Parse';
import { IntegrationConfigDescriptor } from '../webservice';
import { CartMeta } from '../webservice';

interface useEditIntegrationProps {
  integrationConfigId: number;
}

export default function useEditIntegration({
  integrationConfigId,
}: useEditIntegrationProps) {
  const { account } = useAccount();
  const [data, setData] = useState<IntegrationConfigDescriptor>();
  const [meta, setMeta] = useState<CartMeta>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = parseAccountParams(account);
    async function init() {
      try {
        setLoading(true);
        const response = await apiInstance.get(
          `/integrations/${integrationConfigId}`,
          { params }
        );
        if (response.status === 200) {
          setData(response.data);
        } else {
          // throw response;
          throw Error('problems');
        }
        const metaResponse = await apiInstance.get(
          `/meta/cart/${response.data.cartIdentifier}`,
          { params }
        );
        if (metaResponse.status === 200) {
          setMeta(metaResponse.data);
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || 'Unknown error');
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [integrationConfigId, account]);

  return { data, meta, error, loading };
}
