import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, UserIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import logo from "../logo.svg";

import { useAccount } from "../AccountContext";
import * as AuthService from "../services/auth.service";
import { parseAxiosErrors, parseClassNames } from "../utils/Parse";
import { apiInstance } from "src/services/api";

type NavType = {
  name: string;
  href: string;
};

type NavArrayType = NavType[];

const standardNavigation: NavArrayType = [
  { name: "Integrations", href: "/integrations" },
  { name: "Orders", href: "/orders" },
  { name: "Global Ship Maps", href: "/shipmaps" },
  { name: "Audit Logs", href: "/audit" },
];

const superNavigation: NavArrayType = [
  ...standardNavigation,
  { name: "Accounts", href: "/accounts" },
];

const standardProfileNavigation: NavArrayType = [
  { name: "Your Profile", href: "/profile" },
  { name: "WMS Connections", href: "/wms" },
  { name: "Shipping Profiles", href: "/shippingprofiles" },
];

const adminNavigation: NavArrayType = [
  ...standardProfileNavigation,
  { name: "Users", href: "/users" },
];

export default function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAccount } = useAccount();
  const [navigation, setNavigation] =
    useState<NavArrayType>(standardNavigation);
  const [profileNavigation, setProfileNavigation] = useState<NavArrayType>(
    standardProfileNavigation
  );

  useEffect(() => {
    const user = AuthService.getUser();
    switch (user?.role) {
      case "Super":
      case "Staff":
        setNavigation(superNavigation);
        setProfileNavigation(adminNavigation);
        break;
      case "Admin":
        setProfileNavigation(adminNavigation);
        break;
    }
  }, []);

  const handleSignOut = () => {
    toast
      .promise(apiInstance.post("/Users/Logout"), {
        loading: "Signing out...",
        success: "Signed out",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        navigate("/signin");
        setAccount({ accountId: 0, accountName: "" });
        AuthService.logout();
      });
  };

  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-200">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-50">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={parseClassNames(
                        location.pathname === item.href
                          ? "border-gray-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      )}
                      aria-current={
                        location.pathname === item.href ? "page" : undefined
                      }
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                          <span className="sr-only">Open user menu</span>
                          <UserIcon className="h-6 w-6 text-gray-400" />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                        >
                          {profileNavigation.map((profileItem) => (
                            <Menu.Item key={profileItem.name}>
                              {({ active }) => (
                                <Link
                                  to={profileItem.href}
                                  className={parseClassNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {profileItem.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/"
                                onClick={handleSignOut}
                                className={parseClassNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign Out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={parseClassNames(
                    location.pathname === item.href
                      ? "bg-gray-50 border-gray-500 text-gray-700"
                      : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                    "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  )}
                  aria-current={
                    location.pathname === item.href ? "page" : undefined
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pb-3 border-t border-gray-200">
              <div className="mt-3 space-y-1">
                {profileNavigation.map((profileItem) => (
                  <Disclosure.Button
                    key={profileItem.name}
                    as={Link}
                    to={profileItem.href}
                    className={parseClassNames(
                      location.pathname === profileItem.href
                        ? "bg-gray-50 border-gray-500 text-gray-700"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={
                      location.pathname === profileItem.href
                        ? "page"
                        : undefined
                    }
                  >
                    {profileItem.name}
                  </Disclosure.Button>
                ))}
                <Link
                  to="/"
                  onClick={handleSignOut}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Sign Out
                </Link>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
