import {parseClassNames} from "../../utils/Parse";

interface IntegrationToggleStatusProps {
  loading: boolean;
  enabled: boolean;
}

export default function IntegrationToggleStatus({loading, enabled}: IntegrationToggleStatusProps) {
  if (loading) return (
    <span className="ml-2 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
      <span className="absolute flex-shrink-0 flex items-center justify-center">
        <span className="h-1.5 w-1.5 rounded-full bg-yellow-500" aria-hidden="true"></span>
      </span>
      <span className="ml-3.5 font-medium text-gray-900">Loading</span>
    </span>
  );

  return (
    <span className="ml-2 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
      <span className="absolute flex-shrink-0 flex items-center justify-center">
        <span className={parseClassNames(
          enabled
            ? 'bg-green-500'
            : 'bg-gray-500',
          'h-1.5 w-1.5 rounded-full'
        )} aria-hidden="true"></span>
      </span>
      <span className="ml-3.5 font-medium text-gray-900">{enabled ? 'Active' : 'Inactive'}</span>
    </span>
  );
}