import { useState } from "react";

interface UserPermsCheckboxProps {
  name: string;
  description?: string;
  isChecked: boolean;
  value: string;
  setValue: (perm: string) => void;
}

export default function UserPermsCheckbox({
  name,
  description,
  isChecked,
  value,
  setValue,
}: UserPermsCheckboxProps) {
  const [checked, setIsChecked] = useState<boolean>(isChecked);

  const handleChange = () => {
    setIsChecked(!checked);
    setValue(value);
  };

  return (
    <div className="relative flex items-start py-4">
      <div className="min-w-0 flex-1 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700 select-none">
          {name}
          <span className="text-gray-400 ml-2">{description}</span>
        </label>
      </div>
      <div className="ml-3 flex items-center h-5">
        <input
          name={name}
          id={name}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={handleChange}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </div>
    </div>
  );
}
