import { Fragment, useState } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";

import { WrenchIcon } from "@heroicons/react/24/outline";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import TextInput from "src/common/forms/TextInput";
import DialogOverlay from "src/common/DialogOverlay";
import LoadingInline from "src/common/LoadingInline";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import useGetOne from "src/services/useGetOne";
import { CartMeta } from "src/services/webservice";

const carrierOptions = ["USPS", "FEDEX", "UPS", "OTHER"];

interface ForceTrackingButtonProps {
  orderId: number;
  status: string | null | undefined;
  cartType: string;
  role: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  refreshParent: () => void;
  setParentDialogOpen: (open: boolean) => void;
}

interface IFormInputs {
  carrier: string | null;
  trackingNumber: string | null;
}

export function ForceTrackingButton({
  orderId,
  status,
  cartType,
  role,
  isOpen,
  setIsOpen,
  refreshParent,
  setParentDialogOpen,
}: ForceTrackingButtonProps) {
  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<string>("");
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const methods = useForm<IFormInputs>({
    defaultValues: {
      carrier: null,
      trackingNumber: null,
    },
  });
  const { data, loading, error } = useGetOne<CartMeta>(
    `/meta/cart/${cartType}`
  );

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setForceLoading(true);
    toast
      .promise(
        apiInstance.post(
          `/orders/${orderId}/forceTracking`,
          {
            Carrier: data.carrier ? data.carrier : null,
            TrackingNumber: data.trackingNumber ? data.trackingNumber : null,
          },
          { params }
        ),
        {
          loading: "Forcing...",
          success: "Tracking forced!",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => {
        setForceLoading(false);
        setIsOpen(false);
        methods.reset();
        setParentDialogOpen(false);
        refreshParent();
      })
      .catch(() => setForceLoading(false));
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  if (loading) {
    return (
      <div className="mt-4">
        <LoadingInline message="Loading force tracking..." />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-4 text-sm">
        <p className="font-medium text-gray-500">Force Tracking</p>
        <p className="text-gray-900">{error}</p>
      </div>
    );
  }

  if (data?.canForceTracking === false) {
    return (
      <div className="mt-4 text-sm">
        <p className="font-medium text-gray-500">Force Tracking</p>
        <p className="text-gray-900">Cart not supported</p>
      </div>
    );
  }

  const actualButton = (
    <>
      <Button
        label="Force Tracking"
        design="secondary"
        icon={<WrenchIcon className="mr-2 h-6 w-6" />}
        func={openModal}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={isOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Force Tracking
                    <p className="text-sm font-light text-gray-500 mt-2">
                      This will fulfill the entire fulfillment request. Carrier
                      and tracking not required.
                    </p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <FormProvider {...methods}>
                      <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        className="space-y-4"
                      >
                        <input type="hidden" {...methods.register("carrier")} />
                        <RadioGroup
                          value={carrier}
                          onChange={(selection: string) => {
                            setCarrier(selection);
                            methods.setValue("carrier", selection);
                          }}
                        >
                          <RadioGroup.Label>Carrier</RadioGroup.Label>
                          <div className="space-y-4 mt-2">
                            {carrierOptions.map((value) => (
                              <RadioGroup.Option
                                key={value}
                                value={value}
                                className={({ active, checked }) =>
                                  `${
                                    active
                                      ? "ring-2 ring-gray-500 ring-opacity-60"
                                      : ""
                                  }
      ${checked ? "bg-gray-900 bg-opacity-75 text-gray-400" : "bg-white"}
        relative rounded-lg p-2 cursor-pointer flex focus:outline-none bg-gray-200`
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center justify-between w-full">
                                      <div className="flex items-center">
                                        <div className="text-sm">
                                          <RadioGroup.Label
                                            as="p"
                                            className={`font-medium  ${
                                              checked
                                                ? "text-white"
                                                : "text-gray-900"
                                            }`}
                                          >
                                            {value}
                                          </RadioGroup.Label>
                                        </div>
                                      </div>
                                      {checked && (
                                        <div className="flex-shrink-0 text-white">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                          </svg>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                        <TextInput
                          label="Tracking Number"
                          name="trackingNumber"
                          type="text"
                          placeholder="999912312312341124314"
                        />
                        <ButtonGroup>
                          <Button
                            label="Cancel"
                            design="secondary"
                            func={closeModal}
                          />
                          <Button
                            label="Use Force"
                            icon={<WrenchIcon className="h-5 w-5 mr-2" />}
                            type="submit"
                            loading={forceLoading}
                          />
                        </ButtonGroup>
                      </form>
                    </FormProvider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  switch (status) {
    default:
      return (
        <div className="mt-4 text-sm">
          <p className="font-medium text-gray-500">Force Tracking</p>
          <p className="text-gray-900">Order status not supported</p>
        </div>
      );

    case "Open":
      return (
        <div className="mt-4">
          <p className="text-sm font-medium text-gray-500 mb-2">
            Force Tracking
          </p>
          {role === "Super" ? (
            actualButton
          ) : (
            <p className="text-gray-900">
              Order status only supported for Super role
            </p>
          )}
        </div>
      );

    case "Tracking Received":
    case "Tracking Error":
    case "Partial":
      return (
        <div className="mt-4">
          <p className="text-sm font-medium text-gray-500 mb-2">
            Force Tracking
          </p>
          {actualButton}
        </div>
      );
  }
}
