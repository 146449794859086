import {
  ArrowSmallRightIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";

import { UomMapItem } from "../../../services/webservice";

interface DisplayMappedUomItemProps {
  item: UomMapItem;
}

export default function DisplayMappedUomItem({
  item,
}: DisplayMappedUomItemProps) {
  if (item) {
    return (
      <p className="flex items-center text-sm text-gray-500 mt-2">
        <ArrowSmallRightIcon
          className="flex-shrink-0 mx-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <RectangleStackIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
          aria-hidden="true"
        />
        ({item.quantity} each) {item.uomSku}
      </p>
    );
  } else {
    return null;
  }
}
