import { useParams } from "react-router";
import { UserCircleIcon } from "@heroicons/react/24/outline";

import { AccountDescriptor } from "../../services/webservice";
import useGetOne from "../../services/useGetOne";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import AccountForm from "./AccountForm";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";

export default function EditShipMapPage() {
  const { accountId } = useParams();
  const { loading, error, data } = useGetOne<AccountDescriptor>(
    `/Accounts/${accountId}`
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Admin: Edit Account"
          icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Admin: Edit Account"
        icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        {loading ? <LoadingCard /> : <AccountForm account={data} />}
      </PageBody>
    </div>
  );
}
