import { useLocation, useNavigate } from "react-router-dom";

import { UserCircleIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import Button from "src/common/buttons/Button";
import AccountSelector from "./AccountSelector";

import { AccountDescriptor } from "src/services/webservice";
import { useAccount } from "src/AccountContext";

export default function AccountsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/integrations";
  const { setAccount } = useAccount();

  const selectAccount = (selected: AccountDescriptor) => {
    setAccount(selected);
    localStorage.setItem("efsIntAccount", JSON.stringify(selected));
    navigate(from);
  };

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Admin: Choose Account"
        description={
          <>
            You are an Admin, congratulations. You must select an account to
            admin.
          </>
        }
        icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
        actionComp={
          <Button
            label="Add"
            path="add"
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody overflow table>
        <AccountSelector label="Set Account" func={selectAccount} />
      </PageBody>
    </div>
  );
}
