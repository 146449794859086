import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import LoadingInline from "src/common/LoadingInline";

import ErrorAlert from "src/common/ErrorAlert";

import { ShippingProfileDescriptor } from "src/services/webservice";
import useGetList from "src/services/useGetList";
import FormError from "src/common/FormError";

interface AvailableWmsTpbAccountsProps {
  wmsConnectionId: string | undefined;
  carrier?: string | undefined;
  selectedProfile?: number;
  setProfile: (id: number) => void;
}

export function AvailableWmsShippingProfiles({
  wmsConnectionId,
  carrier,
  selectedProfile,
  setProfile,
}: AvailableWmsTpbAccountsProps) {
  const { data, loading, error } = useGetList<ShippingProfileDescriptor>(
    `/ShippingProfiles`,
    1,
    100,
    0,
    null,
    `?WmsConnectionId=${wmsConnectionId}${carrier ? `&Carrier=${carrier}` : ""}`
  );

  const [selected, setSelected] = useState(data[0]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    let selectedIndex = data.findIndex(
      (x) => x.shippingProfileId === selectedProfile
    );
    setSelected(data[selectedIndex]);
  }, [data, selectedProfile]);

  const filteredAccounts =
    query === ""
      ? data
      : data.filter((account) =>
          `${account?.name} ${account?.description}`
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  if (error) return <ErrorAlert error={error} />;
  if (loading)
    return (
      <LoadingInline
        message={`Loading ${carrier ? `${carrier} ` : ""}profiles...`}
      />
    );
  if (data.length === 0)
    return (
      <FormError
        type="warning"
        message={`WMS has no profiles configured for ${carrier}`}
      />
    );

  return (
    <div className="flex space-x-2 items-center flex-wrap">
      <div className="w-full z-30">
        <Combobox
          value={selected}
          onChange={(val: ShippingProfileDescriptor) => {
            console.log(val);
            setProfile(val.shippingProfileId || 0);
            setSelected(val);
          }}
          name="account"
        >
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(account: ShippingProfileDescriptor) =>
                  account?.name || ""
                }
                autoFocus
                autoComplete="off"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredAccounts.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredAccounts.map((account) => (
                    <Combobox.Option
                      key={`profile-${account.shippingProfileId}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={account}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {`${account?.name} ${account?.description}`}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-primary-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}
