import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import MappedItem, { MappedOrderItemType } from "./MappedItem";
import OrderTrackingListItem from "./OrderTrackingListItem";
import OrderStateListItem from "./OrderStateListItem";
import { WmsTrackingsList } from "./WmsTrackingsList";
import { ForceTrackingButton } from "./ForceTrackingButton";

import { getUser } from "src/services/auth.service";
import { Order } from "src/services/webservice/models/Order";
import { InfoAlert } from "src/common/InfoAlert";

interface OrderDetailsProps {
  order: Order;
  refreshParent: () => void;
  setParentDialogOpen: (open: boolean) => void;
}

export default function OrderDetails({
  order,
  setParentDialogOpen,
  refreshParent,
}: OrderDetailsProps) {
  const user = getUser();
  const [forceTrackingOpen, setForceTrackingOpen] = useState<boolean>(false);
  return (
    <>
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900"
      >
        {order.orderNumber} ({order.orderId})
      </Dialog.Title>
      <div className="mt-2">
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          {order.cartType}
        </span>
        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          {order.status}
        </span>
        <p className="mt-2 text-sm text-gray-500">
          Reference: {order.referenceNumber}
        </p>
        {(user?.role === "Super" || user?.role === "Staff") && (
          <p className="text-sm text-gray-500">CartId: {order.cartId}</p>
        )}
        <p className="text-sm text-gray-500">PO Number: {order.poNumber}</p>
        <p className="text-sm text-gray-500">WMS ID: {order.wmsId}</p>
        <div className="mt-4 border-t border-gray-200 px-0 py-5">
          {order.isRedacted && (
            <div className="mb-4">
              <InfoAlert message="Personally Identifiable Information (PII) has been redacted from this order." />
            </div>
          )}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1" id="billing-address">
              <dt className="text-sm font-medium text-gray-500">
                Billing Address
              </dt>
              {!order.isRedacted && (
                <>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.billAddress?.company}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${order.billAddress?.firstName} ${order.billAddress?.lastName}`}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.billAddress?.address1}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.billAddress?.address2}
                  </dd>
                </>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {`${order.billAddress?.city}, ${order.billAddress?.state} ${order.billAddress?.postalCode}`}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.country}
              </dd>
              {!order.isRedacted && (
                <>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.billAddress?.email}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.billAddress?.phone}
                  </dd>
                </>
              )}
            </div>
            <div className="sm:col-span-1" id="shipping-address">
              <dt className="text-sm font-medium text-gray-500">
                Shipping Address
              </dt>
              {!order.isRedacted && (
                <>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.shipAddress?.company}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {`${order.shipAddress?.firstName} ${order.shipAddress?.lastName}`}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.shipAddress?.address1}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.shipAddress?.address2}
                  </dd>
                </>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {`${order.shipAddress?.city}, ${order.shipAddress?.state} ${order.shipAddress?.postalCode}`}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.country}
              </dd>
              {!order.isRedacted && (
                <>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.shipAddress?.email}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.shipAddress?.phone}
                  </dd>
                </>
              )}
            </div>
            <div className="sm:col-span-2" id="shipping-method">
              <dt className="text-sm font-medium text-gray-500">
                Shipping Method
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex flex-wrap space-x-2">
                <span>{order.shipMethod}</span>
                <ArrowRightIcon className="w-4" />
                <span>{order.mappedShipMethod}</span>
              </dd>
            </div>
            <div className="sm:col-span-1" id="order-items">
              <dt className="text-sm font-medium text-gray-500">
                Order Items & Applied SKU Maps
              </dt>
              {order.items?.map((item) => (
                <div key={item.orderItemId}>
                  <dd
                    className="mt-1 text-sm text-gray-900"
                    key={item.orderItemId}
                  >
                    <span className="text-gray-500">
                      {item.quantity} &times;
                    </span>
                    &nbsp;
                    {item.sku || <span>"" (blank SKU)</span>}
                  </dd>
                  {order.items &&
                    item.mappedItems?.map((map: MappedOrderItemType) => (
                      <MappedItem item={map} key={map.orderItemId} />
                    ))}
                </div>
              ))}
            </div>
            {order.wmsItems && order.wmsItems?.length > 0 && (
              <div className="sm:col-span-1" id="order-items">
                <dt className="text-sm font-medium text-gray-500">
                  Submitted WMS Line Items
                </dt>
                {order.wmsItems?.map((item) => (
                  <div key={item.orderWmsItemId}>
                    <dd
                      className="mt-1 text-sm text-gray-900"
                      key={item.orderWmsItemId}
                    >
                      <span className="text-gray-500">
                        {item.quantity} &times;
                      </span>
                      &nbsp;
                      {item.sku}
                    </dd>
                  </div>
                ))}
              </div>
            )}
            <div className="sm:col-span-2" id="order-timeline">
              <dt className="text-sm font-medium text-gray-500">
                Order Timeline
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {order.orderLogs && order.orderLogs.length === 0 ? (
                  <span>No order state logged</span>
                ) : (
                  <ul>
                    {order.orderLogs?.map((log, logIdx) => (
                      <OrderStateListItem
                        key={log.orderLogId}
                        log={log}
                        logIdx={logIdx}
                        count={order.orderLogs?.length}
                      />
                    ))}
                  </ul>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2" id="order-tracking">
              <dt className="text-sm font-medium text-gray-500">Tracking</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {order.fulfillments && order.fulfillments.length === 0 ? (
                  <span>No fulfillments logged</span>
                ) : (
                  <ul>
                    {order.fulfillments?.map((fulfillment) => (
                      <OrderTrackingListItem
                        key={fulfillment.fulfillmentId}
                        fulfillment={fulfillment}
                      />
                    ))}
                  </ul>
                )}
              </dd>
            </div>
            {(user?.role === "Super" || user?.role === "Staff") && (
              <div className="flex flex-col">
                <WmsTrackingsList id={order.orderId} />
                {order.orderId && order.cartType && (
                  <ForceTrackingButton
                    orderId={order.orderId}
                    status={order.status}
                    cartType={order.cartType}
                    role={user?.role}
                    isOpen={forceTrackingOpen}
                    setIsOpen={setForceTrackingOpen}
                    refreshParent={refreshParent}
                    setParentDialogOpen={setParentDialogOpen}
                  />
                )}
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  );
}
