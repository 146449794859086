import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

import { parseClassNames } from "../../../utils/Parse";

interface EventLogStatusProps {
  status: string | null | undefined;
}

export default function EventLogStatus({ status }: EventLogStatusProps) {
  let iconBackground;
  let icon;

  switch (status) {
    case "Success":
      iconBackground = "bg-green-100";
      icon = (
        <CheckIcon className="h-5 w-5 text-green-800" aria-hidden="true" />
      );
      break;
    case "Warning":
      iconBackground = "bg-yellow-100";
      icon = (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-800"
          aria-hidden="true"
        />
      );
      break;
    case "Error":
      iconBackground = "bg-red-100";
      icon = (
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      );
      break;
    default:
      iconBackground = "bg-primary-100";
      icon = (
        <InformationCircleIcon
          className="h-5 w-5 text-primary-800"
          aria-hidden="true"
        />
      );
  }

  return (
    <div>
      <span
        className={parseClassNames(
          iconBackground,
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        {icon}
      </span>
    </div>
  );
}
