import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-hot-toast";

import { RectangleStackIcon, MapIcon } from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import AddWmsItemButton from "./AddWmsItemButton";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import DisplayMappedCartItem from "../skumaps/DisplayMappedCartItem";
import DisplayMappedWmsItem from "../skumaps/DisplayMappedWmsItem";
import LoadingCard from "src/common/LoadingCard";
import ErrorAlert from "src/common/ErrorAlert";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { ItemType, UomItemType } from "./types";
import useGetOne from "src/services/useGetOne";
import { CartItem } from "src/services/webservice";

export default function ItemPage() {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const { integrationConfigId, wmsConnectionId, mapType, cachedCartItemId } =
    useParams();
  const [wmsItems, setWmsItems] = useState<ItemType[]>([]);
  const { data, error, loading } = useGetOne<CartItem>(
    `/cart/${integrationConfigId}/items/${cachedCartItemId}`
  );

  const addItem = (newItem: ItemType) => {
    let isNew = true;
    let newItems = wmsItems.map((item) => {
      if (item.wmsSku === newItem.wmsSku) {
        isNew = false;
        return {
          wmsSku: item.wmsSku,
          quantity: item.quantity + newItem.quantity,
        };
      } else {
        return item;
      }
    });
    if (isNew) newItems.push(newItem);
    setWmsItems(newItems);
  };

  const createMap = () => {
    let mapEndpoint = `/integrations/${integrationConfigId}/SkuMaps`;
    let itemArray: ItemType[] | UomItemType[] = wmsItems;
    if (mapType === "uom") {
      mapEndpoint = `/integrations/${integrationConfigId}/UomMaps`;
      let uomItems: UomItemType[] = wmsItems.map((item) => {
        return { uomSku: item.wmsSku, quantity: item.quantity };
      });
      itemArray = uomItems;
    }

    toast
      .promise(
        apiInstance.post(
          mapEndpoint,
          {
            CartSku: data?.sku,
            items: itemArray,
          },
          { params }
        ),
        {
          loading: "Creating...",
          success: "Map has been created",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() =>
        navigate(
          `/integrations/${integrationConfigId}/${wmsConnectionId}/items`
        )
      );
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        {mapType === "sku" ? (
          <PageHeader
            title="Create SKU Map"
            icon={<MapIcon className="w-8 md:w-10 ml-2" />}
            description="SKU maps can help avoid physical kitting and bundling by routing an incoming cart SKU to one or more WMS SKUs."
          />
        ) : (
          <PageHeader
            title="Create UoM Map"
            icon={<RectangleStackIcon className="w-8 md:w-10 ml-2" />}
            description="Unit of Measure (UoM) maps can reduce pick touches by converting incoming cart quantities to WMS cases and or multi-packs."
          />
        )}
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      {mapType === "sku" ? (
        <PageHeader
          title="Create SKU Map"
          icon={<MapIcon className="w-8 md:w-10 ml-2" />}
          description="SKU maps can help avoid physical kitting and bundling by routing an incoming cart SKU to one or more WMS SKUs."
        />
      ) : (
        <PageHeader
          title="Create UoM Map"
          icon={<RectangleStackIcon className="w-8 md:w-10 ml-2" />}
          description="Unit of Measure (UoM) maps can reduce pick touches by converting incoming cart quantities to WMS cases and or multi-packs."
        />
      )}
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            <div className="text-gray-500 mb-4">
              Currently creating a map for SKU{" "}
              <span className="text-gray-900">{data?.sku || '""'}</span> with
              name: <span className="text-gray-900">{data?.name}</span>.
            </div>
            <p className="text-gray-500 mb-2 text-sm">
              Click the "Add WMS Items" button to search for WMS items to map.
            </p>
            <DisplayMappedCartItem sku={decodeURIComponent(data?.sku || "")} />
            {wmsItems.length > 0 &&
              wmsItems.map((item) => <DisplayMappedWmsItem item={item} />)}
            <div className="mt-4 mb-2">
              <AddWmsItemButton
                wmsConnectionId={Number(wmsConnectionId)}
                addItem={addItem}
                params={params}
              />
            </div>
            <div>
              <ButtonGroup>
                <Button
                  label="Cancel"
                  func={() => navigate(-1)}
                  design="secondary"
                />
                <Button
                  label="Create Map"
                  disabled={wmsItems.length === 0}
                  func={createMap}
                />
              </ButtonGroup>
            </div>
          </>
        )}
      </PageBody>
    </div>
  );
}
