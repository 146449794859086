import { Link } from "react-router-dom";

import { UserCircleIcon } from "@heroicons/react/24/outline";

import Button from "./buttons/Button";

import { useAccount } from "../AccountContext";

export default function AccountHeader() {
  const { account, setAccount } = useAccount();
  const clearAccount = () => {
    localStorage.removeItem("efsIntAccount");
    setAccount({ accountId: 0, accountName: "" });
  };
  if (account?.accountName) {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to="/accounts"
                className="text-gray-400 hover:text-gray-500"
              >
                <UserCircleIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>

          <li className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/accounts/edit/${account.accountId}`}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <span className="hidden sm:inline-flex">Account:</span>
                {` ${account.accountName} (id: ${account.accountId}`}
                {account.accountReference
                  ? `, ref: ${account.accountReference})`
                  : ")"}
              </Link>
              <Button
                label="clear"
                func={clearAccount}
                design="text"
                custom="text-sm sm:text-xs ml-2"
              />
            </div>
          </li>
        </ol>
      </nav>
    );
  } else {
    return null;
  }
}
