import { parseClassNames } from "../../utils/Parse";

interface OrderStatusBadgeProps {
  text?: string;
}

export default function OrderStatusBadge({ text }: OrderStatusBadgeProps) {
  let style = "";
  switch (text) {
    case "Open":
    case "Processing":
      style = "bg-green-100 text-green-800";
      break;
    case "Rejected":
      style = "bg-yellow-100 text-yellow-800";
      break;
    case "Tracking Error":
      style = "bg-red-100 text-red-800";
      break;
    default:
      style = "bg-gray-100 text-gray-800";
  }

  if (!text) return null;
  return (
    <span
      className={parseClassNames(
        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
        style
      )}
    >
      {text}
    </span>
  );
}
