import {
  ArrowsRightLeftIcon,
  FingerPrintIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import LoadingCard from "src/common/LoadingCard";
import CartMetaForm from "./CartMetaForm";
import ErrorAlert from "src/common/ErrorAlert";
import { InfoAlert } from "src/common/InfoAlert";

import { CartMeta, IntegrationConfigDescriptor } from "src/services/webservice";
import useGetOne from "src/services/useGetOne";

interface AddIntegrationMetaPageProps {
  header?: React.ReactNode;
  token?: string;
  existing?: IntegrationConfigDescriptor;
}

export default function AddIntegrationMetaPage({
  header: customHeader,
  token,
  existing,
}: AddIntegrationMetaPageProps) {
  const { integratorType } = useParams();
  const { loading, data, error } = useGetOne<CartMeta>(
    `/Meta/cart/${integratorType}`
  );

  let header = customHeader || (
    <PageHeader
      title="Add Integration"
      icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
    />
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        {header}
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      {header}
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            {existing && (
              <InfoAlert
                message="We found an existing config for this store which you are
              now editing. If you would prefer a fresh install please
              delete the existing config before installing the app."
              />
            )}
            {data?.requiresOAuthTokens && (
              <div className="rounded-md bg-primary-100 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <FingerPrintIcon
                      className="h-5 w-5 text-primary-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-primary-700">
                      <span className="font-medium mr-2">
                        Permissions Needed:
                      </span>
                      This integration requires OAuth tokens to be configured.
                      After saving your settings you will be prompted to grant
                      access via a secure "handshake" with the cart.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="pb-6">Configure {integratorType} Settings</div>
            <CartMetaForm meta={data} token={token} existing={existing} />
          </>
        )}
      </PageBody>
    </div>
  );
}
