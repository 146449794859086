import { CloudIcon } from "@heroicons/react/24/outline";

interface LoadingCardProps {
  message?: string;
}

export default function LoadingCard({
  message = "Loading...",
}: LoadingCardProps) {
  return (
    <div className="text-center py-4">
      <CloudIcon className="animate-bounce loader ease-linear h-12 w-12 mb-2 text-gray-400 mx-auto" />
      <h3 className="text-sm font-medium text-gray-900">{message}</h3>
    </div>
  );
}
