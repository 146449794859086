import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import IntegrationsPage from "./features/integrations/IntegrationsPage";
import IntegrationPage from "./features/integrations/IntegrationPage";
import AddIntegrationPage from "./features/integrations/AddIntegrationPage";
import AddIntegrationMetaPage from "./features/integrations/AddIntegrationMetaPage";
import EditIntegrationPage from "./features/integrations/EditIntegrationPage";
import PendingOrdersPage from "./features/integrations/pendingOrders/PendingOrdersPage";
import WmsConnectionsPage from "./features/wms/WmsConnectionsPage";
import AddWmsPage from "./features/wms/AddWmsPage";
import AddWmsMetaPage from "./features/wms/AddWmsMetaPage";
import EditWmsMetaPage from "./features/wms/EditWmsMetaPage";
import OrdersPage from "./features/orders/OrdersPage";
import ShipMapsPage from "./features/integrations/shipmaps/ShipMapsPage";
import AddShipMapPage from "./features/integrations/shipmaps/AddShipMapPage";
import EditShipMapPage from "./features/integrations/shipmaps/EditShipMapPage";
import ProfilePage from "./features/profile/ProfilePage";
import LoginPage from "./features/login/LoginPage";
import ForgotPage from "./features/login/ForgotPage";
import NotFound from "./NotFound";
import GlobalShipMapsPage from "./features/globalshipmaps/GlobalShipMapsPage";
import EditGlobalShipMapPage from "./features/globalshipmaps/EditGlobalShipMapPage";
import WmsItemsPage from "./features/integrations/wmsitems/WmsItemsPage";
import ItemsPage from "./features/integrations/items/ItemsPage";
import ItemPage from "./features/integrations/items/ItemPage";
import EditSkuMapPage from "./features/integrations/skumaps/EditSkuMapPage";
import EditUomMapPage from "./features/integrations/uommaps/EditUomMapPage";
import EventLogsPage from "./features/integrations/eventlogs/EventLogsPage";
import EventLogPage from "./features/integrations/eventlogs/EventLogPage";
import AccountsPage from "./features/accounts/AccountsPage";
import AddAccountPage from "./features/accounts/AddAccountPage";
import EditAccountPage from "./features/accounts/EditAccountPage";
import ResetPage from "./features/login/ResetPage";
import UsersPage from "./features/users/UsersPage";
import EditUserPage from "./features/users/EditUserPage";
import AddUserPage from "./features/users/AddUserPage";
import AuditLogsPage from "./features/auditLogs/AuditLogsPage";
import ToastContainer from "./features/toast/ToastContainer";
import PortalDialog from "./common/PortalDialog";
import { ShopifyV2 } from "./features/integrations/connect/ShopifyV2";
import { ShopifyV2Prefs } from "./features/integrations/prefs/ShopifyV2Prefs";
import { InventoryTransferPage } from "./features/integrations/inventoryTransfer/InventoryTransferPage";
import { ReAuthRedirectPage } from "./features/integrations/oauth/ReAuthRedirectPage";
import { VerifyPage } from "./features/integrations/oauth/VerifyPage";

import MainLayout from "./features/layout/MainLayout";
import RequireAuth from "./features/auth/RequireAuth";

import * as AuthService from "./services/auth.service";
import { AccountContext, AccountType } from "./AccountContext";
import { AccountDescriptor } from "./services/webservice";
import { CallbackPage } from "./features/integrations/oauth/CallbackPage";
import { CartInstallPage } from "./features/integrations/connect/CartInstallPage";
import { ShippingProfilesPage } from "./features/shippingprofiles/ShippingProfilesPage";
import { AddShippingProfilePage } from "./features/shippingprofiles/AddShippingProfilePage";
import { EditShippingProfilePage } from "./features/shippingprofiles/EditShippingProfilePage";

export default function App() {
  const localAccount = localStorage.getItem("efsIntAccount");
  const defaultAccount: AccountDescriptor = localAccount
    ? JSON.parse(localAccount)
    : {
        accountId: 0,
        accountName: "",
        accountReference: "",
      };
  const [account, setAccount] = useState<AccountType>(defaultAccount);
  const user = AuthService.getUser();

  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      <Routes>
        <Route path="/connect/Shopify" element={<ShopifyV2 />} />
        <Route>
          <Route path="/reset/:resetKey" element={<ResetPage />} />
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/signin" element={<LoginPage />} />
        </Route>
        <Route element={<MainLayout role={user?.role || ""} />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<IntegrationsPage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route
              path="/integrations/:integrationConfigId"
              element={<IntegrationPage />}
            />
            <Route path="/integrations/add" element={<AddIntegrationPage />} />
            <Route
              path="/integrations/add/:integratorType"
              element={<AddIntegrationMetaPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/edit"
              element={<EditIntegrationPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/pendingorders"
              element={<PendingOrdersPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/items"
              element={<ItemsPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/inventoryTransfer"
              element={<InventoryTransferPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/items/:cachedCartItemId/:mapType"
              element={<ItemPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/shipmaps"
              element={<ShipMapsPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/shipmaps/add"
              element={<AddShipMapPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/shipmaps/:shipMethodMapId/edit"
              element={<EditShipMapPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/skumaps/:skuMapId/edit"
              element={<EditSkuMapPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/:wmsConnectionId/uommaps/:uomMapId/edit"
              element={<EditUomMapPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/eventlogs"
              element={<EventLogsPage />}
            />
            <Route
              path="/integrations/:integrationConfigId/eventlogs/:eventLogId"
              element={<EventLogPage />}
            />
            <Route
              path="/integrations/oauth/verify/:integratorType"
              element={<VerifyPage />}
            />
            <Route
              path="/integrations/oauth/callback"
              element={<CallbackPage />}
            />
            <Route
              path="integrations/connect/:integratorType"
              element={<CartInstallPage />}
            />
            <Route
              path="/integrations/oauth/reauth/:integratorType"
              element={<ReAuthRedirectPage />}
            />
            <Route
              path="/integrations/prefs/ShopifyV2"
              element={<ShopifyV2Prefs />}
            />
            <Route path="/wms" element={<WmsConnectionsPage />} />
            <Route path="/wms/add" element={<AddWmsPage />} />
            <Route path="/wms/add/:wmsType" element={<AddWmsMetaPage />} />
            <Route
              path="/wms/edit/:wmsConnectionId"
              element={<EditWmsMetaPage />}
            />
            <Route
              path="/wms/:wmsConnectionId/items"
              element={<WmsItemsPage />}
            />
            <Route
              path="/shippingprofiles"
              element={<ShippingProfilesPage />}
            />{" "}
            <Route
              path="/shippingprofiles/edit/:shippingProfileId"
              element={<EditShippingProfilePage />}
            />
            <Route
              path="/shippingprofiles/add"
              element={<AddShippingProfilePage />}
            />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/shipmaps" element={<GlobalShipMapsPage />} />
            <Route
              path="/shipmaps/edit/:globalShipMapMethodId"
              element={<EditGlobalShipMapPage />}
            />
            <Route path="/shipmaps/add" element={<AddShipMapPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/add" element={<AddUserPage />} />
            <Route path="/users/edit/:userId" element={<EditUserPage />} />
            <Route path="/accounts" element={<AccountsPage />} />
            <Route path="/accounts/add" element={<AddAccountPage />} />
            <Route
              path="/accounts/edit/:accountId"
              element={<EditAccountPage />}
            />
            <Route path="/audit" element={<AuditLogsPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
      <PortalDialog
        title="Update Available!"
        isOpen={showReload}
        closeModal={reloadPage}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            A new version of the integration portal is available. Click the
            button to reload the app.
          </p>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={reloadPage}
          >
            Reload
          </button>
        </div>
      </PortalDialog>
      <ToastContainer />
    </AccountContext.Provider>
  );
}
