import { useState, useEffect } from "react";

import { apiInstance } from "../api";
import { useAccount } from "../../AccountContext";
import { parseAccountParams } from "../../utils/Parse";
import { WmsConnectionDescriptor } from "../webservice";

export enum Formats {
  Enum,
  Full,
}

interface UseGetWmsProps {
  format: Formats;
  reload?: number;
}

export default function useGetWms<T>({ format, reload = 0 }: UseGetWmsProps) {
  const { account } = useAccount();

  const [data, setData] = useState<T[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = parseAccountParams(account);
    async function init() {
      try {
        const response = await apiInstance.get(`/Wms`, { params });
        if (response.status === 200) {
          if (format === Formats.Enum) {
            const newData = response.data.map(
              (wms: WmsConnectionDescriptor) => {
                return { id: wms.wmsConnectionId, name: wms.description };
              }
            );
            setData(newData);
          } else {
            setData(response.data);
          }
          setTotalRecords(
            Number(response.headers["x-pagination-totalrecords"])
          );
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || "Unknown error");
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [format, account, reload]);

  return { data, totalRecords, error, loading };
}
