import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { FingerPrintIcon } from "@heroicons/react/24/outline";

import AddIntegrationMetaPage from "../AddIntegrationMetaPage";
import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import ErrorAlert from "src/common/ErrorAlert";
import LoadingCard from "src/common/LoadingCard";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, getErrorMessage } from "src/utils/Parse";
import { IntegrationConfigDescriptor } from "src/services/webservice";

export function VerifyPage() {
  const [searchParams] = useSearchParams();
  const shopifyParams = searchParams.toString();
  const shop = searchParams.get("shop");
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [existing, setExisting] = useState<IntegrationConfigDescriptor>();
  const { integratorType } = useParams();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<string>("");
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    setLoading(`Requesting Access Token for ${integratorType}...`);
    (async () => {
      try {
        const callback = await apiInstance.get(
          `/oauth/shopifycallback?${shopifyParams}`
        );
        if (callback.data.status === "Error") {
          throw new Error(callback.data.error);
        } else {
          setToken(callback.data.shopifyAccessToken);
          toast.success("Access Token Granted");
          const storeVerification = await apiInstance.get(
            `/Oauth/getshopifystore/${shop}`,
            { params }
          );
          if (storeVerification.status === 200) {
            if (storeVerification.data.integrationConfigId !== 0) {
              const params = parseAccountParams(account);
              const existingConfig = await apiInstance.get(
                `/integrations/${storeVerification.data.integrationConfigId}`,
                { params }
              );
              if (existingConfig.status === 200) {
                setExisting(existingConfig.data);
              } else {
                throw new Error(
                  "There was a problem retrieving the existing integration"
                );
              }
            }
          } else {
            throw new Error("Error verifying store");
          }
        }
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setLoading("");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title={`Connect ${integratorType}`}
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert
          error={`There was a problem with the request to obtain an access token. This can happen if you refresh the page after the token has already been granted. If that is the case, we recommend you restart the install process. Otherwise, please contact support. Additional information: ${error}`}
        />
      </div>
    );

  if (loading)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title={`Connect ${integratorType}`}
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
        <PageBody>
          <LoadingCard message={loading} />
        </PageBody>
      </div>
    );

  return (
    <AddIntegrationMetaPage
      existing={existing}
      token={token}
      header={
        <PageHeader
          title={`Connect ${integratorType}`}
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
      }
    />
  );
}
