import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";

import Button from "src/common/buttons/Button";
import DialogOverlay from "src/common/DialogOverlay";

import { apiInstance } from "src/services/api";
import { WmsItem } from "src/services/webservice";
import { ItemType } from "./types";
import FormError from "src/common/FormError";
import ButtonRound from "src/common/buttons/ButtonRound";

interface ISearchInputs {
  sku: string;
}

interface IQuantityInputs {
  quantity: number;
}

interface AddWmsItemButtonProps {
  wmsConnectionId: number;
  addItem: (item: ItemType) => void;
  params: object;
}

export default function AddWmsItemButton({
  wmsConnectionId,
  addItem,
  params,
}: AddWmsItemButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<WmsItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<WmsItem>();

  const {
    register,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm<ISearchInputs>();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset,
    formState: { errors },
  } = useForm<IQuantityInputs>();

  const onSubmit: SubmitHandler<ISearchInputs> = async (data) => {
    try {
      setData([]);
      setLoading(true);
      const response = await apiInstance.get(
        `/wms/${wmsConnectionId}/items?sku=${data.sku}&Page=1&PageSize=10&SortFields=Sku`,
        { params }
      );
      if (response.status === 200) {
        setData(response.data);
      } else {
        throw response;
      }
    } catch (e: any) {
      toast.error(e.message || e || "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const onSubmit2: SubmitHandler<IQuantityInputs> = (data) => {
    if (selectedItem) {
      addItem({
        wmsSku: selectedItem?.sku,
        quantity: Number(data.quantity),
      });
      reset();
      setSelectedItem(undefined);
    } else {
      console.log("no selectedItem");
    }
  };
  console.log(isSubmitted);
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  if (!wmsConnectionId) return null;
  return (
    <>
      <Button label="Add WMS Item(s)" func={openModal} design="secondary" />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          open={isOpen}
          onClose={closeModal}
        >
          <DialogOverlay />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Search for Warehouse Item
                  </Dialog.Title>
                  <p className="my-2 max-w-4xl text-sm text-gray-500">
                    Start typing the first part of your SKU as wildcards are
                    supported. Will display first 10 results in alphabetical
                    order. Choose item and enter quantity.
                  </p>
                  <div className="mt-2">
                    <form onSubmit={handleSubmit(onSubmit)} className="flex">
                      <label htmlFor="sku" className="sr-only">
                        Search by SKU
                      </label>
                      <input
                        id="sku"
                        type="text"
                        {...register("sku")}
                        className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="WIDGET-BLUE"
                      />
                      <Button
                        type="submit"
                        loading={loading}
                        icon={<MagnifyingGlassIcon className="h-5 w-5 mr-2" />}
                        label="Search"
                        custom="ml-2"
                      />
                    </form>
                  </div>
                  <div className="mt-2">
                    <ul>
                      {data.length === 0 && isSubmitted && (
                        <FormError message="no items found" type="warning" />
                      )}
                      {data.length > 0 &&
                        data.map((item) => (
                          <li
                            key={item.cachedWmsItemId}
                            className="border-b mb-2 py-2"
                          >
                            {item.sku}
                            <ButtonRound
                              design="tertiary"
                              icon={
                                <PlusIcon
                                  className="h-3 w-3"
                                  aria-hidden="true"
                                />
                              }
                              func={() => setSelectedItem(item)}
                              label={`select SKU ${item.sku}`}
                              custom="ml-2"
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                  {selectedItem && (
                    <>
                      <div className="mt-2">
                        {`enter quantity for: ${selectedItem.sku}`}
                      </div>
                      <div className="mt-2">
                        <form
                          onSubmit={handleSubmit2(onSubmit2)}
                          className="flex"
                        >
                          <label htmlFor="sku" className="sr-only">
                            Enter Quantity
                          </label>
                          <input
                            id="quantity"
                            type="number"
                            {...register2("quantity", {
                              required: true,
                              pattern: /^\+?([1-9]\d*)$/gm,
                            })}
                            className="shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm border-gray-300 rounded-md w-14"
                          />
                          <Button
                            type="submit"
                            label="Add to Map"
                            custom="ml-2"
                          />
                          {errors.quantity &&
                            errors.quantity.type === "pattern" && (
                              <FormError message="Must be a positive integer" />
                            )}
                          {errors.quantity &&
                            errors.quantity.type === "required" && (
                              <FormError message="Quantity is required" />
                            )}
                        </form>
                      </div>
                    </>
                  )}
                  <div className="flex justify-end pt-2">
                    <Button func={closeModal} label="Done" design="secondary" />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
