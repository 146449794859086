import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import { PlusSmallIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import Pagination from "src/common/Pagination";
import ErrorAlert from "src/common/ErrorAlert";
import UsersContent from "./UsersContent";
import Button from "src/common/buttons/Button";

import { UserDescriptor } from "src/services/webservice";
import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import useGetList from "src/services/useGetList";
import * as AuthService from "src/services/auth.service";
import { TABLE_COLUMN_HEADER_TEXT } from "src/utils/styles";

export default function UsersPage() {
  const { integrationConfigId } = useParams();
  const { account } = useAccount();
  const role = AuthService.getUser()?.role;
  const params = parseAccountParams(account);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const { data, loading, error, totalRecords, totalPages } =
    useGetList<UserDescriptor>(`/users`, page, pageSize, refreshKey);

  const refreshWarehouseItems = () => {
    toast
      .promise(
        apiInstance.get(`/wms/${integrationConfigId}/items/refresh`, {
          params,
        }),
        {
          loading: "Refreshing WMS items...",
          success: "WMS items list has been successfully refreshed",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => setRefreshKey(refreshKey + 1));
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="User Management"
          icon={<UserGroupIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="User Management"
        description="If you modify user permissions they will not take effect until the user signs in again."
        icon={<UserGroupIcon className="w-8 md:w-10 ml-2" />}
        actionComp={
          <Button
            label="Add"
            path="add"
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      {account.accountId === 0 && (role === "Super" || role === "Staff") && (
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-0 py-2 sm:py-8">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    You do not have an account selected.{" "}
                    <span className="font-bold">
                      Only proceed if you are managing Super / Staff users or
                    </span>{" "}
                    <Link
                      to="/accounts"
                      className="font-medium underline text-yellow-700 hover:text-yellow-600"
                    >
                      click here to select an account.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <PageBody table={true}>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        ID
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Name
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Role
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Login
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Last Login
                      </th>{" "}
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <UsersContent
                      data={data}
                      loading={loading}
                      refresh={refreshWarehouseItems}
                    />
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
