import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { Dialog, Transition } from "@headlessui/react";

import Button from "src/common/buttons/Button";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import DialogOverlay from "src/common/DialogOverlay";

interface NoWmsTargetDialogProps {
  open: boolean;
}

export default function NoWmsTargetDialog({ open }: NoWmsTargetDialogProps) {
  let navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(open);

  function closeModal() {
    setIsOpen(false);
    navigate("/wms/add");
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        open={isOpen}
        onClose={closeModal}
      >
        <DialogOverlay />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  No WMS Targets Available
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You will need to configure a WMS connection before you can
                    configure an integration. Click the button below to start
                    that process.
                  </p>
                </div>

                <ButtonGroup>
                  <Button label="Configure WMS" func={closeModal} />
                </ButtonGroup>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
