import { useNavigate, useParams } from "react-router";

import { PencilIcon, PencilSquareIcon } from "@heroicons/react/24/outline";

import { PlusIcon } from "@heroicons/react/24/solid";

import LoadingCard from "../../../common/LoadingCard";
import FolderIcon from "../../../common/FolderIcon";
import CustomItemDeleteButton from "./CustomItemDeleteButton";
import Button from "../../../common/buttons/Button";
import DisplaySkuMaps from "./DisplaySkuMaps";
import DisplayUomMaps from "./DisplayUomMaps";

import { CartItem } from "../../../services/webservice";
import { parseClassNames } from "../../../utils/Parse";
import { TABLE_COLUMN_TEXT } from "../../../utils/styles";
import * as AuthService from "src/services/auth.service";

interface WmsItemsContentProps {
  data: CartItem[];
  loading: boolean;
  integrationConfigId: string;
  selectedIds: number[];
  refreshCartItems: () => void;
  setSelectedIds: (ids: number[]) => void;
  refreshItemsList: () => void;
  editCustomItem: (item: CartItem) => void;
}

export default function ItemsContent({
  data,
  loading,
  integrationConfigId,
  selectedIds,
  refreshCartItems,
  setSelectedIds,
  refreshItemsList,
  editCustomItem,
}: WmsItemsContentProps) {
  const navigate = useNavigate();
  const user = AuthService.getUser();
  const { wmsConnectionId } = useParams();

  // This function will be triggered when a checkbox changes its state
  const selectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = parseInt(event.target.value);

    // Check if "ids" contains "selectedIds"
    // If true, this checkbox is already checked
    // Otherwise, it is not selected yet
    if (selectedIds.includes(selectedId)) {
      const newIds = selectedIds.filter((id) => id !== selectedId);
      setSelectedIds(newIds);
    } else {
      const newIds = [...selectedIds];
      newIds.push(selectedId);
      setSelectedIds(newIds);
    }
  };

  if (loading)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <LoadingCard />
          </div>
        </td>
      </tr>
    );

  if (data.length === 0)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <FolderIcon />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Cart Items
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by syncing cart items.
            </p>
            <div className="mt-6">
              <Button label="Refresh Cart Items" func={refreshCartItems} />
            </div>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {data.map((item) => (
        <tr
          key={item.cachedCartItemId}
          className={
            selectedIds.includes(item.cachedCartItemId) ? "bg-gray-50" : ""
          }
        >
          <td className="relative w-12 px-6 sm:w-16 sm:px-8">
            {selectedIds.includes(item.cachedCartItemId) && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600"></div>
            )}

            <input
              type="checkbox"
              onChange={selectItem}
              value={item.cachedCartItemId}
              checked={selectedIds.includes(item.cachedCartItemId)}
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-primary-300 text-primary-600 focus:ring-primary-500 sm:left-6"
            />
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              "max-w-xs break-normal"
            )}
          >
            <div>
              <div className="text-sm font-medium">
                <div className="flex space-x-2">
                  <span
                    className={
                      selectedIds.includes(item.cachedCartItemId)
                        ? "text-primary-600"
                        : ""
                    }
                  >
                    {item.sku === "" ? '""' : item.sku}
                  </span>
                  {item.isCustom && item.sku !== "" && (
                    <Button
                      label="edit item"
                      icon={<PencilSquareIcon className="w-4 h-4 mr-0.5" />}
                      design="text"
                      srOnly={`,${item.sku}`}
                      func={() => editCustomItem(item)}
                    />
                  )}
                </div>
              </div>
              <div
                className={parseClassNames(
                  "text-sm text-gray-500",
                  selectedIds.includes(item.cachedCartItemId)
                    ? "text-primary-500"
                    : ""
                )}
              >
                {item.name}
              </div>
            </div>
          </td>
          <td className={TABLE_COLUMN_TEXT.base}>
            <span
              className={parseClassNames(
                item.isActive
                  ? "bg-green-100 text-green-800"
                  : "bg-gray-100 text-gray-800",
                "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
              )}
            >
              {item.isActive ? "Active" : "Inactive"}
            </span>
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              TABLE_COLUMN_TEXT.secondary
            )}
          >
            {item.isCustom ? "Custom" : "Cart"}
          </td>
          <td className={parseClassNames(TABLE_COLUMN_TEXT.base)}>
            <DisplaySkuMaps map={item.skuMap} />
            <DisplayUomMaps map={item.uomMap} />
          </td>
          <td className={parseClassNames(TABLE_COLUMN_TEXT.base)}>
            <div className="flex space-x-2 justify-end items-center">
              {item.skuMap?.skuMapId && item.skuMap.skuMapId > 0 ? (
                <Button
                  label="SKU Map"
                  design="secondary"
                  srOnly={`, ${item.sku} edit`}
                  icon={
                    <PencilIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                  }
                  func={() =>
                    navigate(
                      `/integrations/${integrationConfigId}/${wmsConnectionId}/skumaps/${item.skuMap?.skuMapId}/edit`
                    )
                  }
                />
              ) : (
                <>
                  {!item.uomMap && (
                    <Button
                      label="SKU Map"
                      design="secondary"
                      srOnly={`, ${item.sku} create`}
                      icon={
                        <PlusIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      }
                      func={() => navigate(`${item.cachedCartItemId}/sku`)}
                    />
                  )}
                  {(user?.role === "Super" || user?.role === "Staff") &&
                    item.sku !== "" && (
                      <>
                        {!item.uomMap ? (
                          <Button
                            label="UoM Map"
                            design="secondary"
                            srOnly={`, ${item.sku} create`}
                            icon={
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                            }
                            func={() =>
                              navigate(`${item.cachedCartItemId}/uom`)
                            }
                          />
                        ) : (
                          <Button
                            label="UoM Map"
                            design="secondary"
                            srOnly={`, ${item.sku} edit`}
                            icon={
                              <PencilIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                            }
                            func={() =>
                              navigate(
                                `/integrations/${integrationConfigId}/${wmsConnectionId}/uommaps/${item.uomMap?.uomMapId}/edit`
                              )
                            }
                          />
                        )}
                      </>
                    )}
                </>
              )}
              {item.isCustom && (
                <CustomItemDeleteButton
                  id={item.cachedCartItemId}
                  integrationConfigId={integrationConfigId}
                  refresh={refreshItemsList}
                />
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}
