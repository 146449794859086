import { useState } from "react";
import toast from "react-hot-toast";

import { EyeIcon } from "@heroicons/react/24/outline";

import Button from "src/common/buttons/Button";
import EventDetails from "./EventDetails";
import PortalDialog from "src/common/PortalDialog";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { EventLog } from "src/services/webservice";

interface EventDetailDialogProps {
  id?: number;
  integrationConfigId: string | undefined;
}

export default function EventDetailDialog({
  id,
  integrationConfigId,
}: EventDetailDialogProps) {
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<EventLog>();

  function closeModal() {
    setDialogOpen(false);
  }

  const handleEventDetails = (eventLogId: number | undefined) => {
    toast
      .promise(
        apiInstance.get(
          `/EventLogs/${eventLogId}?integrationConfigId=${integrationConfigId}`,
          { params }
        ),
        {
          loading: "Loading details...",
          success: "Details loaded",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then((res) => {
        setEvent(res.data);
        setDialogOpen(true);
      });
  };

  return (
    <>
      <Button
        label="Details"
        func={() => handleEventDetails(id)}
        design="secondary"
        icon={<EyeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      />
      <PortalDialog
        title="Event Details"
        isOpen={dialogOpen}
        closeModal={closeModal}
      >
        {event && (
          <EventDetails
            event={event}
            buttonFunc={closeModal}
            buttonLabel="Close"
          />
        )}
      </PortalDialog>
    </>
  );
}
