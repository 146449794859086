export const TABLE_COLUMN_SORT = {
  active: "bg-gray-200 text-gray-900 group-hover:bg-gray-300",
  innactive: "text-gray-400 group-hover:visible group-focus:visible",
};

export const TABLE_COLUMN_HEADER_TEXT = {
  base: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
};

export const TABLE_COLUMN_TEXT = {
  base: "px-3 py-4 text-sm text-gray-900",
  primary: "font-medium",
  secondary: "text-gray-500",
};

export const BUTTONS = {
  base: "py-2 px-4 inline-flex items-center border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50",
  primary:
    "text-white bg-primary hover:bg-primary-600 border-transparent focus:ring-primary-300",
  secondary:
    "text-gray-700 bg-white hover:bg-gray-50 border-gray-300 focus:ring-gray-500",
  delete:
    "text-red-900 bg-red-100 hover:bg-red-200 border-transparent focus:ring-red-500",
  text: "text-primary-600 hover:text-primary-900 underline flex items-center font-medium",
};

export const BUTTONS_ROUND = {
  base: "inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
  primary: "text-white bg-primary hover:bg-primary-600 focus:ring-primary-500",
  secondary: "text-gray-600 bg-white hover:bg-gray-200 focus:ring-gray-600",
  disabled: "text-gray-300 bg-gray-100",
  tertiary: "text-white bg-gray-400 hover:bg-gray-500 focus:ring-gray-400",
  delete: "text-red-900 bg-red-100 hover:bg-red-200 focus:ring-red-900",
  icon: "p-1 sm:p-0 h-10 w-10 sm:h-6 sm:w-6",
};

export const FORM_ERROR = {
  base: "ml-1 mt-2 text-xs flex items-center",
  warn: "text-yellow-600",
  error: "text-red-600",
  info: "text-primary-600",
  icons: "h-5 w-5 mr-1",
};

export const TEXT_INPUT = {
  base: "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm",
  valid:
    "placeholder-gray-400 border-gray-300 focus:ring-primary-500 focus:border-primary-500",
  error:
    "border-red-300 placeholder-red-300 focus-within:border-red-300 focus:ring-red-500 focus:border-red-500",
};
