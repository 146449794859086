import { Link } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/solid";

import EmptyList from "../../../common/EmptyList";
import ShipMapping from "../../../common/shipmap/ShipMapping";
import LoadingCard from "../../../common/LoadingCard";
import SignatureRequired from "../../../common/shipmap/SignatureRequired";
import { ShippingProfileChip } from "src/common/shipmap/ShippingProfile";
import HoldOrders from "../../../common/shipmap/HoldOrders";

import { ShipMethodMapDescriptor } from "../../../services/webservice";

interface WmsItemsContentProps {
  data: ShipMethodMapDescriptor[];
  loading: boolean;
}

export default function ShipMapsContent({
  data,
  loading,
}: WmsItemsContentProps) {
  if (loading)
    return (
      <li>
        <div className="text-center py-10">
          <LoadingCard />
        </div>
      </li>
    );

  if (data.length === 0)
    return (
      <li>
        <div className="text-center py-10">
          <EmptyList
            title="No Ship Maps"
            description="Get started by creating a shipping method map."
            buttonPath="add"
            buttonLabel="New Ship Map"
          />
        </div>
      </li>
    );

  return (
    <>
      {data.map((map, index) => (
        <li key={index}>
          <Link
            to={`${map.shipMethodMapId}/edit`}
            className="block hover:bg-gray-50"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-end space-x-3 flex-wrap">
                <ShipMapping
                  cartMethod={map.cartShipMethod}
                  wmsMethod={map.warehouseShipMethod}
                />
                {map.signatureRequired && <SignatureRequired />}
                {map.holdOrder && (
                  <HoldOrders holdOrderHours={map.holdOrderHours} />
                )}
                {map.shippingProfileId > 0 && <ShippingProfileChip />}
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
        </li>
      ))}
    </>
  );
}
