import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import FormError from "../FormError";

import { parseClassNames } from "../../utils/Parse";
import { TEXT_INPUT } from "../../utils/styles";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  description?: string;
  register?: any;
  required?: boolean;
  pattern?: "email" | "alphanumeric";
  wrapperClass?: string;
  className?: string;
}

const TextInput: FC<TextInputProps> = ({
  name,
  label,
  description,
  wrapperClass,
  required = false,
  pattern,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  let regexPattern = undefined;
  switch (pattern) {
    case "alphanumeric":
      regexPattern = /^[a-zA-Z0-9]*$/gm;
      break;
    case "email":
      regexPattern =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
      break;
  }
  return (
    <div className={wrapperClass}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}{" "}
          {rest.disabled && (
            <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
              READ ONLY
            </span>
          )}
        </label>
      )}
      <div className="mt-1">
        <input
          aria-invalid={errors[name] ? "true" : "false"}
          {...register(`${name}` as const, { required, pattern: regexPattern })}
          {...rest}
          className={parseClassNames(
            TEXT_INPUT.base,
            errors[name] ? TEXT_INPUT.error : TEXT_INPUT.valid,
            rest.disabled && "bg-gray-200"
          )}
        />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {description}
        </p>
      )}
      <ErrorMessage
        errors={errors}
        name="multipleErrorInput"
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <FormError key={type} message={message} />
          ))
        }
      />
    </div>
  );
};

export default TextInput;
