import { ArrowSmallRightIcon, MapIcon } from "@heroicons/react/24/outline";

import { SkuMapItem } from "../../../services/webservice";
import { ItemType } from "../items/types";

interface DisplayMappedWmsItemProps {
  item: SkuMapItem | ItemType;
}

export default function DisplayMappedWmsItem({
  item,
}: DisplayMappedWmsItemProps) {
  if (item) {
    return (
      <p className="flex items-center text-sm text-gray-500 mt-2">
        <ArrowSmallRightIcon
          className="flex-shrink-0 mx-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <MapIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
          aria-hidden="true"
        />
        <span className="text-gray-500">{item.quantity} &times;</span>
        &nbsp;
        {item.wmsSku}
      </p>
    );
  } else {
    return null;
  }
}
