import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { PlusSmallIcon } from "@heroicons/react/24/outline";

import { FingerPrintIcon } from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import ErrorAlert from "src/common/ErrorAlert";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, getErrorMessage } from "src/utils/Parse";
import { IntegrationConfigDescriptor } from "src/services/webservice";
import Button from "src/common/buttons/Button";
import { CartInstallContent } from "../oauth/CartInstallContent";

export function CartInstallPage() {
  const navigate = useNavigate();
  const { account } = useAccount();
  const [existing, setExisting] = useState<IntegrationConfigDescriptor[]>([]);
  const { integratorType } = useParams();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const checkCartMeta = await apiInstance.get(
          `/meta/cart/${integratorType}`
        );

        if (checkCartMeta.status === 200) {
          const params = parseAccountParams(account);
          const existingConfigs = await apiInstance.get(
            `/integrations?CartIdentifier=${integratorType}`,
            { params }
          );
          if (existingConfigs.status === 200) {
            setExisting(existingConfigs.data);
          }
        } else {
          throw new Error(`Integration for ${integratorType} not found`);
        }
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonHeader = (
    <PageHeader
      title={`Connect ${integratorType}`}
      icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
      description="Choose an existing configuration to edit or re-authorize, or create a new one."
      actionComp={
        <Button
          label="New Integration"
          type="button"
          func={() => {
            navigate(`/integrations/add/${integratorType}`);
          }}
          icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
        />
      }
    />
  );

  if (error)
    return (
      <div className="py-2 sm:py-10">
        {commonHeader}
        <ErrorAlert error={`There was a problem: ${error}`} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      {commonHeader}
      <PageBody>
        <ul className="divide-y divide-gray-200">
          {integratorType && (
            <CartInstallContent
              data={existing}
              integratorType={integratorType}
              loading={loading}
            />
          )}
        </ul>
      </PageBody>
    </div>
  );
}
