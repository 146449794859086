import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

import LoadingInline from "../../common/LoadingInline";

import FormError from "../../common/FormError";
import useGetList from "src/services/useGetList";

export type WmsShipMethodType = {
  name: string;
  code: string;
  carrier: string;
  international: boolean;
  expedited: boolean;
  staffOnly: boolean;
};

interface AvailableWmsShipMethodsProps {
  wmsConnectionId: string;
  selectedShipMethod?: string | null | undefined;
  setMethod: (method: WmsShipMethodType) => void;
}

export function AvailableWmsShipMethods({
  wmsConnectionId,
  selectedShipMethod,
  setMethod,
}: AvailableWmsShipMethodsProps) {
  const { data, error, loading } = useGetList<WmsShipMethodType>(
    `/Wms/${wmsConnectionId}/wmsShipMethods`,
    1,
    100,
    0
  );
  const [selected, setSelected] = useState(data[0]);
  const [customWmsCode, setCustomWmsCode] = useState<boolean>(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    console.log("selectedShipMethod", selectedShipMethod);
    if (selectedShipMethod) {
      let selectedIndex = data.findIndex((x) => x.code === selectedShipMethod);

      if (selectedIndex === -1) {
        setCustomWmsCode(true);
      } else {
        setSelected(data[selectedIndex]);
        setCustomWmsCode(false);
      }
    } else {
      setSelected(data[0]);
    }
  }, [data, selectedShipMethod]);

  const filteredShipMethods =
    query === ""
      ? data
      : data.filter((shipMethod) =>
          shipMethod.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSelection = (selection: WmsShipMethodType) => {
    setSelected(selection);
    setMethod(selection);
  };

  if (error) return <FormError message={error} />;
  if (loading)
    return <LoadingInline message="Loading available WMS ship methods..." />;

  return (
    <div className="mb-4 w-full">
      {customWmsCode ? (
        <>
          <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            {selectedShipMethod}
          </div>
          <FormError
            type="warning"
            message="This is a custom code that can only be edited by support staff"
          />
        </>
      ) : (
        <Combobox value={selected} onChange={(item) => handleSelection(item)}>
          <div className="relative mt-1 z-50">
            <div className="relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
              <Combobox.Input
                className="w-full py-2 pl-3 pr-10 sm:text-sm leading-5 text-gray-900 border rounded-md shadow-sm border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                displayValue={(shipMethod: WmsShipMethodType) =>
                  shipMethod.name
                }
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredShipMethods.length === 0 && query !== "" ? (
                  <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredShipMethods.map((shipMethod) => (
                    <Combobox.Option
                      key={shipMethod.code}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 ${
                          active ? "text-white bg-primary-600" : "text-gray-900"
                        }`
                      }
                      value={shipMethod}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {shipMethod.name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-primary-600"
                              }`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      )}
    </div>
  );
}
