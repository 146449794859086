import {
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";

interface InfoAlertProps {
  title?: string;
  message: string;
  type?: "info" | "warning" | "error";
}

export function InfoAlert({ title, message, type = "info" }: InfoAlertProps) {
  switch (type) {
    case "warning":
      return (
        <div className="rounded-md bg-yellow-100 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-yellow-700">
                {title && <span className="font-medium mr-2">{title}</span>}
                {message}
              </p>
            </div>
          </div>
        </div>
      );
    case "error":
      return (
        <div className="rounded-md bg-red-100 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-red-700">
                {title && <span className="font-medium mr-2">{title}</span>}
                {message}
              </p>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="rounded-md bg-primary-100 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-primary-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-primary-700">
                {title && <span className="font-medium mr-2">{title}</span>}
                {message}
              </p>
            </div>
          </div>
        </div>
      );
  }
}
