interface CheckAllButtonProps {
  perms: number;
  label: "Permissions" | "Notifications";
  maxValue: number;
  massCheck: (perm: number) => void;
}

export default function CheckAllButton({
  perms,
  label,
  maxValue,
  massCheck,
}: CheckAllButtonProps) {
  return (
    <div className="mt-4">
      {perms !== 0 && (
        <button
          type="button"
          onClick={() => massCheck(0)}
          className="mr-2 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          clear all<span className="sr-only">{` ${label}`}</span>
        </button>
      )}
      {perms !== maxValue && (
        <button
          type="button"
          onClick={() => massCheck(maxValue)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          check all<span className="sr-only">{` ${label}`}</span>
        </button>
      )}
    </div>
  );
}
