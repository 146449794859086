import { useState } from "react";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

import Button from "src/common/buttons/Button";
import PortalDialog from "src/common/PortalDialog";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAxiosErrors } from "src/utils/Parse";

interface AccountDeleteButtonProps {
  id?: number;
  integrationConfigId?: number;
}

export default function AccountDeleteButton({
  id,
  integrationConfigId,
}: AccountDeleteButtonProps) {
  const navigate = useNavigate();
  const { setAccount } = useAccount();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleDeleteAccount = async () => {
    closeModal();
    toast
      .promise(apiInstance.delete(`/Accounts/${id}`), {
        loading: "Deleting account...",
        success: "Account has been successfully deleted.",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        navigate("/accounts");
        setAccount({ accountId: 0, accountName: "" });
      });
  };

  if (id) {
    return (
      <>
        <Button label="Delete" func={openModal} design="delete" />
        <PortalDialog
          title="Are you sure?"
          isOpen={isOpen}
          closeModal={closeModal}
        >
          <>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Permanently delete this account?
              </p>
            </div>

            <div className="mt-4">
              <Button
                label="Yes, Delete"
                func={handleDeleteAccount}
                design="delete"
              />
            </div>
          </>
        </PortalDialog>
      </>
    );
  } else {
    return <></>;
  }
}
