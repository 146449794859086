import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";

import authHeader from "./auth.header";
import { getUser, logout, setLocalUser } from "./auth.service";

const apiLag = Number(process.env.REACT_APP_API_LAG) || 0;
// const apiLag = 5000;

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
export const apiInstance = axios.create({
  withCredentials: true,
  baseURL: baseUrl,
});

function sleep(): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, apiLag));
}

export async function refreshToken() {
  try {
    const response = await apiInstance.post(
      `${baseUrl}/users/refreshAccessToken`
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    throw new Error("Your session has expired");
  }
}

interface MyAxiosRequestConfig extends Omit<AxiosRequestConfig, "headers"> {
  headers?: any; // this was "any" at v0.21.1 but now broken between 0.21.4 >= 0.27.2
  // Lets make it any again to make it work again.
}

// Request interceptor for API calls
apiInstance.interceptors.request.use(
  async (config: MyAxiosRequestConfig) => {
    config.headers = authHeader();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
apiInstance.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") {
      await sleep();
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const access_token = await refreshToken();
        const decodedUser: any = jwtDecode(access_token);
        const user = getUser();
        const updatedUser = {
          userId: decodedUser.UserId,
          accountId: decodedUser.AccountId,
          role: decodedUser.role,
          token: access_token,
          sessionStart: user?.sessionStart,
        };
        setLocalUser(updatedUser);
        return apiInstance(originalRequest);
      } catch (error) {
        logout();
        return Promise.reject(error);
      }
    }
    // trying to handle edge case where the user still gets 401
    if (error.response.status === 401 && originalRequest._retry) logout();
    const message = error.response.data || error.message;
    return Promise.reject(message);
  }
);
