import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LogRocket from "logrocket";
import jwtDecode from "jwt-decode";

import {
  ArrowLeftOnRectangleIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";

import logo from "src/logo.svg";
import login from "src/login.jpg";
import FormError from "src/common/FormError";
import Button from "src/common/buttons/Button";
import { Banner } from "../announcement/Banner";

import * as AuthService from "src/services/auth.service";
import { parseAxiosErrors } from "src/utils/Parse";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

interface IFormInputs {
  email: string;
  password: string;
}

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.state?.from?.search;
  const from = location.state?.from?.pathname
    ? `${location.state?.from?.pathname}${search}`
    : "/integrations";
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setbuttonDisabled] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInputs>();
  const watchEmail = watch("email", "");
  const watchPassword = watch("password", "");

  useEffect(() => {
    setbuttonDisabled(watchEmail.length === 0 || watchPassword.length === 0);
  }, [watchEmail, watchPassword]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setLoading(true);
    await toast
      .promise(AuthService.login(data.email, data.password), {
        loading: "Signing in...",
        success: "User logged in.",
        error: (err) => parseAxiosErrors(err),
      })
      .then((res) => {
        let decodedUser: any = jwtDecode(res);
        LogRocket.identify(data.email, {
          userId: decodedUser.UserId,
          role: decodedUser.role,
          accountId: decodedUser.AccountId,
        });
        if (decodedUser) {
          if (decodedUser.role === "Super" || decodedUser.role === "Staff") {
            console.log("navigate: accounts, from: ", from);
            navigate("/Accounts", { state: { from: from }, replace: true });
          } else {
            console.log("navigate: integrations");
            navigate(from);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        console.log("loaded");
      });
  };

  return (
    <>
      <Banner />
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="w-auto" src={logo} alt="EFS logo" />
              <h3 className="mt-6 text-lg leading-6 font-medium text-gray-900">
                Sign in to Your Account
              </h3>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("email", {
                          required: true,
                          pattern:
                            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
                        })}
                        placeholder="frank@smiles.com"
                        type="text"
                        autoComplete="email"
                        autoFocus
                        className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                          errors.email
                            ? "border-red-300 placeholder-red-300 focus-within:border-red-300"
                            : "placeholder-gray-400 border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                        }`}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <FormError message="Email address is required" />
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <FormError message="Invalid email format" />
                      )}
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        autoComplete="current-password"
                        placeholder="password"
                        className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                          errors.password
                            ? "border-red-300 placeholder-red-300 focus-within:border-red-300"
                            : "placeholder-gray-400 border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                        }`}
                      />
                      {errors.password && (
                        <FormError message="Password is required" />
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={loading || buttonDisabled}
                      className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                    ${
                      loading || buttonDisabled
                        ? "opacity-20 text-gray-400"
                        : "hover:bg-gray-700"
                    }`}
                      data-testid="sign-in-button"
                    >
                      {loading ? (
                        <>
                          <CloudIcon className="animate-bounce h-6 w-6 mr-2" />
                          Loading...
                        </>
                      ) : (
                        <>
                          <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-2" />
                          Sign in
                        </>
                      )}
                    </button>
                  </div>
                  <div className="flex justify-between">
                    <Button
                      label="Forgot your password?"
                      path="/forgot"
                      design="text"
                      custom="text-sm"
                    />
                    <span className="text-sm text-gray-400">
                      v{process.env.REACT_APP_BUILD_NUMBER}
                    </span>
                  </div>
                </form>
                <div className="rounded-md bg-primary-50 p-4 mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-primary-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-primary-700">
                        Want to learn more about our affordable and
                        award-winning fulfillment services?
                      </p>
                      <Button
                        label="Visit our website."
                        func={() =>
                          (window.location.href =
                            "https://www.efulfillmentservice.com/")
                        }
                        design="text"
                        custom="text-sm mt-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={login}
            alt="Warehouse in Motion"
          />
        </div>
      </div>
    </>
  );
}
