import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import * as AuthService from "../services/auth.service";
import { useAccount } from "../AccountContext";

export default function AccountCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useAccount();
  useEffect(() => {
    let user = AuthService.getUser();
    console.log("role: ", user?.role);
    if (
      (user?.role === "Super" &&
        account.accountId === 0 &&
        location.pathname.substring(0, 9) !== "/accounts" &&
        location.pathname.substring(0, 6) !== "/users" &&
        location.pathname.substring(0, 8) !== "/profile") ||
      (user?.role === "Staff" &&
        account.accountId === 0 &&
        location.pathname.substring(0, 9) !== "/accounts" &&
        location.pathname.substring(0, 8) !== "/profile")
    ) {
      toast.error("Staff admins must select an account");
      navigate("/accounts");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, account]);

  return null;
}
