import DisplayMappedWmsItem from "../skumaps/DisplayMappedWmsItem";

import {SkuMap} from "../../../services/webservice";

interface DisplaySkuMapsProps {
  map?: SkuMap
}

export default function DisplaySkuMaps({map}:DisplaySkuMapsProps) {
  if (!map || map.skuMapId === 0) return null;
  return (
    <div>
      {map.items && map.items.map((item) => <DisplayMappedWmsItem key={item.skuMapItemId} item={item} />)}
    </div>
  );
}