import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FingerPrintIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import ErrorAlert from "src/common/ErrorAlert";
import LoadingCard from "src/common/LoadingCard";
import IntegrationConfig from "../IntegrationConfig";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, getErrorMessage } from "src/utils/Parse";
import { IntegrationConfigDescriptor } from "src/services/webservice";

export function CallbackPage() {
  const [searchParams] = useSearchParams();
  const oauthParams = searchParams.toString();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<string>("");
  const [integration, setIntegration] = useState<IntegrationConfigDescriptor>();

  useEffect(() => {
    setLoading("Requesting Access Token...");
    (async () => {
      try {
        const callback = await apiInstance.get(
          `/oauth/callback?${oauthParams}`
        );
        console.log(callback.data);
        if (callback.data.status === "Error") {
          throw new Error(callback.data.error);
        } else {
          const getConfig = await apiInstance.get<IntegrationConfigDescriptor>(
            `/Integrations/${callback.data}`,
            { params }
          );
          if (getConfig.status === 200) {
            setIntegration(getConfig.data);
          }
        }
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setLoading("");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Connecting"
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert
          error={`There was a problem with the request to obtain an access token. This can happen if you refresh the page after the token has already been granted. If that is the case, we recommend you restart the install process. Otherwise, please contact support. Additional information: ${error}`}
        />
      </div>
    );

  if (loading)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Connecting"
          icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
        />
        <PageBody>
          <LoadingCard message={loading} />
        </PageBody>
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Connected"
        icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        <div className="rounded-md bg-green-50 p-4 mx-4 sm:mx-0 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                OAuth "Handshake" Complete!
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Please review your config settings below.</p>
              </div>
              <div className="mt-2 text-sm italic text-green-700">
                Note: if you were not prompted to confirm / accept new
                permissions by your cart, your settings were already current.
              </div>
            </div>
          </div>
        </div>
        {integration && (
          <IntegrationConfig integration={integration} refresh={() => {}} />
        )}
      </PageBody>
    </div>
  );
}
