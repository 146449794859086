import { useState } from "react";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

import Button from "src/common/buttons/Button";
import PortalDialog from "src/common/PortalDialog";

import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, getErrorMessage } from "src/utils/Parse";
import ButtonGroup from "src/common/buttons/ButtonGroup";

interface AccountMigrateShopifyButtonProps {
  id?: number;
}

export function AccountMigrateShopifyButton({
  id,
}: AccountMigrateShopifyButtonProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const handleMigrateShopify = async () => {
    closeModal();
    toast
      .promise(
        apiInstance.post(
          "/migration/shopifyV1",
          {
            validAccounts: [id],
          },
          { params }
        ),
        {
          loading: "Migrating account...",
          success: "Account has been successfully migrated.",
          error: (err) => getErrorMessage(err),
        }
      )
      .then(() => {
        navigate("/integrations");
      });
  };

  if (id) {
    return (
      <>
        <Button
          label="Migrate Shopify v1"
          func={openModal}
          design="secondary"
        />
        <PortalDialog
          title="Are you sure?"
          isOpen={isOpen}
          closeModal={closeModal}
        >
          <>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Migrate ALL v1 Shopify configs in this account to v2? Will skip
                any configurations that have already been migrated, assuming
                config names have not changed.
              </p>
            </div>

            <div className="mt-4">
              <ButtonGroup>
                <Button
                  label="Yes, migrate"
                  func={handleMigrateShopify}
                  design="primary"
                />
              </ButtonGroup>
            </div>
          </>
        </PortalDialog>
      </>
    );
  } else {
    return <></>;
  }
}
