import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import MetaSettingField from "../meta/MetaSettingField";
import AvailableWms from "./AvailableWms";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import TextInput from "src/common/forms/TextInput";
import CartMetaScheduleField from "./CartMetaScheduleField";

import { CartMeta } from "src/services/webservice";
import { IntegrationConfigDescriptor } from "src/services/webservice";
import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import {
  orderSyncSchedule,
  superSyncSchedule,
  customApiSyncSchedule,
  itemSyncSchedule,
  parseAccountParams,
  parseAxiosErrors,
} from "src/utils/Parse";
import * as AuthService from "src/services/auth.service";
import { IntegrationConfigDeleteButton } from "./IntegrationConfigDeleteButton";

interface CartMetaFormProps {
  meta?: CartMeta;
  existing?: IntegrationConfigDescriptor;
  token?: string;
}

export default function CartMetaForm({
  meta,
  existing,
  token,
}: CartMetaFormProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const user = AuthService.getUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const shop = searchParams.get("shop");
  const [storeUrl, setStoreUrl] = useState<string | null>(null);

  useEffect(() => {
    if (shop) {
      setStoreUrl(shop);
      methods.setValue("cartUserConfig.storeUrl", shop);
    }
    if (token) {
      methods.setValue("cartUserConfig.accessToken", token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<any>({
    defaultValues: {
      cartIdentifier: existing?.cartIdentifier,
      cartUserConfig: existing?.cartUserConfig,
      isProcessOrdersEnabled: existing?.isProcessOrdersEnabled,
      isInventorySyncEnabled: existing?.isInventorySyncEnabled,
      name: existing?.name,
      processOrdersSchedule: existing?.processOrdersSchedule,
      inventorySyncSchedule: existing?.inventorySyncSchedule,
    },
  });

  const submitIntegrator = (data: CartMeta) => {
    setLoading(true);
    let response;
    let loadingMessage = "Updating...";
    let successMessage = `Settings updated for ${data.name}`;
    if (existing?.integrationConfigId) {
      response = apiInstance.put(
        `/integrations/${existing.integrationConfigId}`,
        data,
        { params }
      );
    } else {
      loadingMessage = "Creating...";
      successMessage = `Integration config created: ${data.name}`;
      response = apiInstance.post("/integrations", data, { params });
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then((res) => {
        const integrationConfigId =
          existing?.integrationConfigId || res.data.integrationConfigId;
        if (storeUrl) {
          toast
            .promise(
              apiInstance.get(`/oauth/shopifyconnect/${integrationConfigId}`, {
                params,
              }),
              {
                loading: "Finalizing install...",
                success: "Redirecting...",
                error: "Something went wrong",
              }
            )
            .then(() => {
              navigate(`/integrations/${integrationConfigId}`);
            });
        } else {
          if (meta?.requiresOAuthTokens && meta?.identifier !== "ShopifyV2") {
            if (res.data.oAuthUrl) {
              window.location.href = res.data.oAuthUrl;
            } else {
              navigate(`/integrations/${integrationConfigId}/edit`);
            }
          } else {
            navigate(`/integrations/${integrationConfigId}`);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitIntegrator)}>
        <input
          type="hidden"
          {...methods.register("cartIdentifier")}
          value={meta?.identifier}
        />
        <input
          type="hidden"
          {...methods.register("cartUserConfig.$type")}
          value={meta?.cartUserConfigTemplate.$type}
        />
        <input
          type="hidden"
          {...methods.register("isProcessOrdersEnabled")}
          value="false"
        />
        <input
          type="hidden"
          {...methods.register("isInventorySyncEnabled")}
          value="false"
        />
        <div className="md:grid md:grid-cols-2 md:gap-4">
          <TextInput
            wrapperClass="mb-4"
            label="Integration Name"
            name="name"
            type="text"
            placeholder="MyFantasticStore"
            description="The name of this particular integration. One word only, letters & numbers, no spaces."
            pattern="alphanumeric"
            required
          />
          <AvailableWms
            selectedWms={existing?.wmsConnection?.wmsConnectionId}
          />
          <div className="mb-4">
            <CartMetaScheduleField
              label="Order Sync Schedule"
              name="processOrdersSchedule"
              options={
                user?.role === "Super"
                  ? [
                      ...superSyncSchedule,
                      ...customApiSyncSchedule,
                      ...orderSyncSchedule,
                    ]
                  : meta?.identifier === "CustomApi"
                  ? [...customApiSyncSchedule, ...orderSyncSchedule]
                  : orderSyncSchedule
              }
              value={existing?.processOrdersSchedule}
            />
          </div>
          <div className="mb-4">
            <CartMetaScheduleField
              label="Inventory Sync Schedule"
              name="inventorySyncSchedule"
              options={
                user?.role === "Super"
                  ? [...superSyncSchedule, ...itemSyncSchedule]
                  : itemSyncSchedule
              }
              value={existing?.inventorySyncSchedule}
            />
          </div>
          {meta?.settingsMeta.map((setting) => (
            <div className="mb-4" key={setting.id}>
              <MetaSettingField
                setting={setting}
                type="cart"
                existingConfig={existing}
                isSuper={user?.role === "Super"}
              />
            </div>
          ))}
        </div>
        <ButtonGroup>
          {existing && (
            <IntegrationConfigDeleteButton
              id={existing.integrationConfigId}
              integrationName={existing.name}
              additionalWarnings={
                existing.cartIdentifier === "ShopifyV2"
                  ? [
                      "Shopify will continue to send orders until you uninstall the app from the Shopify admin.",
                    ]
                  : []
              }
            />
          )}
          <Button
            label="Cancel"
            design="secondary"
            path={existing ? "/integrations" : "/integrations/add"}
          />
          <Button label="Save Integration" type="submit" disabled={loading} />
        </ButtonGroup>
      </form>
    </FormProvider>
  );
}
