import { useState, useEffect } from "react";
import { apiInstance } from "../api";

import { WmsMeta } from "../webservice";

export default function useGetWmsMeta(wmsIdentifier: string) {
  const [data, setData] = useState<WmsMeta>();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      try {
        const response = await apiInstance.get(`/Meta/wms/${wmsIdentifier}`);
        if (response.status === 200) {
          setData(response.data);
        } else {
          throw response;
        }
      } catch (e: any) {
        setError(e.message || e || 'Unknown error');
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [wmsIdentifier]);

  return { data, error, loading };
}
