import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import ErrorAlert from "src/common/ErrorAlert";
import LoadingCard from "src/common/LoadingCard";

import { CartDescriptor } from "src/services/webservice";
import useGetList from "src/services/useGetList";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import AddIntegrationLineItem from "./AddIntegrationLineItem";

export default function AddIntegrationsPage() {
  const { loading, data, error } = useGetList<CartDescriptor>(
    "/Meta/cart",
    1,
    50
  );
  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Add Integration"
          icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Add Integration"
        icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        description="Please choose from the list of available integration platforms below:"
      />
      <PageBody>
        {loading ? (
          <LoadingCard />
        ) : (
          <>
            <div className="flow-root">
              <ul className="divide-y divide-gray-200">
                {data.map((integration) => (
                  <AddIntegrationLineItem integration={integration} />
                ))}
              </ul>
            </div>
            <ButtonGroup>
              <Button label="Cancel" design="secondary" path="/integrations" />
            </ButtonGroup>
          </>
        )}
      </PageBody>
    </div>
  );
}
