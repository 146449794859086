import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import { useForm, SubmitHandler } from "react-hook-form";

import { BriefcaseIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import Button from "../../../common/buttons/Button";
import Pagination from "../../../common/Pagination";
import ErrorAlert from "../../../common/ErrorAlert";
import WmsItemsContent from "./WmsItemsContent";

import { WmsItem } from "../../../services/webservice";
import { apiInstance } from "src/services/api";
import { useAccount } from "../../../AccountContext";
import {
  parseAccountParams,
  parseAxiosErrors,
  parseClassNames,
} from "../../../utils/Parse";
import useGetList from "../../../services/useGetList";
import { TEXT_INPUT } from "src/utils/styles";

interface IItemSearchFormProps {
  search: string;
}

export default function WmsItemsPage() {
  const isInitialRender = useRef(true);
  const { wmsConnectionId } = useParams();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [filter, setFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const { data, loading, error, totalRecords, totalPages } =
    useGetList<WmsItem>(
      `/wms/${wmsConnectionId}/items`,
      page,
      pageSize,
      refreshKey,
      null,
      filter
    );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IItemSearchFormProps>();

  useEffect(() => {
    if (isInitialRender.current || search === "") {
      isInitialRender.current = false;
      return;
    }
    const updatedFilter = `&Sku=${search}`;
    setFilter(updatedFilter);
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const refreshWarehouseItems = () => {
    setRefreshLoading(true);
    toast
      .promise(
        apiInstance.get(`/wms/${wmsConnectionId}/items/refresh`, { params }),
        {
          loading: "Refreshing WMS items...",
          success: "WMS items list has been successfully refreshed",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => setRefreshKey(refreshKey + 1))
      .finally(() => setRefreshLoading(false));
  };

  const onSubmit: SubmitHandler<IItemSearchFormProps> = (data) => {
    setSearch(encodeURIComponent(data.search));
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="WMS Items"
          icon={<BriefcaseIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="WMS Items"
        icon={<BriefcaseIcon className="w-8 md:w-10 ml-2" />}
        description="This list represents the items synced from your WMS connection. If an item is missing, click the refresh button to sync again."
        actionComp={
          <Button
            label="Refresh"
            func={refreshWarehouseItems}
            icon={<ArrowPathIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-2">
        <div className="w-full rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                  <span>Search Items</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-primary-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-0 pt-4 pb-2 text-sm text-gray-500 flex sm:space-x-10 space-y-4 sm:space-y-0 flex-wrap">
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap space-x-4 space-y-2 sm:space-y-0">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="text"
                              {...register("search", { required: true })}
                              id="search"
                              className={parseClassNames(
                                "block w-full focus:outline-none rounded-none rounded-l-md  sm:text-sm",
                                errors.search
                                  ? TEXT_INPUT.error
                                  : TEXT_INPUT.valid
                              )}
                              placeholder={`Enter SKU`}
                            />
                          </div>
                          <button
                            type="submit"
                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          >
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            <span>Search</span>
                          </button>
                        </div>
                        <Button
                          label="clear search"
                          design="text"
                          func={() => {
                            setFilter("");
                            setSearch("");
                            setRefreshKey(refreshKey + 1);
                            reset();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <PageBody>
        <ul className="divide-y divide-gray-200">
          <WmsItemsContent
            data={data}
            loading={loading || refreshLoading}
            refresh={refreshWarehouseItems}
          />
        </ul>
        <Pagination
          page={page}
          pageSize={pageSize}
          totalRecords={totalRecords}
          setPage={setPage}
          totalPages={totalPages}
        />
      </PageBody>
    </div>
  );
}
