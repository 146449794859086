import { useEffect, useState, useRef, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Disclosure, Listbox, Transition } from "@headlessui/react";

import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import Pagination from "../../common/Pagination";
import ErrorAlert from "../../common/ErrorAlert";
import Button from "src/common/buttons/Button";

import useGetList, { SortType } from "../../services/useGetList";
import { AuditLog } from "../../services/webservice";
import AuditLogsContent from "./AuditLogsContent";
import {
  TABLE_COLUMN_HEADER_TEXT,
  TABLE_COLUMN_SORT,
  TEXT_INPUT,
} from "src/utils/styles";
import { parseClassNames } from "../../utils/Parse";

interface IAuditLogSearchFormProps {
  user: string;
}

export type AuditType = "Create" | "Edit" | "Delete" | "";
export type RadioOptionType = {
  id: string;
  title: string;
  filter: AuditType;
};

const auditTypes: RadioOptionType[] = [
  { id: "status-all", title: "All", filter: "" },
  { id: "status-create", title: "Create", filter: "Create" },
  { id: "status-edit", title: "Edit", filter: "Edit" },
  { id: "status-delete", title: "Delete", filter: "Delete" },
];

export type ResourceType =
  | "Account"
  | "CachedCartItem"
  | "IntegrationConfig"
  | "GlobalShipMethodMap"
  | "ShipMethodMap"
  | "SkuMap"
  | "UomMap"
  | "User"
  | "WmsConnection"
  | "ProcessOrdersEnabled"
  | "InventorySyncEnabled"
  | "UpdateTrackingEnabled"
  | "RefreshCartItemsEnabled"
  | "RefreshWmsItemsEnabled"
  | "NotificationSubscriptions"
  | "ApiCartOrder"
  | "";

export type ResourceRadioOptionType = {
  id: string;
  title: string;
  filter: ResourceType;
};

const resources: ResourceRadioOptionType[] = [
  { id: "resource-all", title: "All", filter: "" },
  { id: "resource-a", title: "Account", filter: "Account" },
  { id: "resource-cci", title: "Cached Cart Item", filter: "CachedCartItem" },
  {
    id: "resource-ic",
    title: "Integration Config",
    filter: "IntegrationConfig",
  },
  {
    id: "resource-gsmm",
    title: "Global Ship Method Map",
    filter: "GlobalShipMethodMap",
  },
  { id: "resource-smm", title: "Ship Method Map", filter: "ShipMethodMap" },
  { id: "resource-sm", title: "SKU Map", filter: "SkuMap" },
  { id: "resource-um", title: "UOM Map", filter: "UomMap" },
  { id: "resource-u", title: "User", filter: "User" },
  { id: "resource-wc", title: "WMS Connection", filter: "WmsConnection" },
  {
    id: "resource-poe",
    title: "Process Orders Enabled",
    filter: "ProcessOrdersEnabled",
  },
  {
    id: "resource-ise",
    title: "Inventory Sync Enabled",
    filter: "InventorySyncEnabled",
  },
  {
    id: "resource-ute",
    title: "Update Tracking Enabled",
    filter: "UpdateTrackingEnabled",
  },
  {
    id: "resource-rce",
    title: "Refresh Cart Items Enabled",
    filter: "RefreshCartItemsEnabled",
  },
  {
    id: "resource-rwie",
    title: "Refresh WMS Items Enabled",
    filter: "RefreshWmsItemsEnabled",
  },
  {
    id: "resource-ns",
    title: "Notification Subscriptions",
    filter: "NotificationSubscriptions",
  },
  { id: "resource-aco", title: "API Cart Order", filter: "ApiCartOrder" },
];

export default function AuditLogsPage() {
  const isInitialRender = useRef(true);
  let [searchParams] = useSearchParams();
  let integrationConfigId = searchParams.get("integrationConfigId");
  const [typeParam, setTypeParam] = useState<AuditType>("");
  const [resourceParam, setResourceParam] = useState<ResourceType>("");
  const [user, setUser] = useState<string>("");
  const [filter, setFilter] = useState<string>(
    integrationConfigId ? `&integrationConfigId=${integrationConfigId}` : ""
  );
  const [sort, setSort] = useState<SortType[]>([
    { field: "dateCreated", direction: "desc" },
  ]);
  const [selected, setSelected] = useState(resources[0]);
  const [refreshKey, setRefreshKey] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const { data, error, loading, totalRecords, totalPages } =
    useGetList<AuditLog>(
      `/AuditLogs`,
      page,
      pageSize,
      refreshKey,
      sort,
      filter
    );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAuditLogSearchFormProps>();

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const updatedFilter = handleFilter();
    setFilter(updatedFilter);
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, user, resourceParam]);

  const onSubmit: SubmitHandler<IAuditLogSearchFormProps> = (data) => {
    setUser(encodeURIComponent(data.user));
  };

  const handleFilter = () => {
    let newFilter = integrationConfigId
      ? `&integrationConfigId=${integrationConfigId}`
      : "";
    newFilter += typeParam ? `&Type=${typeParam}` : "";
    newFilter += resourceParam ? `&Resource=${resourceParam}` : "";
    newFilter += user ? `&UserName=${user}` : "";
    console.log(newFilter);
    return newFilter;
  };

  const handleSort = (field: string) => {
    let newSort: SortType = { field, direction: "asc" };
    if (sort[0].field === newSort.field) {
      newSort.direction = sort[0].direction === "asc" ? "desc" : "asc";
    }
    setSort([newSort]);
  };

  const handleStatusFilter = (status: AuditType) => {
    setTypeParam(status);
  };

  const handleResourceFilter = (resource: ResourceType) => {
    setResourceParam(resource);
    // setSelected based on the matching resources object from the array
    let selectedResource = resources.find((res) => res.filter === resource);
    setSelected(selectedResource || resources[0]);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Audit Log"
          icon={<MagnifyingGlassCircleIcon className="w-8 md:w-10 ml-2" />}
          description="These logs record setting and configuration changes, who made them, and when."
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Audit Logs"
        icon={<MagnifyingGlassCircleIcon className="w-8 md:w-10 ml-2" />}
        description="These logs record setting and configuration changes, who made them, and when."
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pt-2">
        <div className="w-full rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                  <span>Filter logs</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-primary-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 flex flex-wrap align-middle space-y-4 sm:space-y-0">
                  <div>
                    <p className="text-sm leading-5 text-gray-500 mb-4">
                      By Type
                    </p>
                    <fieldset>
                      <legend className="sr-only">Log Type</legend>
                      <div className="flex items-center space-y-0 space-x-4">
                        {auditTypes.map((status) => (
                          <div key={status.id} className="flex items-center">
                            <input
                              id={status.id}
                              name="log-type"
                              type="radio"
                              defaultChecked={status.filter === typeParam}
                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                              onClick={() => handleStatusFilter(status.filter)}
                            />
                            <label
                              htmlFor={status.id}
                              className="ml-2 block text-sm font-medium text-gray-700"
                            >
                              {status.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div className="sm:ml-6 z-30 w-64">
                    <p className="text-sm leading-5 text-gray-500">
                      By Resource
                    </p>
                    <Listbox
                      value={resourceParam}
                      onChange={(val) => {
                        handleResourceFilter(val);
                        console.log(val);
                      }}
                    >
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-gray-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-500 sm:text-sm">
                          <span className="block truncate">
                            {selected.title}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm">
                            {resources.map((resource) => (
                              <Listbox.Option
                                key={resource.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-primary-100 text-primary-900"
                                      : "text-gray-900"
                                  }`
                                }
                                value={resource.filter}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {resource.title}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div className="sm:ml-6">
                    <p className="text-sm leading-5 text-gray-500">By User</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap space-x-0 sm:space-x-4 space-y-2 sm:space-y-0">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="text"
                              {...register("user", { required: true })}
                              id="user"
                              className={parseClassNames(
                                "block w-full focus:outline-none rounded-none rounded-l-md  sm:text-sm",
                                errors.user
                                  ? TEXT_INPUT.error
                                  : TEXT_INPUT.valid
                              )}
                              placeholder="By User"
                            />
                          </div>
                          <button
                            type="submit"
                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                          >
                            <span>Search</span>
                          </button>
                        </div>
                        <Button
                          label="clear"
                          design="text"
                          func={() => {
                            setUser("");
                            reset();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <PageBody table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Integration Config
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        <button
                          onClick={() => handleSort("dateCreated")}
                          className="group inline-flex font-semibold text-gray-900"
                        >
                          Date
                          <span
                            className={parseClassNames(
                              "ml-2 rounded flex-none",
                              sort[0].field === "dateCreated"
                                ? TABLE_COLUMN_SORT.active
                                : TABLE_COLUMN_SORT.innactive
                            )}
                          >
                            {sort[0].field === "dateCreated" &&
                            sort[0].direction === "desc" ? (
                              <ChevronDownIcon className="h-5 w-5" />
                            ) : (
                              <ChevronUpIcon className="h-5 w-5" />
                            )}
                          </span>
                        </button>
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Type
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Resource
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        User
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <AuditLogsContent data={data} loading={loading} />
                  </tbody>
                </table>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
