import {
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/solid";

interface PaginationProps {
  page: number;
  pageSize: number;
  totalRecords: number;
  setPage: (page: number) => void;
  totalPages: number;
}

export default function Pagination({
  page,
  pageSize,
  totalRecords,
  setPage,
  totalPages,
}: PaginationProps) {
  const prevPage = () => {
    let newPage = page - 1;
    if (newPage >= 1) setPage(newPage);
  };

  const nextPage = () => {
    let newPage = page + 1;
    if (page < totalPages) setPage(newPage);
  };

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        {totalRecords > 1 ? (
          <p className="text-sm text-gray-700">
            Page <span className="font-medium">{page}</span> Showing{" "}
            <span className="font-medium">{(page - 1) * pageSize + 1}</span> to{" "}
            <span className="font-medium">
              {page * pageSize > totalRecords ? totalRecords : page * pageSize}
            </span>{" "}
            of <span className="font-medium">{totalRecords}</span> results
          </p>
        ) : (
          <p className="text-sm text-gray-700">
            <span className="font-medium">{totalRecords}</span> results
          </p>
        )}
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {page > 1 && (
          <>
            <button
              onClick={() => setPage(1)}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <span className="sr-only">First Page</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={prevPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </>
        )}
        {page < totalPages && (
          <>
            <button
              onClick={nextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => setPage(totalPages)}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <span className="sr-only">Last Page</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </>
        )}
      </div>
    </nav>
  );
}
