import { Link } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { TruckIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import LoadingCard from "../../common/LoadingCard";
import EmptyList from "../../common/EmptyList";
import ErrorAlert from "../../common/ErrorAlert";
import Button from "../../common/buttons/Button";

import { GlobalShipMethodMapDescriptor } from "../../services/webservice";
import useGetList from "../../services/useGetList";
import CartMethod from "../../common/shipmap/CartMethod";
import WmsConnectionMap from "./WmsConnectionMap";

export default function ShipMapsPage() {
  const { data, loading, error } = useGetList<GlobalShipMethodMapDescriptor>(
    "/GlobalShipMethodMaps/",
    1,
    100
  );
  const mockFunction = () => {};
  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Global Ship Maps"
          icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Global Ship Maps"
        description={
          <>
            These mappings apply to <span className="text-gray-900">ALL</span>{" "}
            your integrations. You can configure overrides on a per-integration
            basis.
          </>
        }
        icon={<TruckIcon className="w-8 md:w-10 ml-2" />}
        actionComp={
          <Button
            label="Add"
            path="add"
            icon={<PlusSmallIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      <PageBody>
        <ul className="divide-y divide-gray-200">
          {data.length === 0 && (
            <div className="text-center py-10">
              {loading ? (
                <LoadingCard />
              ) : (
                <EmptyList
                  title="No Global Ship Maps"
                  description="Get started by creating a shipping method map."
                  buttonPath="add"
                  buttonLabel="New Global Ship Map"
                />
              )}
            </div>
          )}
          {data.length > 0 &&
            data.map((map) => (
              <li key={map.globalShipMethodMapId}>
                <Link
                  to={`edit/${map.globalShipMethodMapId}`}
                  className="block hover:bg-gray-50"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div>
                        <CartMethod method={map.cartShipMethod} />
                        {map.items &&
                          map.items?.length > 0 &&
                          map.items.map((mapItem) => (
                            <div key={mapItem.globalShipMethodMapItemId}>
                              <WmsConnectionMap
                                wmsConnectionId={mapItem.wmsConnectionId || 0}
                                item={mapItem}
                                addItem={mockFunction}
                                removeItem={mockFunction}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      </PageBody>
    </div>
  );
}
