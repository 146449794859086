import { ArrowPathIcon } from "@heroicons/react/20/solid";

import BackToIntegrationConfig from "./buttons/BackToIntegrationConfig";
import Button from "./buttons/Button";

interface PageHeaderProps {
  title: string;
  description?: React.ReactNode;
  actionComp?: React.ReactNode;
  actionCompSecondary?: React.ReactNode;
  icon?: React.ReactNode;
  refresh?: () => void;
}

export default function PageHeader({
  title,
  actionComp,
  actionCompSecondary,
  icon,
  description,
  refresh,
}: PageHeaderProps) {
  return (
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-0">
        <div className="flex justify-between flex-wrap space-y-3 sm:space-y-0">
          <div>
            <div className="flex items-center">
              <h1 className="text-xl md:text-3xl leading-tight text-gray-900">
                {title}
              </h1>
              {icon}
              {refresh && (
                <Button
                  label="reload data"
                  icon={<ArrowPathIcon className="w-4 h-4 mr-0.5" />}
                  design="text"
                  custom="text-sm ml-4"
                  func={refresh}
                />
              )}
            </div>
            {description && (
              <p className="font-light text-gray-500 mt-2 max-w-2xl">
                {description}
              </p>
            )}
          </div>
          <div className="flex space-x-2">
            <BackToIntegrationConfig />
            {actionCompSecondary && <div>{actionCompSecondary}</div>}
            {actionComp && <div>{actionComp}</div>}
          </div>
        </div>
      </div>
    </header>
  );
}
