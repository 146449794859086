import { DateTime } from "luxon";
import { SettingEnumMeta } from "../services/webservice";
import { AccountType } from "../AccountContext";

export const parseDate = (dateString: string | undefined) => {
  if (
    dateString &&
    dateString !== "0001-01-01T00:00:00Z" &&
    dateString !== "2019-01-01T00:00:00Z"
  ) {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_MED);
  } else {
    return "N/A";
  }
};

export const holdOrderValues = [
  { name: "None", hours: 0 },
  { name: "6 hours", hours: 6 },
  { name: "12 hours", hours: 12 },
  { name: "24 hours", hours: 24 },
  { name: "72 hours", hours: 72 },
  { name: "1 week", hours: 120 },
];

export const superSyncSchedule = [
  { id: "0 0/1 * * * ?", name: "Every Minute" },
  { id: "0 0/2 * * * ?", name: "Every 2 Minutes" },
];

export const customApiSyncSchedule = [
  { id: "0 0/5 * * * ?", name: "Every 5 Minutes" },
  { id: "0 0/15 * * * ?", name: "Every 15 Minutes" },
];

export const orderSyncSchedule = [
  {
    id: "0 15,45 * * * ?",
    name: "30 Minute Intervals (XX:15, XX:45 each hour)",
  },
  { id: "0 45 * * * ?", name: "Hourly (XX:45 each hour)" },
  { id: "0 45 0/3 * * ?", name: "Every 3 Hours (XX:45 each instance)" },
  { id: "0 0 5,17 * * ?", name: "Twice Daily (5 AM and 5 PM)" },
  { id: "0 0 5 * * ?", name: "Daily (5 AM)" },
];

export const itemSyncSchedule = [
  { id: "0 0 * * * ?", name: "Hourly (XX:00 each hour)" },
  { id: "0 45 0/3 * * ?", name: "Every 3 Hours (XX:45 each instance)" },
  { id: "0 0 0,12 * * ?", name: "Twice Daily (12 AM and 12 PM)" },
  { id: "0 0 0 * * ?", name: "Daily (12 AM)" },
];

const htmlErrors = [
  {
    error: "Request failed with status code 403",
    friendlyMessage: "You do not have permission to view this content.",
  },
  {
    error: "Request failed with status code 404",
    friendlyMessage: "The referenced resource is not found.",
  },
];

export const parseOrderSchedule = (scheduleString: string) => {
  const combinedSchedule = [
    ...superSyncSchedule,
    ...customApiSyncSchedule,
    ...orderSyncSchedule,
  ];
  return (
    combinedSchedule.find((x) => x.id === scheduleString)?.name ||
    `${scheduleString} (custom)`
  );
};

export const parseInventorySchedule = (scheduleString: string) => {
  const combinedSchedule = [...superSyncSchedule, ...itemSyncSchedule];
  return (
    combinedSchedule.find((x) => x.id === scheduleString)?.name ||
    `${scheduleString} (custom)`
  );
};

export const parseValueIndex = (
  options: SettingEnumMeta[],
  value: string | number | undefined
) => {
  let index = 0;
  if (value) {
    index = options.findIndex((x) => x.id === value);
  }
  return index;
};

export const parseClassNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const parseAccountParams = (account: AccountType) => {
  return account.accountId > 0 ? { AccountId: account.accountId } : {};
};

export const parseHtmlErrors = (error: string) => {
  return htmlErrors.find((x) => x.error === error)?.friendlyMessage || error;
};

export const parseApiErrors = (error: any) => {
  const message = error.message || error.errorMessage;
  return message;
};

export const parseAxiosErrors = (error: any) => {
  console.log("initial: ", error);
  let friendlyErrorMessage = "";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("error.response.data: ", error.response.data);
    console.log("error.response.status: ", error.response.status);
    console.log("error.response.headers: ", error.response.headers);
    switch (error.response.status) {
      case 403:
        friendlyErrorMessage = "Invalid Username Or Password";
        break;
      default:
        friendlyErrorMessage = error.response.statusText;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("error.request: ", error.request);
    friendlyErrorMessage = "Network error";
  } else if (error.errors) {
    console.log("error.errors: ", error.errors);
    friendlyErrorMessage = Object.values(error.errors).join(", ");
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error: ", error.message || error);
    friendlyErrorMessage = error.message || error || "Unknown error";
  }
  return friendlyErrorMessage.match(
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g
  )
    ? `Internal Error. Please provide this code to support: ${friendlyErrorMessage}`
    : friendlyErrorMessage;
};

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}
