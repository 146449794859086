import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import ErrorAlert from "./ErrorAlert";
import PageHeader from "./PageHeader";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) {
  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Something Went Wrong"
        description="There was an unexpected problem with the app. Helpful information may be provided below."
        icon={<ExclamationCircleIcon className="w-8 md:w-10 ml-2" />}
      />
      <ErrorAlert error={error.message} func={resetErrorBoundary} />
    </div>
  );
}
