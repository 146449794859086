import LoadingCard from "../../common/LoadingCard";
import EmptyList from "../../common/EmptyList";

import Button from "../../common/buttons/Button";

import { UserDescriptor } from "../../services/webservice";
import { parseDate, parseClassNames } from "../../utils/Parse";
import { TABLE_COLUMN_TEXT } from "../../utils/styles";

interface UsersContentProps {
  data: UserDescriptor[];
  loading: boolean;
  refresh: () => void;
}

export default function UsersContent({
  data,
  loading,
  refresh,
}: UsersContentProps) {
  if (loading)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <LoadingCard />
          </div>
        </td>
      </tr>
    );

  if (data.length === 0)
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center py-10">
            <EmptyList
              title="No Users"
              description="Add some users"
              buttonPath="add"
              buttonLabel="Add Users"
            />
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {data.map((user) => (
        <tr key={user.userId}>
          <td className={TABLE_COLUMN_TEXT.base}>
            <div className="flex items-center">
              <div className="text-sm font-medium">{user.userId}</div>
            </div>
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              TABLE_COLUMN_TEXT.secondary
            )}
          >
            {user.name}
          </td>
          <td className={TABLE_COLUMN_TEXT.base}>
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
              {user.role}
            </span>
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              TABLE_COLUMN_TEXT.secondary
            )}
          >
            {user.email}
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              TABLE_COLUMN_TEXT.secondary
            )}
          >
            {parseDate(user.lastLogin)}
          </td>
          <td
            className={parseClassNames(
              TABLE_COLUMN_TEXT.base,
              TABLE_COLUMN_TEXT.secondary,
              "text-right"
            )}
          >
            <Button
              label="Edit"
              srOnly={` ${user.name}`}
              path={`edit/${user.userId}`}
              design="secondary"
            />
          </td>
        </tr>
      ))}
    </>
  );
}
