import { ArrowPathIcon } from "@heroicons/react/24/outline";

import LoadingCard from "../../../common/LoadingCard";
import FolderIcon from "../../../common/FolderIcon";

import { WmsItem } from "../../../services/webservice";

interface WmsItemsContentProps {
  data: WmsItem[];
  loading: boolean;
  refresh: () => void;
}

export default function WmsItemsContent({
  data,
  loading,
  refresh,
}: WmsItemsContentProps) {
  if (loading)
    return (
      <li>
        <div className="text-center py-10">
          <LoadingCard />
        </div>
      </li>
    );

  if (data.length === 0)
    return (
      <li>
        <div className="text-center py-10">
          <FolderIcon />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No WMS Items
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by syncing items from your WMS.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={refresh}
            >
              <ArrowPathIcon className="mr-2 h-6 w-6" />
              Refresh WMS Items
            </button>
          </div>
        </div>
      </li>
    );

  return (
    <>
      {data.map((map, index) => (
        <li key={index}>
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p className="flex items-center text-md text-gray-900 font-medium">
                    {map.sku}{" "}
                    <span className="ml-4 text-sm text-gray-400">
                      (WMS ID: {map.wmsId})
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
}
