import { Fragment, useState } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { Listbox, RadioGroup, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import useGetWms, { Formats } from "src/services/wms/useGetWms";
import FormError from "src/common/FormError";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import { AvailableWmsTpbAccounts } from "src/features/shippingprofiles/AvailableWmsShippingProfiles";

import { SettingEnumMeta, TpbAccount } from "src/services/webservice";
import { apiInstance } from "src/services/api";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import LoadingInline from "src/common/LoadingInline";
import {
  DdpOptionType,
  DutiesTaxesCodeType,
  DutyBillingType,
  ShippingProfileDescriptor,
  TransportationBillingType,
} from "./types";

const ddpValues: DdpOptionType[] = [
  {
    name: "Shipping Only (Duties & Taxes unpaid)",
    dutiesTaxesCode: "DDU",
    transportationBillingType: "THIRDPARTY",
    dutyBillingType: "RECIPIENT",
  },
  {
    name: "Shipping + Duties & Taxes",
    dutiesTaxesCode: "DDP",
    transportationBillingType: "THIRDPARTY",
    dutyBillingType: "THIRDPARTY",
  },
  {
    name: "Duties & Taxes Only",
    dutiesTaxesCode: "DDP",
    transportationBillingType: "PREPAID",
    dutyBillingType: "THIRDPARTY",
  },
];

interface IFormInputs {
  name: string;
  description: string;
  wmsConnectionId: number;
  tpbAccountId: number;
  dutiesTaxesCode: DutiesTaxesCodeType;
  transportationBillingType: TransportationBillingType;
  dutyBillingType: DutyBillingType;
}

interface ShippingProfileFormProps {
  existingProfile?: ShippingProfileDescriptor;
}

export function ShippingProfileForm({
  existingProfile,
}: ShippingProfileFormProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [loading, setLoading] = useState<boolean>(false);
  const [tpbAccount, setTpbAccount] = useState<TpbAccount | null>();
  const methods = useForm<IFormInputs>({
    defaultValues: {
      name: existingProfile?.name || "",
      description: existingProfile?.description || "",
      wmsConnectionId: existingProfile?.wmsConnectionId || 0,
      tpbAccountId: existingProfile?.tpbAccountId || 0,
      dutiesTaxesCode: existingProfile?.dutiesTaxesCode || "DDU",
      transportationBillingType:
        existingProfile?.transportationBillingType || "THIRDPARTY",
      dutyBillingType: existingProfile?.dutyBillingType || "RECIPIENT",
    },
  });
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const [selectedDdpOption, setSelectedDdpOption] = useState<DdpOptionType>(
    ddpValues[
      findIndexByValues(
        ddpValues,
        existingProfile?.dutiesTaxesCode || "DDU",
        existingProfile?.transportationBillingType || "THIRDPARTY",
        existingProfile?.dutyBillingType || "RECIPIENT"
      )
    ]
  );

  const {
    data,
    error,
    loading: loadingWms,
  } = useGetWms<SettingEnumMeta>({
    format: Formats.Enum,
  });

  const [selectedWms, setSelectedWms] = useState<SettingEnumMeta>({
    id: "0",
    name: "choose WMS",
  });

  function findIndexByValues(
    ddpValues: DdpOptionType[],
    dutiesTaxesCode: string,
    transportationBillingType: string,
    dutyBillingType: string
  ): number {
    return ddpValues.findIndex((ddp: DdpOptionType) => {
      return (
        ddp.dutiesTaxesCode === dutiesTaxesCode &&
        ddp.transportationBillingType === transportationBillingType &&
        ddp.dutyBillingType === dutyBillingType
      );
    });
  }

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    setLoading(true);
    data.description =
      data.description || `${tpbAccount?.accountName} (${tpbAccount?.carrier})`;
    let response;

    if (existingProfile) {
      response = apiInstance.put(
        `/ShippingProfiles/${existingProfile.shippingProfileId}`,
        data,
        { params }
      );
    } else {
      response = apiInstance.post("/ShippingProfiles", data, { params });
    }

    toast
      .promise(response, {
        loading: "Saving profile...",
        success: "Profile saved!",
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        navigate("/shippingprofiles");
      })
      .finally(() => setLoading(false));
  };

  const handleDdpSelection = (selection: DdpOptionType) => {
    setSelectedDdpOption(selection);
    methods.setValue("dutiesTaxesCode", selection.dutiesTaxesCode);
    methods.setValue(
      "transportationBillingType",
      selection.transportationBillingType
    );
    methods.setValue("dutyBillingType", selection.dutyBillingType);
  };

  const handleWmsChange = (wms: SettingEnumMeta) => {
    setSelectedWms(wms);
    methods.setValue("wmsConnectionId", Number(wms.id));
    setTpbAccount(null);
    methods.setValue("tpbAccountId", 0);
    setRefreshKey(refreshKey + 1);
  };

  const handleTpbAccountChange = (account: TpbAccount) => {
    account.accountId && methods.setValue("tpbAccountId", account.accountId);
    setTpbAccount(account);
  };

  if (error)
    return (
      <div className="mb-4">
        <p className="block text-sm font-medium text-gray-700">
          WMS Connection
        </p>
        <FormError message={error} />
      </div>
    );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...methods.register("wmsConnectionId", {
            required: true,
            min: 1,
          })}
        />{" "}
        <input
          type="hidden"
          {...methods.register("tpbAccountId", {
            required: true,
            min: 1,
          })}
        />
        <input type="hidden" {...methods.register("dutiesTaxesCode")} />
        <input type="hidden" {...methods.register("dutyBillingType")} />
        <input
          type="hidden"
          {...methods.register("transportationBillingType")}
        />
        <div>
          <div>
            <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
              Profile Name & WMS TPB Account
            </h3>
            <p className="mt-1 max-w-2xl text-md md:text-sm text-gray-500">
              Give your profile a memorable name and select from the available
              TPB accounts synced from the WMS.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cartShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Profile Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...methods.register("name", { required: true })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      methods.formState.errors.name
                        ? "focus:ring-red-300 focus:border-red-300 border-red-300 placeholder-red-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="My Neat Profile"
                  />
                </div>
                {methods.formState.errors.name && (
                  <FormError message="Profile Name is Required" />
                )}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cartShipMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    {...methods.register("description", { required: true })}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md ${
                      methods.formState.errors.description
                        ? "focus:ring-red-300 focus:border-red-300 border-red-300 placeholder-red-400"
                        : "focus:ring-gray-500 focus:border-gray-500"
                    }`}
                    placeholder="Enter a helpful description"
                  />
                </div>
                {methods.formState.errors.description && (
                  <FormError message="Profile Name is Required" />
                )}
              </div>
            </div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="wmsConnectionId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  WMS Connections
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 z-20">
                  {loadingWms ? (
                    <LoadingInline message="Loading WMS Connections..." />
                  ) : (
                    <Listbox
                      value={selectedWms}
                      onChange={(wms) => handleWmsChange(wms)}
                    >
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            {selectedWms?.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {data.map((wms) => (
                              <Listbox.Option
                                key={wms.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-amber-100 text-amber-900"
                                      : "text-gray-900"
                                  }`
                                }
                                value={wms}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {wms.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="warehouseShipMethod"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  WMS TPB Account
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  {selectedWms.id !== "0" ? (
                    <AvailableWmsTpbAccounts
                      wms={selectedWms}
                      setAccount={handleTpbAccountChange}
                      key={refreshKey}
                    />
                  ) : (
                    <FormError
                      type="info"
                      message="You must select a WMS connection above to show available TPB accounts"
                    />
                  )}
                  {methods.formState.errors.tpbAccountId && (
                    <FormError message="WMS TPB Account is Required" />
                  )}
                </div>
              </div>
            </div>

            <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-md md:text-lg leading-6 font-medium text-gray-900">
                  Advanced Settings
                </h3>
              </div>
              <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                <div className="pt-6 sm:pt-5">
                  <div role="group" aria-labelledby="label-email">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div></div>
                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg space-y-4">
                          <div>
                            <div className="relative flex items-start">
                              <div className="text-sm">
                                <label
                                  htmlFor="holdOrder"
                                  className="font-medium text-gray-700"
                                >
                                  Duties & Taxes
                                </label>
                                <p className="text-gray-500">
                                  Configure your DDP settings carefully.
                                </p>
                              </div>
                            </div>
                            <div className="sm:col-span-2 mt-4">
                              <div className="max-w-lg">
                                <RadioGroup
                                  value={selectedDdpOption}
                                  onChange={(selection: DdpOptionType) => {
                                    handleDdpSelection(selection);
                                  }}
                                >
                                  <RadioGroup.Label className="text-sm text-gray-500">
                                    DDP selections other than{" "}
                                    <span className="text-gray-700 font-medium">
                                      Shipping Only
                                    </span>{" "}
                                    are currently available only for FedEx TPB
                                    accounts.
                                  </RadioGroup.Label>
                                  <div className="space-y-4 mt-4">
                                    {ddpValues.map((value) => (
                                      <RadioGroup.Option
                                        key={value.name}
                                        value={value}
                                        className={({ active, checked }) =>
                                          `${
                                            active
                                              ? "ring-2 ring-gray-500 ring-opacity-60"
                                              : ""
                                          }
                  ${
                    checked
                      ? "bg-gray-900 bg-opacity-75 text-gray-400"
                      : "bg-white"
                  }
                    relative rounded-lg p-2 cursor-pointer flex focus:outline-none bg-gray-200`
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <div className="flex items-center justify-between w-full">
                                              <div className="flex items-center">
                                                <div className="text-sm">
                                                  <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium  ${
                                                      checked
                                                        ? "text-white"
                                                        : "text-gray-900"
                                                    }`}
                                                  >
                                                    {value.name}
                                                  </RadioGroup.Label>
                                                </div>
                                              </div>
                                              {checked && (
                                                <div className="flex-shrink-0 text-white">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      strokeWidth={2}
                                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    ))}
                                  </div>
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <ButtonGroup>
              <Button
                label="Cancel"
                func={() => navigate(-1)}
                design="secondary"
              />
              <Button label="Save" type="submit" loading={loading} />
            </ButtonGroup>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
