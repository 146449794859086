import { useNavigate } from "react-router";
import {
  Bars4Icon,
  ShoppingCartIcon,
  BriefcaseIcon,
  MapIcon,
  Cog8ToothIcon,
  ArrowsRightLeftIcon,
  ClipboardDocumentListIcon,
  ArrowRightOnRectangleIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";

import IntegrationToggle from "./IntegrationToggle";
import IntegrationConfigShortcutButton from "./IntegrationConfigShortcutButton";
import IntegrationConfigSettingTransferButton from "./IntegrationConfigSettingTransferButton";
import HelpPopover from "src/common/HelpPopover";
import ButtonRound from "src/common/buttons/ButtonRound";

import { IntegrationConfigDescriptor } from "src/services/webservice";
import {
  parseDate,
  parseOrderSchedule,
  parseInventorySchedule,
  parseAccountParams,
  parseAxiosErrors,
} from "src/utils/Parse";
import * as AuthService from "src/services/auth.service";
import { BUTTONS_ROUND } from "src/utils/styles";
import Button from "src/common/buttons/Button";
import { toast } from "react-hot-toast";
import { apiInstance } from "src/services/api";
import { InfoAlert } from "src/common/InfoAlert";

interface IntegrationConfigProps {
  integration: IntegrationConfigDescriptor | null;
  refresh: () => void;
}

export default function IntegrationConfig({
  integration,
  refresh,
}: IntegrationConfigProps) {
  const navigate = useNavigate();
  const user = AuthService.getUser();
  const params = parseAccountParams({
    accountId: integration?.accountId || 0,
    accountName: "",
  });

  const handleCartItemRefreshToggle = () => {
    toast
      .promise(
        apiInstance.post(
          `/integrations/${integration?.integrationConfigId}/refreshCartItemsEnabled`,
          "",
          { params }
        ),
        {
          loading: "Toggling item refresh...",
          success: "Item refresh toggled",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => refresh());
  };

  if (integration === null) return null;
  return (
    <div className="px-0 sm:px-6 pb-8 sm:pb-10">
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex flex-wrap flex-col sm:flex-row">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {integration.name}
          </h3>
          <span className="inline-flex items-center mt-2 sm:mt-0 sm:ml-4 px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            {integration.cartIdentifier}{" "}
            <ArrowsRightLeftIcon className="h-5 w-5 mx-2" />{" "}
            {integration.wmsConnection?.description}
          </span>
        </div>
        <div className="flex mt-2 sm:mt-0 space-x-2">
          <ButtonRound
            icon={<Cog8ToothIcon className={BUTTONS_ROUND.icon} />}
            func={() =>
              navigate(`/integrations/${integration.integrationConfigId}/edit`)
            }
            label={`Edit Settings for ${integration.name}`}
            design="secondary"
          />
          {user?.role !== "User" && user?.role !== "Admin" && (
            <IntegrationConfigSettingTransferButton
              id={integration.integrationConfigId}
              refresh={refresh}
            />
          )}
          {integration.cartIdentifier === "ShopifyV2" && (
            <ButtonRound
              icon={
                <ArrowRightOnRectangleIcon
                  className={BUTTONS_ROUND.icon}
                  aria-hidden="true"
                />
              }
              label={`transfer inventory from ${integration.integrationConfigId}`}
              design={
                integration.isInventoryTransferScheduled
                  ? "disabled"
                  : "secondary"
              }
              func={() =>
                navigate(
                  `/integrations/${integration.integrationConfigId}/inventoryTransfer`
                )
              }
              disabled={integration.isInventoryTransferScheduled}
            />
          )}
        </div>
      </div>
      <dl className="mt-4 sm:mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <div className="px-4 p-6 mt-2 sm:mt-0">
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-2 gap-y-6">
            <IntegrationConfigShortcutButton
              label="Received Orders"
              configName={integration.name}
              icon={<Bars4Icon className={BUTTONS_ROUND.icon} />}
              clicker={() =>
                navigate(
                  `/orders?integrationConfigId=${integration.integrationConfigId}`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="Ship Maps"
              configName={integration.name}
              icon={<MapIcon className={BUTTONS_ROUND.icon} />}
              clicker={() =>
                navigate(
                  `/integrations/${integration.integrationConfigId}/${integration.wmsConnection?.wmsConnectionId}/shipmaps`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="Pending Orders"
              configName={integration.name}
              icon={<ShoppingCartIcon className={BUTTONS_ROUND.icon} />}
              clicker={() =>
                navigate(
                  `/integrations/${integration.integrationConfigId}/pendingorders`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="Cart Items"
              configName={integration.name}
              icon={<BriefcaseIcon className={BUTTONS_ROUND.icon} />}
              clicker={() =>
                navigate(
                  `/integrations/${integration.integrationConfigId}/${
                    integration.wmsConnection?.wmsConnectionId || 0
                  }/items`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="WMS Items"
              configName={integration.name}
              icon={<BriefcaseIcon className={BUTTONS_ROUND.icon} />}
              clicker={() =>
                navigate(
                  `/wms/${integration.wmsConnection?.wmsConnectionId}/items?integrationConfigId=${integration.integrationConfigId}`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="Event Logs"
              configName={integration.name}
              icon={
                <ClipboardDocumentListIcon className={BUTTONS_ROUND.icon} />
              }
              clicker={() =>
                navigate(
                  `/integrations/${integration.integrationConfigId}/eventlogs?page=1`
                )
              }
            />
            <IntegrationConfigShortcutButton
              label="Filter Audit Logs"
              configName={integration.name}
              icon={
                <MagnifyingGlassCircleIcon className={BUTTONS_ROUND.icon} />
              }
              clicker={() =>
                navigate(
                  `/audit?integrationConfigId=${integration.integrationConfigId}`
                )
              }
            />
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <IntegrationToggle
            title="Order Sync"
            isActive={integration.isProcessOrdersEnabled || false}
            integrationConfigId={integration.integrationConfigId}
            type="processordersenabled"
            refresh={refresh}
          />
          {integration.cartIdentifier === "ShopifyV2" && (
            <div className="mt-2">
              <InfoAlert
                message="Order sync is not needed to receive fulfillment order webhooks from
              Shopify, but should be enabled as a backup."
              />
            </div>
          )}
          {integration.isProcessOrdersEnabled && (
            <dd className="my-4 flex">
              <div>
                Next:{" "}
                <span className="text-gray-400">
                  {parseDate(integration.processOrdersNextRunTime)}
                </span>
              </div>
            </dd>
          )}
          <dd className="my-4 flex">
            <div>
              Last:{" "}
              <span className="text-gray-400">
                {parseDate(integration.processOrdersLastRunTime)}
              </span>
            </div>
          </dd>
          <dd className="my-4 flex">
            <div>
              Schedule:{" "}
              <span className="text-gray-400">
                {parseOrderSchedule(integration.processOrdersSchedule)}
              </span>
            </div>
          </dd>
          {integration.cartIdentifier === "ShopifyV2" && (
            <>
              <IntegrationToggle
                title="Tracking"
                isActive={integration.isUpdateTrackingEnabled || false}
                integrationConfigId={integration.integrationConfigId}
                type="updatetrackingenabled"
                refresh={refresh}
              />
              {(user?.role === "Super" || user?.role === "Staff") && (
                <div className="mt-2">
                  <IntegrationToggle
                    title="Real Order Sync"
                    isActive={integration.isProcessOrdersEnabled || false}
                    integrationConfigId={integration.integrationConfigId}
                    type="processordersenabled2"
                    refresh={refresh}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {integration.cartIdentifier !== "CustomApi" && (
          <div className="px-4 py-5 sm:p-6">
            <IntegrationToggle
              title="Item Sync"
              isActive={integration.isInventorySyncEnabled}
              integrationConfigId={integration.integrationConfigId}
              type="inventorysyncenabled"
              refresh={refresh}
            />
            {integration.isInventorySyncEnabled && (
              <dd className="my-4 flex">
                <div>
                  Next:{" "}
                  <span className="text-gray-400">
                    {parseDate(integration.inventorySyncNextRunTime)}
                  </span>
                </div>
              </dd>
            )}
            <dd className="my-4 flex">
              <div>
                Last:{" "}
                <span className="text-gray-400">
                  {parseDate(integration.inventorySyncLastRunTime)}
                </span>
              </div>
            </dd>
            <dd className="my-4 flex">
              <div>
                Schedule:{" "}
                <span className="text-gray-400">
                  {parseInventorySchedule(integration.inventorySyncSchedule)}
                </span>
              </div>
            </dd>
          </div>
        )}
      </dl>
      <div className="text-sm sm:text-xs text-gray-400 mt-3 flex flex-wrap space-x-2">
        <span>Automatic cart items refresh enabled: </span>
        {integration.isRefreshCartItemsEnabled ? (
          <Button
            label="YES, click to toggle"
            design="text"
            func={handleCartItemRefreshToggle}
          />
        ) : (
          <Button
            label="NO, click to toggle"
            design="text"
            func={handleCartItemRefreshToggle}
          />
        )}
        <HelpPopover text="If YES we will pull in any item changes from your cart once per night automatically" />
      </div>
    </div>
  );
}
