import { useState } from "react";
import { useParams, useNavigate } from "react-router";
import toast from "react-hot-toast";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";
import Button from "src/common/buttons/Button";
import { InventoryTransferContent } from "./InventoryTransferContent";
import ErrorAlert from "src/common/ErrorAlert";
import { InfoAlert } from "src/common/InfoAlert";

import { InventoryTransfer } from "src/services/webservice";
import { apiInstance } from "src/services/api";
import useGetOne from "src/services/useGetOne";
import { useAccount } from "src/AccountContext";
import { parseAccountParams, parseAxiosErrors } from "src/utils/Parse";
import { TABLE_COLUMN_HEADER_TEXT } from "src/utils/styles";

type TransferRequestType = {
  locationName: string;
  itemsToTransfer: InventoryTransfer[];
  errorMessage: string;
  hasError: boolean;
};

export function InventoryTransferPage() {
  const { integrationConfigId } = useParams();
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const { data, loading, error } = useGetOne<TransferRequestType>(
    `/integrations/${integrationConfigId}/inventoryTransfer`,
    refreshKey
  );

  const handleTransferInventory = () => {
    toast
      .promise(
        apiInstance.post(
          `/integrations/${integrationConfigId}/inventoryTransfer`,
          {
            LocationName: data?.locationName,
          },
          {
            params,
          }
        ),
        {
          loading: "Requesting inventory transfer...",
          success: "Transfer scheduled",
          error: (err) => parseAxiosErrors(err),
        }
      )
      .then(() => navigate(`/integrations/${integrationConfigId}`));
  };

  const reloadTransferList = () => {
    setRefreshKey(refreshKey + 1);
  };

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Inventory Transfer"
          icon={<ArrowRightOnRectangleIcon className="w-8 md:w-10 ml-2" />}
          refresh={reloadTransferList}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Inventory Transfer"
        icon={<ArrowRightOnRectangleIcon className="w-8 md:w-10 ml-2" />}
        refresh={reloadTransferList}
        description={
          <span>
            Designed to work with locations named "EFS" or "efs", but will also
            work if you have a single location of a different name (locations
            can be renamed in Shopify if necessary). Products with committed
            order quantity will not be transferred.{" "}
            <span className="font-bold">
              Transfer can take several minutes if you have many products.
            </span>
          </span>
        }
        actionComp={
          <Button
            label="Transfer"
            type="button"
            func={handleTransferInventory}
            icon={<ArrowRightOnRectangleIcon className="mr-2 h-6 w-6" />}
          />
        }
      />
      {data && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-0 mt-4">
          {data?.locationName ? (
            <InfoAlert title="Location Found:" message={data?.locationName} />
          ) : (
            <InfoAlert
              message="No eligible location found. Please see instructions."
              type="warning"
            />
          )}
        </div>
      )}
      <PageBody table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        SKU
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        cartId
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        quantity
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        sourceId
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <InventoryTransferContent
                      data={data?.itemsToTransfer || []}
                      loading={loading}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
