import { useSearchParams } from "react-router-dom";

import { FingerPrintIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import PageHeader from "src/common/PageHeader";
import PageBody from "src/common/PageBody";

export function ReAuthRedirectPage() {
  const [searchParams] = useSearchParams();
  const shop = searchParams.get("shop");

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="OAUTH Verification"
        icon={<FingerPrintIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody table={true}>
        <div className="rounded-md bg-green-50 p-4 mx-4 sm:mx-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Re-authorization Complete!
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Scopes and permissions updated successfully for {shop}.</p>
              </div>
              <div className="mt-2 text-sm italic text-green-700">
                Note: if you were not prompted to confirm updated permissions by
                your cart, your settings were already current and nothing has
                changed.
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
