import { UserCircleIcon } from "@heroicons/react/24/outline";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import AccountForm from "./AccountForm";

export default function AddAccountPage() {
  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Super Admin: Create Account"
        icon={<UserCircleIcon className="w-8 md:w-10 ml-2" />}
      />
      <PageBody>
        <AccountForm />
      </PageBody>
    </div>
  );
}
