import { ShoppingCartIcon } from "@heroicons/react/24/outline";

interface CartMethodProps {
  method?: string;
}

export default function CartMethod({ method }: CartMethodProps) {
  if (!method) return null;
  return (
    <div>
      <p className="flex items-center text-sm text-gray-500">
        <ShoppingCartIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
          aria-hidden="true"
        />
        <span className="text-md font-medium text-gray-600 flex-wrap">
          {method}
        </span>
      </p>
    </div>
  );
}
