import { TruckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";

interface WmsMethodProps {
  method?: string;
}

export default function WmsMethod({ method }: WmsMethodProps) {
  return (
    <div className="flex items-center text-sm text-gray-500">
      <ArrowSmallRightIcon
        className="flex-shrink-0 mx-1.5 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      {method ? (
        <>
          <TruckIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
            aria-hidden="true"
          />
          {method}
        </>
      ) : (
        <NoSymbolIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-200"
          aria-hidden="true"
        />
      )}
    </div>
  );
}
