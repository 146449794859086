import { Link } from "react-router-dom";

import {
  ChevronRightIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/solid";

import EmptyList from "../../../common/EmptyList";
import LoadingCard from "../../../common/LoadingCard";

import { IntegrationConfigDescriptor } from "../../../services/webservice";

interface CartInstallContentProps {
  data: IntegrationConfigDescriptor[];
  integratorType: string;
  loading: boolean;
}

export function CartInstallContent({
  data,
  integratorType,
  loading,
}: CartInstallContentProps) {
  if (loading)
    return (
      <li>
        <div className="text-center py-10">
          <LoadingCard />
        </div>
      </li>
    );

  if (data.length === 0)
    return (
      <li>
        <div className="text-center py-10">
          <EmptyList
            title={`No Integrations found for ${integratorType}`}
            description="Get started by adding a new one."
            buttonLabel="New Integration"
            buttonPath={`/integrations/add/${integratorType}`}
          />
        </div>
      </li>
    );

  return (
    <>
      {data.map((integration) => (
        <li key={integration.integrationConfigId}>
          <Link
            to={`/integrations/${integration.integrationConfigId}/edit`}
            className="block hover:bg-gray-50"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-end space-x-3 flex-wrap">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {integration.name}
                </h3>
                <span className="inline-flex items-center mt-2 sm:mt-0 sm:ml-4 px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                  {integration.cartIdentifier}{" "}
                  <ArrowsRightLeftIcon className="h-5 w-5 mx-2" />{" "}
                  {integration.wmsConnection?.description}
                </span>
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
        </li>
      ))}
    </>
  );
}
