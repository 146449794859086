import { useState } from "react";
import { useParams } from "react-router";

import {
  ShoppingCartIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";

import PageHeader from "../../../common/PageHeader";
import PageBody from "../../../common/PageBody";
import ErrorAlert from "../../../common/ErrorAlert";
import PendingOrdersContent from "./PendingOrdersContent";

import useGetList from "../../../services/useGetList";
import { Order } from "../../../services/webservice";
import { TABLE_COLUMN_HEADER_TEXT } from "../../../utils/styles";

export default function OrdersPage() {
  const { integrationConfigId } = useParams();
  const [refreshKey, setRefreshKey] = useState<number>(1);
  const { loading, data, error } = useGetList<Order>(
    `/integrations/${integrationConfigId}/pendingOrders`,
    1,
    100,
    refreshKey
  );

  const refreshData = () => setRefreshKey(refreshKey + 1);

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Integrations"
          icon={<ArrowsRightLeftIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Pending Orders"
        description="This is how the system 'sees' the orders in the cart. Your integration config settings create a 'filter' and this list represents the orders that match. Please note: orders may remain on this page until tracking has been transmitted back to the cart."
        icon={<ShoppingCartIcon className="w-8 md:w-10 ml-2" />}
        refresh={refreshData}
      />
      <PageBody table={true}>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Order #
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Reference #
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Shipping Name
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Shipping Country
                      </th>
                      <th scope="col" className={TABLE_COLUMN_HEADER_TEXT.base}>
                        Incoming Ship Method
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Options</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <PendingOrdersContent
                      data={data}
                      loading={loading}
                      refresh={refreshData}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
}
