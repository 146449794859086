import { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import MetaSettingField from "../meta/MetaSettingField";
import TextInput from "src/common/forms/TextInput";
import ButtonGroup from "src/common/buttons/ButtonGroup";
import Button from "src/common/buttons/Button";
import { SkipSkuToggle } from "./SkipSkuToggle";

import { WmsMeta } from "../../services/webservice";
import { WmsConnectionDescriptor } from "../../services/webservice";
import { apiInstance } from "../../services/api";
import { useAccount } from "../../AccountContext";
import { parseAccountParams, parseAxiosErrors } from "../../utils/Parse";

interface WmsMetaFormProps {
  meta: WmsMeta | undefined;
  existing?: WmsConnectionDescriptor;
}

export default function WmsMetaForm({ meta, existing }: WmsMetaFormProps) {
  const navigate = useNavigate();
  const { account } = useAccount();
  const params = parseAccountParams(account);
  const [loading, setLoading] = useState<boolean>(false);
  const skipSkuEnabled = useRef<boolean>(existing?.skipSku || false);
  const [key, setKey] = useState<number>(0);
  const methods = useForm<any>({
    defaultValues: {
      wmsIdentifier: existing?.wmsIdentifier,
      description: existing?.description,
      skipSku: existing?.skipSku,
      wmsUserConfig: existing?.wmsUserConfig,
    },
  });

  const submitIntegrator = (data: WmsMeta) => {
    setLoading(true);
    let response;
    let loadingMessage = "Updating...";
    let successMessage = "WMS connection updated";
    if (existing?.wmsConnectionId) {
      response = apiInstance.put(`/Wms/${existing.wmsConnectionId}`, data, {
        params,
      });
    } else {
      loadingMessage = "Creating...";
      successMessage = "WMS connection created";
      response = apiInstance.post("/Wms", data, { params });
    }
    toast
      .promise(response, {
        loading: loadingMessage,
        success: successMessage,
        error: (err) => parseAxiosErrors(err),
      })
      .then(() => {
        setLoading(false);
        navigate("/wms");
      });
  };

  const toggleSkipSku = () => {
    skipSkuEnabled.current = !skipSkuEnabled.current;
    methods.setValue("skipSku", skipSkuEnabled.current);
    setKey(key + 1);
  };

  if (!meta?.settingsMeta) return <div>no meta</div>;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitIntegrator)}>
        <input
          type="hidden"
          {...methods.register("wmsIdentifier")}
          value={meta.identifier}
        />
        <input
          type="hidden"
          {...methods.register("wmsUserConfig.$type")}
          value={meta.wmsUserConfigTemplate.$type}
        />
        <div className="md:grid md:grid-cols-2 md:gap-4">
          <TextInput
            wrapperClass="mb-4"
            label="WMS Description"
            name="description"
            type="text"
            placeholder="FCP"
            description="A user friendly name of the WMS."
            required
          />

          <SkipSkuToggle
            enabled={skipSkuEnabled.current}
            setEnabled={toggleSkipSku}
            key={key}
          />

          {meta.settingsMeta.map((setting) => (
            <div className="mb-4" key={setting.id}>
              <MetaSettingField setting={setting} type="wms" {...methods} />
            </div>
          ))}
        </div>
        <ButtonGroup>
          <Button design="secondary" func={() => navigate(-1)} label="Cancel" />
          <Button type="submit" label="Save WMS" loading={loading} />
        </ButtonGroup>
      </form>
    </FormProvider>
  );
}
