import { Dialog } from "@headlessui/react";

import OrderStateListItem from "src/features/orders/OrderStateListItem";

import { Order } from "../../../services/webservice/models/Order";

interface OrderDetailsProps {
  order: Order;
}

export default function OrderDetails({ order }: OrderDetailsProps) {
  return (
    <>
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900"
      >
        {order.orderNumber}
      </Dialog.Title>
      <div className="mt-2">
        <p className="mt-2 text-sm text-gray-500">
          Reference: {order.referenceNumber}
        </p>
        {order.poNumber && (
          <p className="text-sm text-gray-500">PO Number: {order.poNumber}</p>
        )}
        {order.cartId && (
          <p className="mt-2 text-sm text-gray-500">Cart ID: {order.cartId}</p>
        )}
        <div className="mt-4 border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1" id="billing-address">
              <dt className="text-sm font-medium text-gray-500">
                Billing Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.company}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.firstName} {order.billAddress?.lastName}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.address1}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.address2}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.city}, {order.billAddress?.state}{" "}
                {order.billAddress?.postalCode}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.email}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.billAddress?.phone}
              </dd>
            </div>
            <div className="sm:col-span-1" id="shipping-address">
              <dt className="text-sm font-medium text-gray-500">
                Shipping Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.company}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.firstName} {order.shipAddress?.lastName}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.address1}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.address2}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.city}, {order.shipAddress?.state}{" "}
                {order.shipAddress?.postalCode}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.email}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                {order.shipAddress?.phone}
              </dd>
            </div>
            <div className="sm:col-span-2" id="shipping-method">
              <dt className="text-sm font-medium text-gray-500">
                Shipping Method
              </dt>
              <dd className="mt-1 text-sm text-gray-900 flex flex-wrap space-x-2">
                <span>{order.shipMethod}</span>
              </dd>
            </div>
            <div className="sm:col-span-1" id="order-items">
              <dt className="text-sm font-medium text-gray-500">Order Items</dt>
              {order.items?.map((item) => (
                <div key={item.orderItemId}>
                  <dd
                    className="mt-1 text-sm text-gray-900"
                    key={item.orderItemId}
                  >
                    <span className="text-gray-500">
                      {item.quantity} &times;
                    </span>
                    &nbsp;
                    {item.sku || <span>"" (blank SKU)</span>}
                  </dd>
                </div>
              ))}
            </div>
            {order.wmsItems && order.wmsItems?.length > 0 && (
              <div className="sm:col-span-1" id="order-items">
                <dt className="text-sm font-medium text-gray-500">
                  Submitted WMS Line Items
                </dt>
                {order.wmsItems?.map((item) => (
                  <div key={item.orderWmsItemId}>
                    <dd
                      className="mt-1 text-sm text-gray-900"
                      key={item.orderWmsItemId}
                    >
                      <span className="text-gray-500">
                        {item.quantity} &times;
                      </span>
                      &nbsp;
                      {item.sku}
                    </dd>
                  </div>
                ))}
              </div>
            )}
            <div className="sm:col-span-2" id="order-timeline">
              <dt className="text-sm font-medium text-gray-500">
                Order Timeline
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {order.orderLogs && order.orderLogs.length === 0 ? (
                  <span>No order state logged</span>
                ) : (
                  <ul>
                    {order.orderLogs?.map((log, logIdx) => (
                      <OrderStateListItem
                        log={log}
                        logIdx={logIdx}
                        count={order.orderLogs?.length}
                      />
                    ))}
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
