import { Disclosure, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

interface HelpDisclosureProps {
  text: string;
}

export default function HelpDisclosure({ text }: HelpDisclosureProps) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button>
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </Disclosure.Button>

          {/*
            Use the Transition + open render prop argument to add transitions.
          */}
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            {/*
              Don't forget to add `static` to your Disclosure.Panel!
            */}
            <Disclosure.Panel static>{text}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
