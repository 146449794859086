import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";

import PageHeader from "../../common/PageHeader";
import PageBody from "../../common/PageBody";
import ErrorAlert from "../../common/ErrorAlert";
import LoadingCard from "../../common/LoadingCard";
import WmsMetaForm from "./WmsMetaForm";

import useGetWmsMeta from "../../services/meta/useGetWmsMeta";

export default function AddWmsMetaPage() {
  const { wmsType } = useParams();
  const { loading, data, error } = useGetWmsMeta(wmsType || "");

  if (error)
    return (
      <div className="py-2 sm:py-10">
        <PageHeader
          title="Edit Ship Map"
          icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        />
        <ErrorAlert error={error} />
      </div>
    );

  return (
    <div className="py-2 sm:py-10">
      <PageHeader
        title="Add WMS"
        icon={<GlobeAltIcon className="w-8 md:w-10 ml-2" />}
        description={`Configure ${wmsType} Settings`}
      />
      <PageBody>
        {loading ? <LoadingCard /> : <WmsMetaForm meta={data} />}
      </PageBody>
    </div>
  );
}
